import "./style.scss";

interface Props {
  title: string;
  message?: string;
  width?: string;
  position?: { x: number; y: number };
}

export default function MessageCard({
  title,
  message,
  width = "300px",
  position,
}: Props) {
  return (
    <div
      className="MessageCardContainer"
      style={{
        width: width,
        top: position?.y + "px",
        left: position?.x + "px",
      }}
    >
      <p className="MessageCardContainer--Title">{title}</p>

      {message ? (
        <p className="MessageCardContainer--Message">{message}</p>
      ) : null}
    </div>
  );
}
