declare global {
  interface Window {
    $crisp?: any[];
    CRISP_WEBSITE_ID?: string;
  }
}

export const loadCrisp = () => {
  if (typeof window !== "undefined" && !window.$crisp) {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "82940795-7eb2-487b-808b-4a997ba036dd";
    const d = document;
    const s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = true;
    d.getElementsByTagName("head")[0].appendChild(s);
  }
};

export const unloadCrisp = () => {
  if (typeof window !== "undefined") {
    if (window.$crisp) {
      delete window.$crisp;
    }
    if (window.CRISP_WEBSITE_ID) {
      delete window.CRISP_WEBSITE_ID;
    }
    const crispScript = document.querySelector(
      'script[src="https://client.crisp.chat/l.js"]',
    );
    if (crispScript) {
      crispScript.remove();
    }
  }
};
