import React, { useState } from "react";
import "./style.scss";
import Pair from "../../Layout/Pair/Pair";
import Text from "../Text/Text";
import ExpandButton from "../../utils/ExpandButton/ExpandButton";
import Stack from "../../Layout/Stack/Stack";
import { Edit } from "react-feather";
import { truncateString } from "../../../utils/truncateString";
import Popover from "../../utils/Popover/Popover";
import TextField from "../../Inputs/TextField/TextField";
import Button from "../../Inputs/Button/Button";

interface Props {
  onExpand: () => void;
  expanded: boolean;
  title: string;
  rightOptions?: React.ReactNode;
  subheader?: React.ReactNode;
  showActiveIcon?: boolean;
  enableTitleEditing?: boolean;
  onSaveTitle?: (newTitle: string) => void;
}

export default function ListHeader({
  onExpand,
  expanded,
  title: title_ = "",
  rightOptions,
  subheader,
  showActiveIcon,
  enableTitleEditing = false,
  onSaveTitle = () => {},
}: Props) {
  const [title, setTitle] = useState(title_);
  const [editTitle, setEditTitle] = useState(title_);
  const [showPopover, setShowPopover] = useState(false);

  return (
    <div className="ListHeaderContainer">
      <Pair direction="row" spacing={8} align="flex-start">
        <ExpandButton onClick={onExpand} expanded={expanded} />
        <Pair direction="column" spacing={6}>
          <div
            className={`ListHeaderContainer--Title${
              enableTitleEditing ? "-edit" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (enableTitleEditing) {
                setShowPopover(!showPopover);
                setEditTitle(title);
              }
            }}
          >
            <Text
              size="subtitle"
              weight="bold"
              variant="primary"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {title?.length > 0 ? truncateString(title, 30) : "Untitled"}{" "}
              <Edit className="ListHeaderContainer--Title-edit--icon" />
            </Text>
          </div>
          <Pair direction="row" align="center" spacing={10}>
            {showActiveIcon ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={10}
              >
                <Pair direction="row" align="center" spacing={4}>
                  <div className="ListHeaderContainer--ActiveCircle" />
                  <Text size="small" variant="secondary">
                    active
                  </Text>
                </Pair>
                <Text size="small" variant="secondary">
                  |
                </Text>
              </Stack>
            ) : null}
            {subheader}
          </Pair>
        </Pair>
      </Pair>
      <div className="ListHeaderContainer--right">{rightOptions}</div>
      {showPopover ? (
        <div className="ListHeaderContainer--Popover">
          <Popover onClose={() => setShowPopover(false)}>
            <Pair>
              <TextField
                placeholder="Title"
                inputType="text"
                width="full"
                title=""
                value={editTitle}
                onChange={(value) => {
                  setEditTitle(value || "");
                }}
                error={editTitle?.length === 0 ? "Title cannot be empty" : null}
              />
              <Button
                label="Save"
                width="full"
                disabled={editTitle?.length === 0}
                onClick={() => {
                  onSaveTitle(editTitle);
                  setTitle(editTitle);
                  setShowPopover(false);
                }}
              />
            </Pair>
          </Popover>
        </div>
      ) : null}
    </div>
  );
}
