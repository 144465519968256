import React, { useState } from "react";
import "./style.scss";
import List from "../../Layout/List/List";
import Pair from "../../Layout/Pair/Pair";
import Text from "../Text/Text";
import Button from "../../Inputs/Button/Button";
import { Plus } from "react-feather";
import BackgroundLine from "../BackgroundLine/BackgroundLine";
import CommandModal, { ICommand } from "../../Inputs/CommandModal/CommandModal";
import { useMediaQuery } from "react-responsive";
import IconButton from "../../Inputs/IconButton/IconButton";
import Modal from "../../Layout/Modal/Modal";

interface Props {
  children?: React.ReactNode;
  onNewCommand?: (command: ICommand) => void;
  showUpgradeMessage?: boolean;
  upgradePlanLink?: string;
}

export default function CommandsList({
  children,
  onNewCommand = () => {},
  showUpgradeMessage = false,
  upgradePlanLink = "",
}: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const getNumberOfCommands = () => {
    if (!React.isValidElement(children)) return 0;
    if (children.props.commands === undefined) return 0;
    return children.props.commands.length;
  };

  return (
    <div className="CommandsListContainer">
      <div className="CommandsListContainer--Header">
        <div className="CommandsListContainer--Header--Title">
          <Pair direction="column" spacing={4}>
            <Text size="subtitle" weight="bold" variant="primary">
              Commands
            </Text>
            <Text variant="secondary">{getNumberOfCommands()} commands</Text>
          </Pair>
          {!isTabletOrMobile && (
            <div className="CommandsListContainer--Header--Right">
              <Button
                label="New"
                endIcon={<Plus strokeWidth={2} size={20} />}
                onClick={() => {
                  setOpenModal(true);
                }}
              />
              <IconButton
                icon="help"
                tooltip="How to use the commands?"
                tooltipPosition="bottom"
                fill={true}
                onClick={() => {
                  setOpenHelpModal(true);
                }}
              />
            </div>
          )}
        </div>

        <BackgroundLine />
        <div className="CommandsListContainer--Header--Table">
          <Text size="regular" weight="bold" variant="primary">
            Command
          </Text>
          <Text size="regular" weight="bold" variant="primary">
            Description
          </Text>
        </div>
        <BackgroundLine />
      </div>
      <div className="CommandsListContainer--List">
        <List removePadding>{children}</List>
      </div>
      <CommandModal
        openModal={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={(command) => onNewCommand(command)}
        showUpgradeMessage={showUpgradeMessage}
        upgradePlanLink={upgradePlanLink}
      />
      <Modal
        open={openHelpModal}
        onClose={() => {
          setOpenHelpModal(false);
        }}
        header={
          <Text weight="bold" size="subtitle">
            How to use the commands?
          </Text>
        }
        height="720px"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "12px",
          }}
        >
          <Text size="subtitle">
            To use commands you can do it from the kbar or directly in the
            google top search bar.
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">Kbar:</Text>
            <Text>
              Tu use commands in the kbar, activate the kbar and select the
              "commands" option.
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">Google Searchbar</Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">1.</Text>
            <Text>Click on the google searchbar</Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">2.</Text>
            <Text>Write the activation command "go"</Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">3.</Text>
            <Text>
              Then user your tab or space to activate TabDock in the searhbar
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">4.</Text>
            <Text>Type your command</Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">5.</Text>
            <Text>Example: sw thor</Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">6.</Text>
            <Text>Enter to run the command</Text>
          </div>
          <img
            src={"https://i.imgur.com/ozIWRSq.png"}
            alt="Use commands instructions"
            width="90%"
            style={{
              borderRadius: "8px",
              marginTop: "10px",
              alignSelf: "center",
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
