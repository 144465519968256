import React from "react";
import "./style.scss";
import Text from "../Text/Text";
import CircularProgressbar from "../CircularProgressbar/CircularProgressbar";

interface Props {
  title: string;
  value: number;
  max: number;
  background?: boolean;
}

export default function CircularProgressbarDisplay({
  title,
  value,
  max,
  background = false,
}: Props) {
  return (
    <div
      className={`CircularProgressbarDisplay ${
        background ? "CircularProgressbarDisplayBackground" : ""
      }`}
    >
      <Text weight="bold" alignCenter>
        {title}
      </Text>
      <CircularProgressbar
        size={80}
        color="#3391E8"
        max={max}
        value={value > max ? max : value}
        text={`${value} of ${max}`}
      />
    </div>
  );
}
