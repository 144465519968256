import React from "react";
import Pair from "../../Layout/Pair/Pair";
import Text from "../Text/Text";
import { truncateString } from "../../../utils/truncateString";

interface Props {
  header: string;
  subheader: string;
  align?: "flex-start" | "flex-end" | "center";
  size?:
    | "clock"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "h7"
    | "h8"
    | "super-clock";
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "error"
    | "success"
    | "white"
    | "black";
  variant_2?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "error"
    | "success"
    | "white"
    | "black"
    | null;
  spacing?: number;
  maxLength?: number;
}

export default function TextPair({
  header,
  subheader,
  align = "flex-start",
  size = "h1",
  variant = "primary",
  variant_2 = null,
  spacing = 6,
  maxLength = 0,
}: Props) {
  const param = getParameters(size);
  return (
    <Pair align={align} spacing={spacing}>
      <Text
        size={param.size_1}
        weight={param.weight_1}
        variant={variant}
        elevated={size === "clock" || size === "super-clock"}
        alignCenter={align === "center"}
      >
        {maxLength > 0 ? truncateString(header, maxLength) : header}
      </Text>
      <Text
        size={param.size_2}
        weight={param.weight_2}
        variant={variant_2 !== null ? variant_2 : variant}
        alignCenter={align === "center"}
      >
        {maxLength > 0 ? truncateString(subheader, maxLength) : subheader}
      </Text>
    </Pair>
  );
}

type Size =
  | "clock"
  | "large"
  | "title"
  | "subtitle"
  | "regular"
  | "small"
  | "tiny"
  | "super-clock";
type Weight = "light" | "regular" | "bold" | "semibold";
type Variant = "primary" | "secondary" | "tertiary";

type Param = {
  size_1: Size;
  weight_1: Weight;
  variant_1: Variant;
  size_2: Size;
  weight_2: Weight;
  variant_2: Variant;
};

function getParameters(size: string): Param {
  switch (size) {
    case "clock":
      return {
        size_1: "clock",
        weight_1: "semibold",
        variant_1: "primary",
        size_2: "subtitle",
        weight_2: "light",
        variant_2: "primary",
      };
    case "super-clock":
      return {
        size_1: "super-clock",
        weight_1: "semibold",
        variant_1: "primary",
        size_2: "title",
        weight_2: "light",
        variant_2: "primary",
      };
    case "h1":
      return {
        size_1: "large",
        weight_1: "bold",
        variant_1: "primary",
        size_2: "subtitle",
        weight_2: "light",
        variant_2: "primary",
      };
    case "h2":
      return {
        size_1: "title",
        weight_1: "bold",
        variant_1: "primary",
        size_2: "subtitle",
        weight_2: "light",
        variant_2: "primary",
      };
    case "h3":
      return {
        size_1: "title",
        weight_1: "bold",
        variant_1: "primary",
        size_2: "subtitle",
        weight_2: "light",
        variant_2: "secondary",
      };
    case "h4":
      return {
        size_1: "subtitle",
        weight_1: "bold",
        variant_1: "primary",
        size_2: "regular",
        weight_2: "light",
        variant_2: "primary",
      };
    case "h5":
      return {
        size_1: "subtitle",
        weight_1: "bold",
        variant_1: "primary",
        size_2: "regular",
        weight_2: "light",
        variant_2: "secondary",
      };
    case "h6":
      return {
        size_1: "subtitle",
        weight_1: "bold",
        variant_1: "secondary",
        size_2: "regular",
        weight_2: "light",
        variant_2: "secondary",
      };
    case "h7":
      return {
        size_1: "small",
        weight_1: "regular",
        variant_1: "secondary",
        size_2: "tiny",
        weight_2: "light",
        variant_2: "secondary",
      };
    case "h8":
      return {
        size_1: "small",
        weight_1: "semibold",
        variant_1: "secondary",
        size_2: "small",
        weight_2: "light",
        variant_2: "secondary",
      };
    default:
      return {
        size_1: "subtitle",
        weight_1: "bold",
        variant_1: "secondary",
        size_2: "regular",
        weight_2: "light",
        variant_2: "secondary",
      };
  }
}
