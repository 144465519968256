import { useMutation, useQueryClient } from "react-query";
import { deleteCommand } from "../../api/commandsApi";

interface Props {
  onSuccess: () => void;
  onError: (error: Error) => void;
}

export function useDeleteCommand({ onSuccess, onError }: Props) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, commandId }: { userId: string; commandId: string }) =>
      deleteCommand(userId, commandId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("commands");
        onSuccess();
      },
      onError: (error: Error) => {
        onError(error);
      },
    },
  );
}
