import { createClient } from "@supabase/supabase-js";

const supabaseUrl: string = process.env.REACT_APP_SUPABASE_URL!;
const supabaseAnonKey: string = process.env.REACT_APP_SUPABASE_ANON_KEY!;

const mockResponse = new Response(
  JSON.stringify({ error: "Request intercepted" }),
  {
    status: 400,
    headers: { "Content-Type": "application/json" },
  },
);

const customFetcher = async (
  url: RequestInfo | URL,
  options?: RequestInit,
): Promise<Response> => {
  if (
    typeof url === "string" &&
    url.includes("/auth/v1/token?grant_type=refresh_token")
  ) {
    return mockResponse;
  }
  return fetch(url, options);
};

export const supabaseClient = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: false, // Only the Chrome Extension should refresh the token
    persistSession: true,
    detectSessionInUrl: true,
  },
  global: {
    fetch: customFetcher,
  },
});
