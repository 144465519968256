import React from "react";
import "./style.scss";
import { BackgroundLine, Text } from "@tabdock/common";

export default function RequestFeatureScreen() {
  return (
    <div className="RequestFeatureScreen">
      <div className="RequestFeatureScreen--Header">
        <Text weight="bold" size="title">
          Request Feature
        </Text>
      </div>
      <div className="RequestFeatureScreen--HeaderLine">
        <BackgroundLine />
      </div>
    </div>
  );
}
