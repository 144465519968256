import React from "react";
import "./style.scss";

interface Props {
  children: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
  key_value?: string | number;
  searchValue?: string;
}

export default function DropDownMenuOption({
  children,
  active = false,
  onClick,
  key_value,
  searchValue,
}: Props) {
  return (
    <div
      id={`dropdown-menu-option-${key_value}`}
      className={`DropDownMenuOption ${
        active ? "DropDownMenuOptionActive" : ""
      }`}
      onClick={onClick}
      key={key_value}
    >
      {children}
    </div>
  );
}
