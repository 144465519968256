import { useMutation, useQueryClient } from "react-query";
import { createWorkspaceWithTabs } from "../../api/workspaceApi";

interface Props {
  onSuccess: () => void;
  onError: (error: Error) => void;
}

export function useCreateWorkspaceWithTabs({ onSuccess, onError }: Props) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, title, tabs }: { userId: string; title: string; tabs: any[] }) =>
      createWorkspaceWithTabs(userId, title, tabs),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("workspaces");
        queryClient.invalidateQueries("subscription");
        queryClient.invalidateQueries("workspacesCount");
        onSuccess();
      },
      onError: (error: Error) => {
        onError(error);
      },
    },
  );
}
