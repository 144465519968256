import { Session, User } from "@supabase/supabase-js";
import { useContext, useState, useEffect, createContext } from "react";
import { supabaseClient } from "../config/supabase-client";
import { sendMessageToExtension } from "../utils/extension";
import { useNavigate } from "react-router-dom";
import { AnalyticsService, PostHogProvider } from "@tabdock/common";

const AuthContext = createContext<{
  session: Session | null | undefined;
  user: User | null | undefined;
  signOut: () => void;
  id: string | undefined;
  forceSignOut: () => void;
}>({
  session: null,
  user: null,
  signOut: () => {},
  id: undefined,
  forceSignOut: () => {},
});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User>();
  const [session, setSession] = useState<Session | null>();
  const [id, setId] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);

  const postHogProvider = new PostHogProvider();
  const analyticsService = new AnalyticsService(postHogProvider);

  const navigate = useNavigate();

  useEffect(() => {
    supabaseClient.auth.stopAutoRefresh();

    sendMessageToExtension(
      {
        type: "auth",
        command: "sessionStatus",
        data: null,
      },
      async (response) => {
        if (response && response?.status === "success") {
          const accessToken = response?.user?.accessToken;
          const refreshToken = response?.user?.refreshToken;

          const { data, error } = await supabaseClient.auth.setSession({
            access_token: accessToken,
            refresh_token: refreshToken,
          });

          if (error) throw error;

          setSession(data?.session);
          setUser(data?.session?.user);
          setId(data?.session?.user?.id);
          setLoading(false);
        }
      },
    );
  }, []);

  useEffect(() => {
    const setData = async () => {
      const {
        data: { session },
        error,
      } = await supabaseClient.auth.getSession();

      if (error) throw error;

      setSession(session);
      setUser(session?.user);
      setId(session?.user?.id);
      setLoading(false);
    };

    const { data: listener } = supabaseClient.auth.onAuthStateChange(
      async (_event, session) => {
        if (_event === "SIGNED_OUT") {
          analyticsService.captureEvent("user_logged_out_event", {
            product: "web",
          });

          setSession(undefined);
          setUser(undefined);
          setId(undefined);
        }
        if (_event === "SIGNED_IN") {
          const user = session?.user;
          analyticsService.identifyUser(user?.id ?? "", { email: user?.email });
          analyticsService.captureEvent("user_logged_in_event", {
            product: "web",
          });

          sendMessageToExtension(
            {
              type: "auth",
              command: "setSession",
              data: {
                accessToken: session?.access_token,
                refreshToken: session?.refresh_token,
                userId: user?.id,
              },
            },
            (response) => {},
          );
        }

        setSession(session);
        setUser(session?.user);
        setId(session?.user?.id);
        setLoading(false);
      },
    );

    setData();

    return () => {
      listener?.subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignOut = async () => {
    setSession(undefined);
    setUser(undefined);
    setId(undefined);
    await supabaseClient.auth.signOut();

    localStorage.setItem("forceSignOut", "false");

    analyticsService.captureEvent("user_logged_out", {
      product: "web",
    });

    sendMessageToExtension(
      {
        type: "auth",
        command: "logoutAuth",
        data: null,
      },
      (response) => {},
    );

    navigate(0);
  };

  const handleForceSignOut = async () => {
    localStorage.setItem("forceSignOut", "true");

    setSession(undefined);
    setUser(undefined);
    setId(undefined);

    sendMessageToExtension(
      {
        type: "auth",
        command: "forceLogout",
        data: null,
      },
      (response) => {},
    );

    await supabaseClient.auth.setSession({
      access_token: "",
      refresh_token: "",
    });

    document.cookie = `sb-access-token=; value=; max-age=0;`;
    document.cookie = `sb-refresh-token=; path=/; value=; max-age=0;`;
    document.cookie = `sb-auth-token=; path=/; value=; max-age=0;`;

    await supabaseClient.auth.refreshSession();
    await supabaseClient.auth.signOut();

    analyticsService.captureEvent("user_force_logged_out", {
      product: "web",
    });
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        user,
        signOut: handleSignOut,
        id,
        forceSignOut: handleForceSignOut,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
