import { useState } from "react";
import "./style.scss";
import ExpandButton from "../../utils/ExpandButton/ExpandButton";
import TabGroupLabel from "../TabGroupLabel/TabGroupLabel";
import IconButton from "../../Inputs/IconButton/IconButton";
import List from "../../Layout/List/List";

type Color =
  | "blue"
  | "grey"
  | "red"
  | "yellow"
  | "green"
  | "pink"
  | "purple"
  | "cyan"
  | "orange";

interface Props {
  id: string;
  onUnGroup: () => void;
  onOpenAll: () => void;
  onDelete: () => void;
  title: string;
  color: string;
  locked?: boolean;
  children?: React.ReactNode;
}

export default function TabGroup({
  id,
  title,
  color: passedColor,
  onUnGroup,
  onOpenAll,
  onDelete,
  locked = false,
  children,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const color = passedColor as Color;

  return (
    <div className={`TabGroupContainer TabGroupContainer--${color}`} id={id}>
      <div className="TabGroupContainer--Header">
        <div className="TabGroupContainer--Header--Title">
          <ExpandButton
            onClick={() => setExpanded(!expanded)}
            expanded={expanded}
          />
          <TabGroupLabel label={title} color={color} />
        </div>

        {!locked && (
          <div className="TabGroupContainer--Header--Actions">
            <IconButton icon="share" variant="secondary" onClick={onOpenAll} />
            <IconButton
              icon="ungroup"
              variant="secondary"
              onClick={onUnGroup}
            />
            <IconButton icon="trash" variant="secondary" onClick={onDelete} />
          </div>
        )}
      </div>
      <div
        className={`TabGroupContainer--List ${
          expanded
            ? "TabGroupContainer--List--expanded"
            : "TabGroupContainer--List--collapsed"
        }`}
      >
        <List>{children}</List>
      </div>
    </div>
  );
}
