import React, { useEffect, useRef, useState, Children } from "react";
import "./style.scss";
import Stack from "../../Layout/Stack/Stack";
import Text from "../../Display/Text/Text";
import { ChevronDown, X } from "react-feather";
import listenForOutsideClicks from "../../../hooks/listenForOutsideClicks";
import { RotatingLines } from "react-loader-spinner";

interface Props {
  title?: string;
  placeholder?: string;
  width?: "full" | "auto" | "fit-content";
  direction?: "row" | "column";
  spacing?: number;
  selected: number | null;
  enableSearch?: boolean;
  enableBorder?: boolean;
  softBackground?: boolean;
  loading?: boolean;
  children: React.ReactNode;
}

export default function DropDownMenu({
  title,
  placeholder,
  width = "full",
  direction = "column",
  spacing = 6,
  selected = null,
  enableSearch = false,
  enableBorder = true,
  softBackground = false,
  loading = false,
  children,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);
  const [listening, setListening] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<React.ReactNode[]>([]);

  useEffect(
    () =>
      listenForOutsideClicks({
        listening,
        setListening,
        ref,
        onOutsideClick: () => {
          setExpanded(false);
        },
      })(),
    [listening, ref],
  );

  useEffect(() => {
    if (!expanded) {
      setSearchValue("");
    }
  }, [expanded]);

  useEffect(() => {
    if (enableSearch) {
      const results = Children.toArray(children).filter((child, index) => {
        if (!React.isValidElement(child)) return false;
        const { searchValue: searchValueAux } = child.props;
        return searchValueAux
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });

      setSearchResults(results);
    } else {
      setSearchResults(Children.toArray(children));
    }
  }, [searchValue, children, enableSearch]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const getChild = (index: number) => {
    const element = Children.toArray(children)[index];
    if (!React.isValidElement(element)) return null;
    const { children: aux } = element.props;
    return aux;
  };

  const Icon = expanded ? X : ChevronDown;
  return (
    <Stack
      spacing={spacing}
      width={width}
      direction={direction}
      alignItems={direction === "row" ? "center" : "flex-start"}
    >
      {title && <Text weight="bold">{title}</Text>}
      <div className={`DropdownMenuContainer ${width}`}>
        <div
          className={`DropdownMenuContainer--Placeholder ${width} ${
            enableBorder ? "DropdownMenuContainer--Placeholder--Border" : ""
          } ${softBackground ? "DropdownMenuContainer--Placeholder--80" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            toggleExpanded();
          }}
        >
          {selected !== null ? (
            <div className="DropdownMenuContainer--Placeholder--Selected">
              {getChild(selected)}
            </div>
          ) : enableSearch ? (
            <input
              className="DropdownMenuContainer--Placeholder--Input"
              placeholder={placeholder}
              onClick={(e) => {
                e.stopPropagation();
                toggleExpanded();
              }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          ) : (
            <Text variant="secondary">{placeholder}</Text>
          )}
          <Icon className="DropdownMenuContainer--Placeholder--Icon" />
        </div>
        <div
          className={`DropdownMenuContainer--Content ${
            expanded ? "expanded" : "collapsed"
          }  ${enableBorder ? "DropdownMenuContainer--Content--Border" : ""}
          ${softBackground ? "DropdownMenuContainer--Content--80" : ""}`}
          ref={ref}
          onClick={() => {
            setExpanded(false);
          }}
        >
          {loading ? (
            <div
              style={{
                padding: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RotatingLines
                visible={true}
                width="18"
                strokeWidth="2"
                strokeColor="gray"
                animationDuration="1"
              />
            </div>
          ) : searchResults.length > 0 ? (
            searchResults
          ) : (
            <div
              style={{
                padding: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text variant="secondary">No results found</Text>
            </div>
          )}
        </div>
      </div>
    </Stack>
  );
}
