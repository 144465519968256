import { useMutation, useQueryClient } from "react-query";
import { createCommand, ICommand } from "../../api/commandsApi";

interface Props {
  onSuccess: () => void;
  onError: (error: Error) => void;
}

export function useCreateCommand({ onSuccess, onError }: Props) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, command }: { userId: string; command: ICommand }) =>
      createCommand(userId, command),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("commands");
        onSuccess();
      },
      onError: (error: Error) => {
        onError(error);
      },
    },
  );
}
