import React from "react";
import "./style.scss";

import Text from "../Text/Text";
import Button from "../../Inputs/Button/Button";
import Carousel from "../Carousel/Carousel";
import { ArrowLeft, ArrowRight } from "react-feather";

export default function LearnTabDockSlider() {
  return (
    <div className="LearnTabDockSliderContainer">
      <Carousel
        renderArrowPrev={(onClickHandler, hasPrev) => {
          return (
            <div className="prev-button">
              <Button
                onClick={onClickHandler}
                disabled={!hasPrev}
                variant="outlined"
                startIcon={<ArrowLeft />}
                label=""
                color="tertiary"
              />
            </div>
          );
        }}
        renderArrowNext={(onClickHandler, hasNext) => {
          return (
            <div className="next-button">
              <Button
                onClick={onClickHandler}
                disabled={!hasNext}
                variant="outlined"
                endIcon={<ArrowRight />}
                label=""
                color="tertiary"
              />
            </div>
          );
        }}
      >
        <div>
          <img
            src="https://i.imgur.com/9Ldr7cy.png"
            alt="Workspaces"
            width={"100%"}
          />
          <div className="image-legend">
            <Text weight="bold" size="subtitle">
              Workspaces
            </Text>
            <Text
              style={{
                textAlign: "justify",
              }}
            >
              A workspace is a window in your browser. You can save the entire
              workspace to access it later and view it from a new tab.
            </Text>
          </div>
        </div>
        <div>
          <img
            src="https://i.imgur.com/VTq3Bwk.png"
            alt="Dashboard"
            width={"100%"}
          />
          <div className="image-legend">
            <Text weight="bold" size="subtitle">
              Dashboard
            </Text>
            <Text
              style={{
                textAlign: "justify",
              }}
            >
              Access your dashboard from my.tabdock.app and manage all your
              workspaces, commands and settings
            </Text>
          </div>
        </div>
        <div>
          <img
            src="https://i.imgur.com/AspGc3Z.png"
            alt="Commands"
            width={"100%"}
          />
          <div className="image-legend">
            <Text weight="bold" size="subtitle">
              Commands
            </Text>
            <Text
              style={{
                textAlign: "justify",
              }}
            >
              In your Google search box, you can activate commands by typing{" "}
              <b>z</b> followed by a space or tab. This allows you to enter your
              commands.
            </Text>
          </div>
        </div>
      </Carousel>
    </div>
  );
}
