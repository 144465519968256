import React from "react";
import OpenTabRow from "../OpenTabRow/OpenTabRow";
import OpenTabGroup from "../OpenTabGroup/OpenTabGroup";

type TabsType = {
  id: string;
  title: string;
  url: string;
  iconUrl: string;
  chromeId?: string;
};

type TabGroupType = {
  id: string;
  title: string;
  color: string;
  tabs?: TabsType[];
  chromeId?: string;
};

type TabsOrGroups = TabsType | TabGroupType;

interface Props {
  tabsOrGroups?: TabsOrGroups[];
  onClickTab?: (chromeId: string) => void;
}

export default function OpenTabComponent({
  tabsOrGroups,
  onClickTab = () => {},
}: Props) {
  if (tabsOrGroups === undefined) return <></>;
  return (
    <>
      {tabsOrGroups?.map((tabOrGroup) => {
        if (typeof tabOrGroup === "object" && "tabs" in tabOrGroup) {
          const group = tabOrGroup as TabGroupType;
          return (
            <OpenTabGroup
              id={group.chromeId!}
              title={group.title ?? ""}
              color={group.color ?? "blue"}
              key={group.chromeId}
              tabs={group.tabs}
            >
              {group.tabs?.map((tab, index) => (
                <OpenTabRow
                  {...tab}
                  id={tab.chromeId!}
                  key={tab.chromeId}
                  onClick={() => {
                    onClickTab(tab.chromeId ?? "");
                  }}
                />
              ))}
            </OpenTabGroup>
          );
        } else {
          const tab = tabOrGroup as TabsType;
          return (
            <OpenTabRow
              {...tab}
              id={tab.chromeId!}
              key={tab.chromeId}
              onClick={() => {
                onClickTab(tab.chromeId ?? "");
              }}
            />
          );
        }
      })}
    </>
  );
}
