import React from "react";
import "./style.scss";
import Avatar from "react-avatar";

interface Props {
  username: string;
  size?: number;
}

export default function ProfileImage({ username, size = 48 }: Props) {
  return <Avatar name={username} round size={`${size}`} />;
}
