import React, { useEffect, useState } from "react";
import "./style.scss";
import ReactDOMServer from "react-dom/server";
import TabInfo from "../TabInfo/TabInfo";
import { useDragAndDrop } from "../../../Contexts/DragAndDropProvider";
import { useSelection } from "../../../Contexts/OpenTabSelectionContext";
import { MultipleDragNDropTabIcon } from "../../Icons/Icons";
import Text from "../Text/Text";
import Pair from "../../Layout/Pair/Pair";

interface Props {
  id: string;
  title: string;
  url: string;
  iconUrl: string;
  onClick?: () => void;
}

export default function OpenTabRow({
  id,
  title,
  url,
  iconUrl,
  onClick = () => {},
}: Props) {
  const [dragging, setDragging] = useState(false);
  const { handleDragStart, handleDragEnd } = useDragAndDrop();
  const {
    toggleTabSelection,
    isSelected,
    draggingSelection,
    setDraggingSelection,
    selectedTabs,
  } = useSelection();

  const createCustomDragImage = () => {
    const dragImage = document.createElement("div");
    dragImage.style.position = "absolute";
    dragImage.style.top = "-1000px";
    dragImage.style.left = "-1000px";

    const tabInfoHtml = ReactDOMServer.renderToString(
      <div
        style={{
          padding: "10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
        }}
      >
        <TabInfo title={title} url={url} iconUrl={iconUrl} />
      </div>,
    );
    dragImage.innerHTML = tabInfoHtml;

    document.body.appendChild(dragImage);
    return dragImage;
  };

  const createCustomMultipleDragImage = (number: number) => {
    const dragImage = document.createElement("div");
    dragImage.style.position = "absolute";
    dragImage.style.top = "-1000px";
    dragImage.style.left = "-1000px";

    const tabInfoHtml = ReactDOMServer.renderToString(
      <div
        style={{
          padding: "10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
        }}
      >
        <Pair direction="row" spacing={5} align="center">
          <MultipleDragNDropTabIcon />
          <Text variant="secondary">Add {number} Tabs</Text>
        </Pair>
      </div>,
    );
    dragImage.innerHTML = tabInfoHtml;

    document.body.appendChild(dragImage);
    return dragImage;
  };

  useEffect(() => {
    if (draggingSelection) {
      setDragging(isSelected(id));
    } else {
      setDragging(false);
    }
  }, [draggingSelection, id, isSelected]);

  return (
    <div
      className={`OpenTabRow ${dragging ? "OpenTabRowDragging" : ""}`}
      id={id}
      key={id}
      onClick={onClick}
      draggable
      onDragEnd={() => {
        handleDragEnd();
        setDragging(false);
        setDraggingSelection(false);
      }}
      onDragStart={(e) => {
        let dragImage = createCustomDragImage();
        if (isSelected(id) && Object.keys(selectedTabs).length > 1) {
          setDraggingSelection(true);
          dragImage = createCustomMultipleDragImage(
            Object.keys(selectedTabs).length,
          );
        }
        e.dataTransfer.setDragImage(dragImage, 0, 0);
        handleDragStart(e, { chromeId: id, title, url, faviconUrl: iconUrl });
        setDragging(true);
      }}
    >
      <TabInfo title={title} url={url} iconUrl={iconUrl} />
      <input
        type="checkbox"
        className="OpenTabRow--checkbox"
        onClick={(e) => {
          e.stopPropagation();
        }}
        checked={isSelected(id)}
        onChange={() =>
          toggleTabSelection({ chromeId: id, title, url, faviconUrl: iconUrl })
        }
      />
    </div>
  );
}
