import { supabaseClient } from "../config/supabase-client";
const USER_INTEGRATIONS = "userIntegrations";

export type INTEGRATIONS = "google_calendar";

export async function createUserIntegration(
  userId: string,
  integration: INTEGRATIONS,
  token: JSON,
  configuration: JSON,
) {
  const { error } = await supabaseClient.from(USER_INTEGRATIONS).insert([
    {
      userId: userId,
      integration_type: integration,
      token: token,
      configuration: configuration,
    },
  ]);

  if (error) throw error;
}

export async function getUserIntegrationToken(
  userId: string,
  integration: INTEGRATIONS,
) {
  const { data, error } = await supabaseClient
    .from(USER_INTEGRATIONS)
    .select("token")
    .eq("userId", userId)
    .eq("integration_type", integration);

  if (error) throw error;
  if (!data) return null;
  return data[0]?.token;
}

type IntegrationData = {
  token: {
    access_token: string;
    refresh_token?: string;
  };
  configuration: {
    calendarIds: string[];
    timezone: string;
    format: "12" | "24";
  };
} | null;

export async function getUserIntegrationData(
  userId: string,
  integrationType: INTEGRATIONS,
): Promise<IntegrationData> {
  const { data, error } = await supabaseClient
    .from(USER_INTEGRATIONS)
    .select("*")
    .eq("userId", userId)
    .eq("integration_type", integrationType);

  if (error) throw error;
  if (!data) return null;

  if (data.length === 0) return null;

  data[0].token = {
    access_token: data[0].token.access_token,
    scope: data[0].token.scope,
    token_type: data[0].token.token_type,
  };

  return data[0];
}

export async function deleteUserIntegration(
  userId: string,
  integration: INTEGRATIONS,
) {
  const { error } = await supabaseClient
    .from(USER_INTEGRATIONS)
    .delete()
    .eq("userId", userId)
    .eq("integration_type", integration);

  if (error) throw error;
}

export async function updateUserIntegrationToken(
  userId: string,
  integration: INTEGRATIONS,
  token: JSON,
) {
  const { error } = await supabaseClient
    .from(USER_INTEGRATIONS)
    .update({ token: token })
    .eq("userId", userId)
    .eq("integration_type", integration);

  if (error) throw error;
}

export async function updateUserIntegrationConfiguration(
  userId: string,
  integration: INTEGRATIONS,
  configuration: JSON,
) {
  const { error } = await supabaseClient
    .from(USER_INTEGRATIONS)
    .update({ configuration: configuration })
    .eq("userId", userId)
    .eq("integration_type", integration);

  if (error) throw error;
}

export async function updateGoogleIntegrationConfiguration_HourFormat(
  userId: string,
  format: "12" | "24",
) {
  const IntegrationData = await getUserIntegrationData(
    userId,
    "google_calendar",
  )!;

  if (!IntegrationData) return;
  const { configuration } = IntegrationData;

  const { error } = await supabaseClient
    .from(USER_INTEGRATIONS)
    .update({ configuration: { ...configuration, format: format } })
    .eq("userId", userId)
    .eq("integration_type", "google_calendar");

  if (error) throw error;
}

export async function updateGoogleIntegrationConfiguration_CalendarIds(
  userId: string,
  calendarIds: string[],
) {
  const IntegrationData = await getUserIntegrationData(
    userId,
    "google_calendar",
  )!;

  if (!IntegrationData) return;
  const { configuration } = IntegrationData;

  const { error } = await supabaseClient
    .from(USER_INTEGRATIONS)
    .update({ configuration: { ...configuration, calendarIds: calendarIds } })
    .eq("userId", userId)
    .eq("integration_type", "google_calendar");

  if (error) throw error;
}

export async function updateGoogleIntegrationConfiguration_Timezone(
  userId: string,
  timezone: string,
) {
  const IntegrationData = await getUserIntegrationData(
    userId,
    "google_calendar",
  )!;

  if (!IntegrationData) return;
  const { configuration } = IntegrationData;

  const { error } = await supabaseClient
    .from(USER_INTEGRATIONS)
    .update({ configuration: { ...configuration, timezone: timezone } })
    .eq("userId", userId)
    .eq("integration_type", "google_calendar");

  if (error) throw error;
}
