import React from "react";
import "./style.scss";

type Size =
  | "clock"
  | "super-clock"
  | "large"
  | "title"
  | "subtitle"
  | "regular"
  | "small"
  | "tiny"
  | "micro";
type Weight = "light" | "regular" | "bold" | "semibold";

interface Props {
  size?: Size;
  weight?: Weight;
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "error"
    | "success"
    | "white"
    | "black";
  lineThrough?: boolean;
  elevated?: boolean;
  italic?: boolean;
  cutText?: boolean;
  alignCenter?: boolean;
  personalizedSize?: boolean;
  personalizedSizeValue?: string;
  [key: string]: any;
}

export default function Text({
  size = "regular",
  weight = "regular",
  variant = "primary",
  lineThrough = false,
  elevated = false,
  italic = false,
  cutText = false,
  alignCenter = false,
  personalizedSize = false,
  personalizedSizeValue,
  ...props
}: Props) {
  if (personalizedSize) {
    return (
      <p
        className={`Text ${size} ${weight} ${variant} ${
          lineThrough ? "line-through" : ""
        } ${elevated ? "elevated" : ""} ${italic ? "italic" : ""} ${
          cutText ? "cut" : ""
        } ${alignCenter ? "align-center" : ""}`}
        style={{
          fontSize: personalizedSizeValue,
        }}
        {...props}
      >
        {props.children}
      </p>
    );
  }
  return (
    <p
      className={`Text ${size} ${weight} ${variant} ${
        lineThrough ? "line-through" : ""
      } ${elevated ? "elevated" : ""} ${italic ? "italic" : ""} ${
        cutText ? "cut" : ""
      } ${alignCenter ? "align-center" : ""}`}
      {...props}
    >
      {props.children}
    </p>
  );
}
