import React, { useEffect } from "react";
//import { createPortal } from "react-dom";
import "./style.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  noCenter?: boolean;
}

export default function ModalContainer({
  open,
  onClose,
  children,
  noCenter = false,
}: Props) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeOnEsc = (e: { charCode: any; keyCode: any }) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", closeOnEsc, false);
    return () => {
      document.removeEventListener("keydown", closeOnEsc, false);
    };
  }, [closeOnEsc]);

  return open ? (
    <div
      className={`ModalContainer ${!noCenter ? "ModalContainerCenter" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <div
        className="ModalContainer--Content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  ) : null;
}
