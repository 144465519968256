import "./style.scss";
import TabInfo from "../TabInfo/TabInfo";
import TagList from "../TagList/TagList";
import IconButton from "../../Inputs/IconButton/IconButton";
import Pair from "../../Layout/Pair/Pair";
import { useRef, useState } from "react";
import MessageCard from "../MessageCard/MessageCard";
import CopyButton from "../../utils/CopyButton/CopyButton";

interface Props {
  id: string;
  chromeId?: string;
  onDelete: () => void;
  onClick?: () => void;
  hideOptions?: boolean;
  locked?: boolean;
}

export default function TabRow({
  id,
  chromeId,
  title,
  url,
  iconUrl,
  tags,
  onUpdateTags,
  onAddTag,
  onDelete = () => {},
  onClick = () => {},
  hideOptions = false,
  locked = false,
}: Props &
  React.ComponentProps<typeof TabInfo> &
  React.ComponentProps<typeof TagList>) {
  const [isHovered, setIsHovered] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const messageTimeoutRef = useRef<any>(null);

  const handleMouseEnter = (e: React.MouseEvent) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
    e.stopPropagation();

    if (messageTimeoutRef.current) {
      clearTimeout(messageTimeoutRef.current);
    }

    messageTimeoutRef.current = setTimeout(() => {
      setIsHovered(true);
    }, 1500);
  };

  const handleMouseLeave = () => {
    clearTimeout(messageTimeoutRef.current);
    setIsHovered(false);
  };

  return (
    <div
      key={id}
      className="TabRow"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={(e) => setCursorPosition({ x: e.clientX, y: e.clientY })}
      onClick={locked ? () => {} : onClick}
    >
      <div className="TabRow--TabInfo">
        <TabInfo title={title} url={url} iconUrl={iconUrl} />
      </div>
      <div className="TabRow--TagList" style={{ display: "none" }}>
        <TagList tags={tags} onUpdateTags={onUpdateTags} onAddTag={onAddTag} />
      </div>
      <div
        className="TabRowActions"
        style={{
          display: hideOptions || locked ? "none" : "",
        }}
      >
        <Pair direction="row">
          <CopyButton copyText={url} variant="tertiary" />
          <IconButton
            icon="trash"
            variant="tertiary"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          />
        </Pair>
      </div>
      {isHovered && !locked && (
        <div
          style={{
            position: "absolute",
            top: cursorPosition.y + 10,
            left: cursorPosition.x - 150,
          }}
        >
          <MessageCard
            title={title}
            message={url}
            position={{
              x: cursorPosition.x + 5,
              y: cursorPosition.y + 10,
            }}
          />
        </div>
      )}
    </div>
  );
}
