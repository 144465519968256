import { supabaseClient } from "../config/supabase-client";

export async function getUsernameAndEmail(id?: string) {
  const { data, error } = await supabaseClient
    .from("user")
    .select("username, email")
    .eq("id", id)
    .single();

  if (error) throw error;
  return data;
}

export async function deleteUser() {
  const { data, error } = await supabaseClient.rpc("delete_user");
  if (error) throw error;
  return data;
}
