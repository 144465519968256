import React from "react";
import "./style.scss";
import List from "../../Layout/List/List";
import Pair from "../../Layout/Pair/Pair";
import Text from "../Text/Text";
import Button from "../../Inputs/Button/Button";
import { Plus } from "react-feather";

interface Props {
  children?: React.ReactNode;
  onNewTag?: () => void;
}

export default function EditTagList({ children, onNewTag }: Props) {
  const getNumberOfTags = () => {
    if (!React.isValidElement(children)) return 0;
    return children.props.tags.length;
  };

  return (
    <div className="EditTagListContainer">
      <div className="EditTagListContainer--Header">
        <Pair direction="column" spacing={4}>
          <Text size="subtitle" weight="bold" variant="primary">
            Tags
          </Text>
          <Text variant="secondary">{getNumberOfTags()} tags</Text>
        </Pair>
        <div className="EditTagListContainer--Header--Right">
          <Button
            label="New"
            endIcon={<Plus strokeWidth={2} size={20} />}
            onClick={onNewTag}
          />
        </div>
      </div>
      <div className="EditTagListContainer--List">
        <List>{children}</List>
      </div>
    </div>
  );
}
