import "./style.scss";
import Text from "../Text/Text";

interface Props {
  size: number;
  strokeWidth?: number;
  color: string;
  value: number;
  max: number;
  text: string;
}

export default function CircularProgressbar({
  size,
  strokeWidth = 10,
  color,
  value,
  max,
  text,
}: Props) {
  const radius = (size - strokeWidth) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * value) / max;
  const percentage = (value / max) * 100;

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <svg width={size} height={size} viewBox={viewBox}>
        <circle
          fill={"none"}
          stroke={"#ddd"}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          fill={"none"}
          stroke={percentage >= 65 ? "red" : color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
      <div
        style={{
          position: "absolute",
          top: `calc(50% - 14px)`,
          left: `calc(50% - ${3.75 * text.length}px)`,
        }}
      >
        <Text weight="bold" variant="secondary" size="regular">
          {text}
        </Text>
      </div>
    </div>
  );
}
