type Props = Record<string, any>;

export default function listenForOutsideClicks({
  listening,
  setListening,
  ref,
  onOutsideClick,
}: Props): () => void {
  return () => {
    if (listening) return;
    if (!ref.current) return;
    setListening(true);
    ["click", "touchstart"].forEach(() => {
      document.addEventListener("click", (evt) => {
        const cur = ref.current;
        const node = evt.target;
        if (!cur) return;
        if (cur?.contains(node)) return;
        onOutsideClick();
      });
    });
  };
}
