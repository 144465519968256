import { supabaseClient } from "../config/supabase-client";
const USER_SETTINGS = "usersettings";

export async function getUserSettings(userId?: string): Promise<any> {
  const { data, error } = await supabaseClient
    .from(USER_SETTINGS)
    .select("*")
    .eq("userId", userId);

  if (error) throw error;

  return data[0];
}

export async function updateUserTheme(userId: string, theme: string) {
  const { data, error } = await supabaseClient
    .from(USER_SETTINGS)
    .update({ theme })
    .eq("userId", userId)
    .select("*");

  if (error) throw error;
  return data;
}

export async function updateUserSettingsImageBackground(
  id: string,
  image: string,
) {
  const { data, error } = await supabaseClient
    .from(USER_SETTINGS)
    .update({ bgImageQuery: image })
    .eq("id", id)
    .select("*");

  if (error) throw error;
  return data;
}
