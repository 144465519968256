import React, { useState } from "react";
import "./style.scss";
import Pair from "../../Layout/Pair/Pair";
import TextPair from "../TextPair/TextPair";
import Logo from "../Logo/Logo";

interface Props {
  title: string;
  url: string;
  iconUrl: string;
}

export default function TabInfo({ title, url, iconUrl }: Props) {
  const [src, setSrc] = useState(iconUrl);
  const urlContainsNewTab = url?.includes("chrome://newtab/");

  const handleError = () => {
    setSrc("https://i.imgur.com/di2d4PI.png");
  };

  return (
    <Pair direction="row" align="center" spacing={18}>
      {urlContainsNewTab ? (
        <Logo type="icon-small" />
      ) : (
        <div className="TabInfoImage">
          <img src={src} alt="tab icon" loading="lazy" onError={handleError} />
        </div>
      )}
      <TextPair
        header={title}
        subheader={url}
        size="h7"
        variant="primary"
        variant_2="tertiary"
        spacing={4}
        maxLength={30}
      />
    </Pair>
  );
}
