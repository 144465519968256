import React from "react";
import "./style.scss";
import Modal from "../../Layout/Modal/Modal";
import Text from "../../Display/Text/Text";
import Pair from "../../Layout/Pair/Pair";
import Button from "../Button/Button";
import Section from "../../Layout/Section/Section";

interface Props {
  openModal: boolean;
  onClose: () => void;
  title: string;
  buttonLabel: string;
  onCancel: () => void;
  onConfirm: () => void;
  destructiveAction?: boolean;
  description: string;
  important?: boolean;
  aditionalContent?: React.ReactNode;
}

export default function ConfirmationModal({
  openModal,
  onClose,
  title,
  buttonLabel,
  onConfirm,
  onCancel,
  destructiveAction = true,
  description,
  important = false,
  aditionalContent,
}: Props) {
  return (
    <Modal
      open={openModal}
      onClose={!important ? onClose : () => {}}
      height={aditionalContent ? "360px" : "260px"}
      header={
        <Text weight="bold" size="subtitle">
          {title}
        </Text>
      }
      footer={
        <Pair direction="row">
          <Button
            label="cancel"
            color="tertiary"
            onClick={() => {
              onCancel();
              onClose();
            }}
          />
          <Button
            label={buttonLabel}
            onClick={() => {
              onConfirm();
              onClose();
            }}
            color={destructiveAction ? "error" : "primary"}
          />
        </Pair>
      }
    >
      <Section alignItems="flex-start" justifyContent="center" padding={0}>
        <Text>{description}</Text>
        {aditionalContent}
      </Section>
    </Modal>
  );
}
