import React from "react";
import "./style.scss";
import { CloseIcon, WarningIcon, HelpIcon } from "../../Icons/Icons";
import Pair from "../../Layout/Pair/Pair";
import Text from "../Text/Text";
import { useMediaQuery } from "react-responsive";

interface Props {
  title: string;
  message: string;
  removable?: boolean;
  onRemove?: () => void;
  type?: "error" | "warning" | "info" | "onScreen";
  optionsComponent?: React.ReactNode;
}

export default function ScreenMessage({
  title,
  message,
  removable = false,
  onRemove = () => {},
  type = "info",
  optionsComponent = null,
}: Props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const Icon =
    type === "error" ? (
      <WarningIcon className="ScreenMessageContainer--Icon" />
    ) : type === "warning" ? (
      <WarningIcon className="ScreenMessageContainer--Icon" />
    ) : (
      <HelpIcon
        className={
          type === "onScreen"
            ? "ScreenMessageContainer--Icon-onScreen"
            : "ScreenMessageContainer--Icon"
        }
      />
    );

  return (
    <div className={`ScreenMessageContainer ScreenMessageContainer--${type}`}>
      <div className="ScreenMessageContainer--Message">
        {!isTabletOrMobile ? Icon : null}
        <Pair>
          <Text
            variant={type === "onScreen" ? "primary" : "white"}
            size="subtitle"
            weight="semibold"
          >
            {title}
          </Text>
          <Text variant={type === "onScreen" ? "primary" : "white"}>
            {message}
          </Text>
        </Pair>
      </div>

      {optionsComponent && (
        <div className="ScreenMessageContainer--Options">
          {optionsComponent}
        </div>
      )}

      {removable && (
        <div className="ScreenMessageContainer--CloseIcon">
          <CloseIcon
            onClick={onRemove}
            className="ScreenMessageContainer--Icon2"
          />
        </div>
      )}
    </div>
  );
}
