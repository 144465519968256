import React, { useState } from "react";
import IconButton from "../../Inputs/IconButton/IconButton";

interface Props {
  copyText: string;
  variant?: "primary" | "secondary" | "tertiary" | "extension" | "success";
}

const CopyButton = ({ copyText, variant = "secondary" }: Props) => {
  const [copied, setCopied] = useState(false);

  const handleClick = async (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(copyText);
    setCopied(true);
    setTimeout(() => setCopied(false), 2500);
  };

  return (
    <IconButton
      icon={copied ? "check" : "copy"}
      size={10}
      variant={copied ? "success" : variant}
      tooltip={copied ? "Copied!" : "Copy link"}
      tooltipPosition="bottom"
      onClick={handleClick}
      fill={copied}
    />
  );
};

export default CopyButton;
