import { useMutation } from "react-query";
import { updateUserTheme } from "../../api/userSettingsApi";

export function useUpdateUserTheme() {
  return useMutation(
    ({ userId, theme }: { userId: string; theme: string }) =>
      updateUserTheme(userId, theme),
    {
      onSuccess: () => {},
      onError: (error: Error) => {},
    },
  );
}
