import React from "react";
import "./style.scss";
import Logo from "../Logo/Logo";
import InstallationStep from "../InstallationStep/InstallationStep";
import Text from "../Text/Text";
import Button from "../../Inputs/Button/Button";
import { HelpIcon2 } from "../../Icons/Icons";

interface Props {
  currentStep: number;
}

export default function InstallationStepsSidebar({ currentStep }: Props) {
  const steps = [
    {
      title: "Welcome to TabDock!",
      description: "We are excited to have you on board",
    },
    {
      title: "Pin TabDock",
      description: "Pin extension for quick access",
    },
    {
      title: "Learn the essentials",
      description: "Take a look into the basics",
    },
    {
      title: "Kbar",
      description: "Quick access to the extension",
    },
    {
      title: "Your New Tab Page",
      description: "Meet your new tab page",
    },
    {
      title: "Sign In / Sign Up",
      description: "Sign In to get access to all features",
    },
  ];
  return (
    <div className="InstallationStepsSidebarContainer">
      <div className="InstallationStepsSidebarContainer--header">
        <Logo type="icon" />
      </div>
      <div className="InstallationStepsSidebarContainer--steps">
        {steps.map((step, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <InstallationStep
              key={index}
              title={step.title}
              description={step.description}
              index={index + 1}
              state={
                currentStep === index + 1
                  ? "active"
                  : currentStep > index + 1
                  ? "completed"
                  : "pending"
              }
            />
            {index !== steps.length - 1 && (
              <div
                className={
                  currentStep === index + 1
                    ? "active-line"
                    : currentStep > index + 1
                    ? "completed-line"
                    : "pending-line"
                }
              />
            )}
          </div>
        ))}
      </div>
      <div className="InstallationStepsSidebarContainer--footer">
        <HelpIcon2 />
        <Text weight="bold">Having trouble?</Text>
        <Text>
          Feel free to contact us and we will always help you through the
          process.
        </Text>

        <Button
          label="Contact Us"
          variant="outlined"
          color="tertiary"
          onClick={() => {
            window.location.href = "mailto:angel@tabdock.app";
          }}
        />
      </div>
    </div>
  );
}
