import Text from "../../Display/Text/Text";
import TextFieldBox from "../TextFieldBox/TextFieldBox";
import Stack from "../../Layout/Stack/Stack";

interface Props {
  width?: "full" | "auto" | "fit-content";
  title: string;
  placeholder?: string;
  titleVariant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "error"
    | "success"
    | "white"
    | "black";
  value?: string;
  onChange?: (value: string) => void;
  inputType?: "text" | "password" | "number" | "email";
  error?: string | null;
  direction?: "row" | "column";
  spacing?: number;
  disabled?: boolean;
}

export default function TextField({
  width = "full",
  title,
  placeholder,
  titleVariant = "primary",
  value,
  onChange = () => {},
  inputType = "text",
  error = null,
  direction = "column",
  spacing = 6,
  disabled = false,
}: Props) {
  return (
    <Stack
      spacing={spacing}
      width={width}
      direction={direction}
      alignItems={direction === "row" ? "center" : "flex-start"}
    >
      <Text weight="bold" variant={titleVariant}>
        {title}
      </Text>
      <TextFieldBox
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        inputType={inputType}
        error={error !== null}
        width={width}
        disabled={disabled}
      />
      {error !== null ? (
        <Text variant="error" size="small">
          {error}
        </Text>
      ) : null}
    </Stack>
  );
}
