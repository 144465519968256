import { createContext, useContext, useState } from "react";

export interface ITab {
  chromeId: string;
  title: string;
  url: string;
  faviconUrl: string;
}

interface IDragAndDropContext {
  isDragging: boolean;
  handleDragStart: (e: React.DragEvent<HTMLDivElement>, tab: ITab) => void;
  handleDragEnd: () => void;
  handleDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => ITab;
}

const DragAndDropContext = createContext<IDragAndDropContext>({
  isDragging: false,
  handleDragStart: () => {},
  handleDragEnd: () => {},
  handleDragOver: () => {},
  handleDrop: () => {
    return {
      chromeId: "",
      title: "",
      url: "",
      faviconUrl: "",
    };
  },
});

export const DragAndDropProvider = ({ children }: any) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, tab: ITab) => {
    e.dataTransfer.setData("tab", JSON.stringify(tab));
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): ITab => {
    e.preventDefault();
    setIsDragging(false);
    const tab = JSON.parse(e.dataTransfer.getData("tab"));
    return tab;
  };

  return (
    <DragAndDropContext.Provider
      value={{
        isDragging,
        handleDragStart,
        handleDragEnd,
        handleDragOver,
        handleDrop,
      }}
    >
      {children}
    </DragAndDropContext.Provider>
  );
};

export const useDragAndDrop = () => {
  return useContext(DragAndDropContext);
};
