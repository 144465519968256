import React, { useState } from "react";
import "./style.scss";

interface Props {
  renderArrowPrev: (
    onClickHandler: () => void,
    hasPrev: boolean,
  ) => JSX.Element;
  renderArrowNext: (
    onClickHandler: () => void,
    hasNext: boolean,
  ) => JSX.Element;
  children: React.ReactNode;
}

export default function Carousel({
  renderArrowPrev,
  renderArrowNext,
  children,
}: Props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = React.Children.toArray(children);

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="carousel">
      <div className="carousel__button">
        {renderArrowPrev(handlePrev, currentSlide > 0)}
      </div>

      <div className="carousel-slides">
        {slides.map((slide, index) => (
          <div
            className={`carousel__item ${
              index === currentSlide ? "active-slide" : ""
            }`}
            key={index}
          >
            {slide}
          </div>
        ))}
      </div>
      <div className="carousel__button">
        {renderArrowNext(handleNext, currentSlide < slides.length - 1)}
      </div>
    </div>
  );
}
