import React from "react";
import "./style.scss";
import Text from "../Text/Text";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import WorkspacesTopBar from "../../Inputs/WorkspacesTopBar/WorkspacesTopBar";
import List from "../../Layout/List/List";
import { getTabsAndGroupsCount } from "../../../utils/getTabsAndGroupsCount";
import TabList from "../TabList/TabList";
import TabComponent from "../TabComponent/TabComponent";
import { WorkspaceType } from "../../../Types/Types";
import TextPair from "../TextPair/TextPair";
import Pair from "../../Layout/Pair/Pair";
import { ITab, useDragAndDrop } from "../../../Contexts/DragAndDropProvider";
import { useSelection } from "../../../Contexts/OpenTabSelectionContext";
import Stack from "../../Layout/Stack/Stack";
import Button from "../../Inputs/Button/Button";

interface Props {
  title: string;
  tooltip?: string | null;
  workspaces: WorkspaceType[];
  replaceMode?: boolean;
  enableTitleEditing?: boolean;
  showLimitReached?: boolean;
  upgradePlanLink?: string;
  openWorkspaceInNewWindow: (workspaceId: string) => void;
  addWorkspaceToCurrentWindow: (workspaceId: string) => void;
  replaceCurrentWindowWithWorkspace: (workspaceId: string) => void;
  deleteWorkspace: (workspaceId: string) => void;
  onClickTab: (url: string, id: string) => void;
  deleteTab: (tabId: string) => void;
  onDeleteTabGroup: (groupId: string) => void;
  onUnGroup: (groupId: string) => void;
  onOpenTabGroup: (groupId: string) => void;
  focusTab: (chromeId: string) => void;
  deleteAndCloseTab: (tabId: string, chromeId: string) => void;
  deleteAndCloseTabGroup: (chromeId: string) => void;
  ungroupOpenTabGroup: (chromeId: string) => void;
  focusTabGroup: (chromeId: string) => void;
  addTabToWorkspace: (workspaceId: string, tab: ITab[]) => void;
  pullCloudChanges?: (workspaceId: string) => void;
  pushLocalChanges?: (workspaceId: string) => void;
  onSaveTitle?: (workspaceId: string, newTitle: string) => void;
}

export default function WorkspacesList({
  title,
  tooltip = null,
  workspaces: workspacesInit,
  replaceMode = false,
  enableTitleEditing = false,
  showLimitReached = false,
  upgradePlanLink = "",
  openWorkspaceInNewWindow,
  addWorkspaceToCurrentWindow,
  replaceCurrentWindowWithWorkspace,
  deleteWorkspace,
  onClickTab,
  deleteTab,
  onDeleteTabGroup,
  onUnGroup,
  onOpenTabGroup,
  focusTab,
  deleteAndCloseTab,
  deleteAndCloseTabGroup,
  ungroupOpenTabGroup,
  focusTabGroup,
  addTabToWorkspace,
  pullCloudChanges = () => {},
  pushLocalChanges = () => {},
  onSaveTitle = () => {},
}: Props) {
  const [workspaces, setWorkspaces] = React.useState<WorkspaceType[] | null>(
    workspacesInit,
  );
  const [searchValue, setSearchValue] = React.useState<string>("");
  const { handleDragOver, handleDrop, isDragging } = useDragAndDrop();
  //const { selectedTabs, draggingSelection } = useSelection();
  const selectedTabs = {};
  const draggingSelection = false;

  const noWorkspaces = (
    <div className="WorkspacesList--NoWorkspaces">
      <TextPair
        header="You don't have any workspaces yet"
        subheader="Create a new workspace to save your tabs and groups"
        align="center"
        size="h4"
      />
    </div>
  );

  React.useEffect(() => {
    if (workspacesInit) {
      const filteredWorkspaces = workspacesInit.filter((workspace) => {
        return workspace.title
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setWorkspaces(filteredWorkspaces);
    }
  }, [searchValue, workspacesInit]);

  const GoToUpgradePlan = () => {
    window.open(upgradePlanLink, "_blank");
  };

  return (
    <div className="WorkspacesList">
      <div className="WorkspacesList--Header">
        <Pair width="full">
          <Text weight="bold" size="title">
            {replaceMode ? "Workspace Sync" : title}
          </Text>
          {replaceMode ? (
            <Stack direction="column" spacing={15}>
              <Text size="small" variant="secondary">
                <b>Pull Cloud Changes: </b> Replace your local workspace with
                the latest version stored in the cloud. This action will
                overwrite your current local tabs with those from the cloud
                workspace.
              </Text>
              <Text size="small" variant="secondary">
                <b>Push Local Changes: </b> Replace your cloud workspace with
                the current workspace on this window. This action will overwrite
                the existing cloud workspace with your local tabs.
              </Text>
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="space-between" width="full">
              <Text size="small" variant="secondary">
                {workspaces?.length} workspaces found
              </Text>
              {showLimitReached && (
                <Pair direction="row" align="center" justify="flex-start">
                  <Text size="small" variant="secondary">
                    You've reached the limit of workspaces available in your
                    plan.
                  </Text>
                  <Button
                    onClick={GoToUpgradePlan}
                    size="small"
                    variant="outlined"
                    label="Upgrade Plan"
                  />
                </Pair>
              )}
            </Stack>
          )}
        </Pair>
        {tooltip !== null ? (
          <div className="WorkspacesList--Header--Icon">
            <TooltipIcon text={tooltip} />
          </div>
        ) : null}
      </div>
      <div className="WorkspacesList--ActionBar">
        <WorkspacesTopBar
          onSearch={(value) => {
            setSearchValue(value);
          }}
        />
      </div>
      {workspaces ? (
        <div className="WorkspacesList--List">
          {workspaces.length > 0 ? (
            <List gap={30}>
              {workspaces.map((tabList, index) => {
                if (replaceMode && tabList.active) {
                  return null;
                }

                const { tabsCount, groupsCount } = getTabsAndGroupsCount(
                  tabList.tabsOrGroups,
                );
                return (
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => {
                      if (draggingSelection) {
                        addTabToWorkspace(
                          tabList.id,
                          Object.values(selectedTabs),
                        );
                        return;
                      }
                      const tab = handleDrop(e);
                      if (tab) {
                        addTabToWorkspace(tabList.id, [tab]);
                      }
                    }}
                    key={tabList.id}
                  >
                    <TabList
                      key={tabList.id}
                      title={tabList.title}
                      lastUsedDate={tabList.lastUsedDate}
                      tabsCount={tabsCount}
                      groupsCount={groupsCount}
                      workspaceId={tabList.id}
                      enableTitleEditing={enableTitleEditing}
                      openWorkspaceInNewWindow={openWorkspaceInNewWindow}
                      addWorkspaceToCurrentWindow={addWorkspaceToCurrentWindow}
                      replaceCurrentWindowWithWorkspace={
                        replaceCurrentWindowWithWorkspace
                      }
                      deleteWorkspace={deleteWorkspace}
                      active={tabList?.active || false}
                      replaceMode={replaceMode}
                      activeDropZone={isDragging}
                      pullCloudChanges={pullCloudChanges}
                      pushLocalChanges={pushLocalChanges}
                      onSaveTitle={onSaveTitle}
                      locked={tabList.locked}
                      upgradeLink={upgradePlanLink}
                    >
                      <TabComponent
                        tabsOrGroups={tabList.tabsOrGroups}
                        locked={tabList.locked}
                        onClickTab={(url, id, chromeId) => {
                          if (tabList?.active || false) {
                            focusTab(chromeId ?? "");
                            return;
                          }
                          onClickTab(url, id);
                        }}
                        deleteTab={(tabId, chromeId) => {
                          if (tabList?.active || false) {
                            deleteAndCloseTab(tabId, chromeId ?? "");
                            return;
                          }
                          deleteTab(tabId);
                        }}
                        onDeleteTabGroup={(groupId, chromeId) => {
                          if (tabList?.active || false) {
                            deleteAndCloseTabGroup(chromeId ?? "");
                            return;
                          }
                          onDeleteTabGroup(groupId);
                        }}
                        onUnGroup={(groupId, chromeId) => {
                          if (tabList?.active || false) {
                            ungroupOpenTabGroup(chromeId ?? "");
                            return;
                          }
                          onUnGroup(groupId);
                        }}
                        onOpenTabGroup={(groupId, chromeId) => {
                          if (tabList?.active || false) {
                            focusTabGroup(chromeId ?? "");
                            return;
                          }
                          onOpenTabGroup(groupId);
                        }}
                      />
                    </TabList>
                  </div>
                );
              })}
            </List>
          ) : (
            noWorkspaces
          )}
        </div>
      ) : (
        noWorkspaces
      )}
    </div>
  );
}
