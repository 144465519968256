import "./style.scss";
import Pair from "../../Layout/Pair/Pair";
import TextPair from "../TextPair/TextPair";
import IconButton from "../../Inputs/IconButton/IconButton";
import ProfileImage from "../ProfileImage/ProfileImage";
import { truncateString } from "../../../utils/truncateString";

interface Props {
  username: string;
  email: string;
  profileImageURL?: string;
  onSignOut: () => void;
  simple?: boolean;
  showSignOut?: boolean;
}

export default function ProfileDisplay({
  username,
  email,
  profileImageURL,
  onSignOut,
  simple = false,
  showSignOut = true,
}: Props) {
  return (
    <div
      className={`ProfileDisplayContainer ${
        simple ? "ProfileDisplayContainerSimple" : ""
      }`}
    >
      <Pair direction="row" align="center" spacing={10}>
        <ProfileImage username={username} size={48} />
        {simple ? null : (
          <TextPair
            header={truncateString(username, 18)}
            subheader={truncateString(email, 18)}
            size="h8"
            variant="primary"
            variant_2="secondary"
            spacing={2}
          />
        )}
      </Pair>
      {simple || !showSignOut ? null : (
        <IconButton icon="log-out" variant="secondary" onClick={onSignOut} />
      )}
    </div>
  );
}
