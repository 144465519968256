import React, { useEffect, useState } from "react";
import "./style.scss";
import InstallationStepsSidebar from "../InstallationStepsSidebar/InstallationStepsSidebar";
import Logo from "../Logo/Logo";
import Text from "../Text/Text";
import Button from "../../Inputs/Button/Button";
import { ArrowLeft, ArrowRight, Check, CornerRightUp } from "react-feather";
import SignInUpForms from "../../Inputs/SignInUpForms/SignInUpForms";
import LearnTabDockSlider from "../LearnTabDockSlider/LearnTabDockSlider";
import Modal from "../../Layout/Modal/Modal";
import Stack from "../../Layout/Stack/Stack";
import CopyButton from "../../utils/CopyButton/CopyButton";

interface Props {
  isUserAuthenticated: boolean;
  onSignIn: (email: string, password: string) => void;
  onSignInWithGoogle: () => void;
  onSignInWithGithub: () => void;
  onSignUp: (email: string, password: string, username: string) => void;
  onSignUpWithGoogle: () => void;
  onSignUpWithGithub: () => void;
  showVerifyEmailMessage?: boolean;
  error?: string | null;
  providerError?: string | null;
}

declare global {
  interface Window {
    Tally?: {
      loadEmbeds: () => void;
    };
  }
}

export default function InstallationProcess({
  isUserAuthenticated,
  onSignIn,
  onSignInWithGoogle,
  onSignInWithGithub,
  onSignUp,
  onSignUpWithGoogle,
  onSignUpWithGithub,
  showVerifyEmailMessage = false,
  error = null,
  providerError = null,
}: Props) {
  const [currentStep, setCurrentStep] = useState(1);
  const [showKbarInstructions, setShowKbarInstructions] = useState(false);
  const [
    showInstructionsToDisableNewPage,
    setShowInstructionsToDisableNewPage,
  ] = useState(false);
  const [userCompletedForm, setUserCompletedForm] = useState(false);
  const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    script.onload = () => {
      if (window.Tally) {
        window.Tally.loadEmbeds();
      } else {
        document
          .querySelectorAll("iframe[data-tally-src]:not([src])")
          .forEach((el) => {
            const iframe = el as HTMLIFrameElement;
            if (iframe.dataset.tallySrc) {
              iframe.src = iframe.dataset.tallySrc;
            }
          });
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== "https://tally.so") return;

      if (event.data.includes("Tally.FormSubmitted")) {
        setUserCompletedForm(true);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const steps = [
    {
      header: (
        <>
          <div className="InstallationProcessContainer--content--header">
            <Logo type="icon" />
            <Text weight="bold" size="title">
              Welcome to TabDock! 🎉
            </Text>
            <div
              style={{
                width: "70%",
              }}
            >
              <Text size="subtitle" alignCenter>
                We’d love to get to know you better. Please take a moment to
                fill out this quick form so we can make sure TabDock fits your
                needs perfectly.
              </Text>
            </div>
          </div>
        </>
      ),
      content: (
        <div
          style={{
            width: "80%",
            height: "2300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "60px",
            marginTop: "20px",
          }}
        >
          <iframe
            data-tally-src="https://tally.so/embed/m6RgGk?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
            loading="lazy"
            width="100%"
            height="100%"
            frameBorder={0}
            title="Welcome to TabDock! 🎉"
          ></iframe>
        </div>
      ),
    },
    {
      header: (
        <>
          <div className="InstallationProcessContainer--content--header">
            <Logo type="icon" />
            <Text weight="bold" size="title">
              Pin TabDock for quick access
            </Text>
            <Text size="subtitle">
              You can click in the extension to open the main dashboard
            </Text>
          </div>
          <div className="InstallationProcessCornerRightUp">
            <CornerRightUp size={100} strokeWidth={0.5} color="#3391E8" />
          </div>
        </>
      ),
      content: (
        <div className="InstallationProcessContainer--content--content">
          <img
            src={"https://i.imgur.com/NOZypZk.png"}
            alt="Pin TabDock"
            width="70%"
          />
        </div>
      ),
    },
    {
      header: (
        <div className="InstallationProcessContainer--content--header">
          <Logo type="icon" />
          <Text weight="bold" size="title">
            Learn the essentials
          </Text>
          <Text size="subtitle">
            How to store workspaces, handle tabs and use the command line
          </Text>
        </div>
      ),
      content: (
        <div className="InstallationProcessContainer--content--content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginTop: "5px",
            }}
          >
            <Button
              label="Watch Workspaces Tutorial"
              color="primary"
              variant="outlined"
              onClick={() => {
                window.open("https://youtu.be/-snUOg6MuH8", "_blank");
              }}
            />
            <Button
              label="Watch Commands Tutorial"
              color="primary"
              variant="outlined"
              onClick={() => {
                window.open("https://youtu.be/5soVkIQ-l6s", "_blank");
              }}
            />
          </div>
          <LearnTabDockSlider />
        </div>
      ),
    },
    {
      header: (
        <div className="InstallationProcessContainer--content--header">
          <Logo type="icon" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            <Text weight="bold" size="title">
              Press
            </Text>
            <div
              style={{
                backgroundColor: "#2983D4",
                padding: "8px",
                borderRadius: "8px",
              }}
            >
              <Text weight="bold" size="title" variant="white">
                {isMac ? "⌘" : "Ctrl"} + K
              </Text>
            </div>

            <Text weight="bold" size="title">
              to open kbar
            </Text>
          </div>

          <Text size="subtitle">
            The kbar will allow you to have quick access to Tabdock actions and
            commands.
          </Text>
        </div>
      ),
      content: (
        <div className="InstallationProcessContainer--content--content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginTop: "5px",
            }}
          >
            <Button
              label="Watch KBar Tutorial"
              color="primary"
              variant="outlined"
              onClick={() => {
                window.open("https://youtu.be/doL3eP0PSBk", "_blank");
              }}
            />
            <Button
              label="Help, KBar is NOT opening"
              color="tertiary"
              onClick={() => {
                setShowKbarInstructions(true);
              }}
            />
          </div>
          <img src={"https://i.imgur.com/DVoNBgu.png"} alt="Kbar" width="60%" />

          <Modal
            open={showKbarInstructions}
            onClose={() => {
              setShowKbarInstructions(false);
            }}
            header={
              <Text weight="bold" size="subtitle">
                KBar is not opening
              </Text>
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              <Text size="subtitle">
                If you are not able to open the kbar with the command, follow
                the instructions below:
              </Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  marginLeft: "20px",
                }}
              >
                <Text weight="bold">1.</Text>
                <Text>
                  Go to "chrome://extensions/shortcuts" in your browser
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  marginLeft: "20px",
                }}
              >
                <Text weight="bold">2.</Text>
                <Text>Search for TabDock extension</Text>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  marginLeft: "20px",
                }}
              >
                <Text weight="bold">3.</Text>
                <Text>Click and configure the open TabDock Kbar shortcut:</Text>
              </div>
              <img
                src={"https://i.imgur.com/vcxTetB.png"}
                alt="configure kbar command instruction"
                width="90%"
                style={{
                  borderRadius: "8px",
                  marginTop: "10px",
                  alignSelf: "center",
                }}
              />
            </div>
          </Modal>
        </div>
      ),
    },
    {
      header: (
        <>
          <div className="InstallationProcessContainer--content--header">
            <Logo type="icon" />
            <Text weight="bold" size="title">
              Your New Tab Page
            </Text>
            <Text alignCenter>
              TabDock will replace your new tab page with a beautiful and useful
              dashboard where you can see your current workspaces, tabs, and
              more.
            </Text>
          </div>
        </>
      ),
      content: (
        <div className="InstallationProcessContainer--content--content">
          <img
            src={"https://i.imgur.com/GLnEHsQ.png"}
            alt="New Tab Page"
            width="70%"
            style={{ marginTop: "180px" }}
          />
          <div
            style={{
              width: "70%",
              height: "2300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "100px",
              marginTop: "5px",
            }}
          >
            <Stack width="full" spacing={15}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                  width: "100%",
                  position: "relative",
                  zIndex: 300,
                }}
              >
                <Button
                  label="Disable New Tab Page"
                  color="tertiary"
                  onClick={() => {
                    setShowInstructionsToDisableNewPage(true);
                  }}
                />
              </div>
              <Text size="subtitle">
                This new tab page is <b>optional</b> but recommended. You can
                always disable the new tab page following the instructions in
                the settings page or by clicking on the button to see the
                instructions.
              </Text>
              <Text>
                <strong>Note:</strong> In case you dissable the new tab page,
                you can always access TabDock by clicking on the extension icon
                to have access to all the features.
              </Text>

              <Modal
                open={showInstructionsToDisableNewPage}
                onClose={() => {
                  setShowInstructionsToDisableNewPage(false);
                }}
                header={
                  <Text weight="bold" size="subtitle">
                    Disable New Tab Page
                  </Text>
                }
                height="600px"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "12px",
                  }}
                >
                  <Text size="subtitle">
                    To disable the new tab page, follow the instructions below:
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "12px",
                      marginLeft: "20px",
                    }}
                  >
                    <Text weight="bold">1.</Text>
                    <Text>
                      Uninstall the current version of Tabdock extension, you
                      can do this from: chrome://extensions/
                    </Text>
                    <CopyButton copyText="chrome://extensions/" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "12px",
                      marginLeft: "20px",
                    }}
                  >
                    <Text weight="bold">2.</Text>
                    <Text>
                      In chrome://extensions/ search for Tabdock and click on
                      "Remove"
                    </Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "12px",
                      marginLeft: "20px",
                    }}
                  >
                    <Text weight="bold">3.</Text>
                    <Text>
                      Install Tabdock version with no new tab page, click the
                      button to copy the link, and paste it in your browser.
                    </Text>
                    <CopyButton copyText="https://chromewebstore.google.com/detail/tabdock-the-ultimate-tab/ejdpkjfgjdcghnleffmbabomlpdmiplo" />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "12px",
                      marginLeft: "20px",
                    }}
                  >
                    <Text weight="bold">4.</Text>
                    <Text>
                      It is possible that you need to configure the kbar command
                      again, you can do this from: chrome://extensions/shortcuts
                    </Text>
                    <CopyButton copyText="chrome://extensions/shortcuts" />
                  </div>
                  <img
                    src={"https://i.imgur.com/eWvy3mO.png"}
                    alt="disable new tab page instruction"
                    width="90%"
                    style={{
                      borderRadius: "8px",
                      marginTop: "10px",
                      alignSelf: "center",
                    }}
                  />
                </div>
              </Modal>
            </Stack>
          </div>
        </div>
      ),
    },
    {
      header: null,
      content: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
            marginTop: "30px",
          }}
        >
          {isUserAuthenticated ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <Logo type="icon" />
              <Text weight="bold" size="title">
                Welcome to TabDock
              </Text>
              <div
                style={{
                  backgroundColor: "#54B571",
                  padding: "30px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <Check color="white" size={48} strokeWidth={3} />
              </div>
              <Text size="subtitle">
                You are already authenticated and ready to use TabDock
              </Text>
            </div>
          ) : (
            <SignInUpForms
              initialForm="sign-up"
              secondary={true}
              onSignIn={onSignIn}
              onForgotPassword={() => {}}
              onSignInWithGoogle={onSignInWithGoogle}
              onSignInWithGithub={onSignInWithGithub}
              onSignUp={onSignUp}
              onSignUpWithGoogle={onSignUpWithGoogle}
              onSignUpWithGithub={onSignUpWithGithub}
              onGoToSignIn={() => {}}
              onGoToSignUp={() => {}}
              showVerifyEmailMessage={showVerifyEmailMessage}
              error={error}
              providerError={providerError}
            />
          )}
        </div>
      ),
    },
  ];

  const openTabDock = () => {
    window.open("https://my.tabdock.app", "_blank");
  };

  return (
    <div className="InstallationProcessContainer">
      <div className="InstallationProcessContainer--sidebar">
        <InstallationStepsSidebar currentStep={currentStep} />
      </div>
      <div className="InstallationProcessContainer--content">
        {steps[currentStep - 1].header}
        {steps[currentStep - 1].content}

        <div className="InstallationProcessContainer--content--footer">
          {currentStep > 1 ? (
            <Button
              label="Back"
              size="large"
              startIcon={<ArrowLeft />}
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
            />
          ) : (
            <div></div>
          )}

          {currentStep === steps.length ? (
            <Button
              label="Done"
              size="large"
              color="success"
              endIcon={<Check />}
              onClick={openTabDock}
            />
          ) : (
            <Button
              label={
                !userCompletedForm && currentStep === 1
                  ? "Complete the form before the next step"
                  : "Next"
              }
              size="large"
              disabled={!userCompletedForm && currentStep === 1}
              endIcon={<ArrowRight />}
              onClick={() => {
                setCurrentStep(currentStep + 1);
              }}
            />
          )}
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        <Text size="tiny">
          By using our Services, you agree to our{" "}
          <a
            href="/privacy"
            target="_blank"
            rel="noreferrer"
          >{`Privacy Policy`}</a>{" "}
          and{" "}
          <a href="/terms" target="_blank" rel="noreferrer">
            Terms and Conditions
          </a>
          .
        </Text>
      </div>
    </div>
  );
}
