import "./style.scss";
import { ChevronDown } from "react-feather";

interface Props {
  onClick: () => void;
  expanded: boolean;
}

export default function ExpandButton({ onClick, expanded }: Props) {
  return (
    <div className="ExpandButtonContainer" onClick={onClick}>
      <ChevronDown
        className={`ExpandButtonIcon ${
          expanded ? "ExpandButtonIcon--Down" : "ExpandButtonIcon--Right"
        }`}
        size={24}
      />
    </div>
  );
}
