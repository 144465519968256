import React from "react";

interface Props {
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  padding?: number;
  [key: string]: any;
}

export default function Section({
  alignItems = "flex-start",
  justifyContent = "flex-start",
  padding = 16,
  ...props
}: Props): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems,
        justifyContent,
        padding: `${padding}px`,
        width: "100%",
        height: "100%",
      }}
    >
      {props.children}
    </div>
  );
}
