import React from "react";
import "./style.scss";
import {
  BriefcaseIcon,
  ClockIcon,
  BookmarkIcon,
  SlidersIcon,
  TerminalIcon,
  RequestFeatureIcon,
} from "../../Icons/Icons";

type IconType =
  | "briefcase"
  | "clock"
  | "bookmark"
  | "settings"
  | "commands"
  | "feedback";

interface Props {
  onClick: () => void;
  active: boolean;
  label: string;
  icon: IconType;
  hideLabel?: boolean;
}

export default function SidebarNavButton({
  onClick,
  active,
  label,
  icon,
  hideLabel = false,
}: Props) {
  const Icon = IconMap.get(icon);
  return (
    <button
      id={`sidebar-nav-button-${label}`}
      className={`sidebar-nav-button ${
        active ? "sidebar-nav-button--active" : ""
      } ${
        hideLabel
          ? "sidebar-nav-button-collapsed"
          : "sidebar-nav-button-expanded"
      }`}
      onClick={onClick}
    >
      <Icon className={`icon ${active ? "icon--active" : ""}`} />
      {!hideLabel ? (
        <span className={`label ${active ? "label--active" : ""}`}>
          {label}
        </span>
      ) : null}
    </button>
  );
}

const IconMap = new Map<IconType, any>([
  ["briefcase", BriefcaseIcon],
  ["clock", ClockIcon],
  ["bookmark", BookmarkIcon],
  ["settings", SlidersIcon],
  ["commands", TerminalIcon],
  ["feedback", RequestFeatureIcon],
]);
