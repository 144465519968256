import React from "react";
import "./style.scss";

interface Props {
  label: string;
  color:
    | "blue"
    | "grey"
    | "red"
    | "yellow"
    | "green"
    | "pink"
    | "purple"
    | "cyan"
    | "orange";
}

export default function TabGroupLabel({ label, color }: Props) {
  return (
    <div
      className={`tabgroup tabgroup--${color} ${
        label === "" ? "tabgroup--circle" : null
      }`}
    >
      {label}
    </div>
  );
}
