import toast from "react-hot-toast";
import { ToastNotification } from "@tabdock/common";

export const createToast = (message: string, type: "success" | "error") => {
  if (type === "success") {
    toast.custom(
      (t) => (
        <ToastNotification
          message={message}
          type="success"
          visible={t.visible}
        />
      ),
      {
        position: "bottom-right",
      },
    );
  }
  if (type === "error") {
    toast.custom(
      (t) => (
        <ToastNotification message={message} type="error" visible={t.visible} />
      ),
      {
        position: "bottom-right",
      },
    );
  }
};
