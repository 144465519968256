import React from "react";
import "./style.scss";
import {
  BriefcaseFillSmallIcon,
  SyncCloudIcon,
  SaveIcon,
  LogOutFilledSmallIcon,
} from "../../Icons/Icons";
import Text from "../Text/Text";
import { truncateString } from "../../../utils/truncateString";

type IconType = "save" | "sync" | "briefcase" | "sign-out";

interface Props {
  icon: IconType;
  onClick: () => void;
  actionName: string;
  maxActionNameLength?: number;
}

export default function PopupOptionButton({
  icon,
  onClick,
  actionName,
  maxActionNameLength = 6,
}: Props) {
  const Icon = IconMap.get(icon);

  return (
    <div className="PopupOptionButton">
      <div className="PopupOptionButton--Icon" onClick={onClick}>
        <Icon />
      </div>
      <Text>{truncateString(actionName, maxActionNameLength)}</Text>
    </div>
  );
}

const IconMap = new Map<IconType, any>([
  ["save", SaveIcon],
  ["sync", SyncCloudIcon],
  ["briefcase", BriefcaseFillSmallIcon],
  ["sign-out", LogOutFilledSmallIcon],
]);
