import { supabaseClient } from "../config/supabase-client";
import { createTab } from "./tabApi";
const WORKSPACE = "workspace";

export async function createWorkspace(userId: string, title: string) {
  const currentDateTime = new Date();
  const { data, error } = await supabaseClient
    .from(WORKSPACE)
    .insert([
      {
        userId: userId,
        title,
        lastUsedDate: currentDateTime.toLocaleString("en-US"),
        creationDate: currentDateTime.toLocaleString("en-US"),
      },
    ])
    .select("*");

  if (error) throw error;
  return data;
}

export async function createWorkspaceWithTabs(
  userId: string,
  title: string,
  tabs: any[],
) {
  const currentDateTime = new Date();
  const { data, error } = await supabaseClient
    .from(WORKSPACE)
    .insert([
      {
        userId: userId,
        title,
        lastUsedDate: currentDateTime.toLocaleString("en-US"),
        creationDate: currentDateTime.toLocaleString("en-US"),
      },
    ])
    .select("*");

  if (error) throw error;
  const workspaceId = data[0].id;
  const createTabPromises = tabs.map((tab, index) => {
    return createTab(workspaceId, tab.title, tab.url, tab.faviconUrl, index);
  });

  try {
    await Promise.all(createTabPromises);
  } catch (error) {
    throw error;
  }

  return data;
}

export async function getWorkspaces(userId: string) {
  const { data, error } = await supabaseClient.rpc("get_workspaces_data");

  if (!data) return [];

  data.sort((a: any, b: any) => a.index - b.index);

  const mergedData = data.map((workspace: any) => {
    let tabGroups = workspace?.tabgroups?.map((tabgroup: any) => {
      const tabs = tabgroup?.tabs?.sort((a: any, b: any) => a.index - b.index);
      return { ...tabgroup, tabs };
    });

    tabGroups = tabGroups || [];
    const tabs = workspace?.tabs || [];

    const tabsOrGroups = [...tabs, ...tabGroups];
    tabsOrGroups.sort((a: any, b: any) => a.index - b.index);
    return {
      id: workspace.id,
      lastUsedDate: workspace.lastUsedDate,
      title: workspace.title,
      locked: workspace.locked,
      tabsOrGroups,
    };
  });

  if (error) throw error;
  return mergedData || [];
}

export async function deleteWorkspace(id: string) {
  const { data, error } = await supabaseClient
    .from(WORKSPACE)
    .delete()
    .eq("id", id);

  if (error) throw error;
  return data;
}

export async function updateWorkspace(
  id: string,
  title?: string,
  index?: number,
  lastUsedDate?: Date,
) {
  const { data, error } = await supabaseClient
    .from(WORKSPACE)
    .update({
      title,
      index,
      lastUsedDate: lastUsedDate?.toISOString(),
    })
    .eq("id", id)
    .select("*");

  if (error) throw error;
  return data;
}
