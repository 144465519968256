import React from "react";
import "./style.scss";
import Text from "../Text/Text";

type Color =
  | "blue"
  | "grey"
  | "red"
  | "yellow"
  | "green"
  | "pink"
  | "purple"
  | "cyan"
  | "orange";

export const ColorArray = [
  "blue",
  "grey",
  "red",
  "yellow",
  "green",
  "pink",
  "purple",
  "cyan",
  "orange",
];

interface Props {
  color: string;
}

export function ColorOption({ color: passColor = "blue" }: Props) {
  const color = passColor as Color;
  return (
    <div className={`ColorOptionContainer`}>
      <div className={`ColorOptionContainer--Circle ${color}`}></div>
      <Text size="small">
        {passColor.charAt(0).toUpperCase() + passColor.slice(1)}
      </Text>
    </div>
  );
}
