import React, { useState, useRef } from "react";
import "./style.scss";
import {
  TimeLineIcon,
  UngroupIcon,
  ReplaceIcon,
  BriefcaseIcon,
  ClockIcon,
  BookmarkIcon,
  SlidersIcon,
  RefreshIcon,
  DownloadIcon,
  ListIcon,
  GridIcon,
  ExternalLinkIcon,
  ShareIcon,
  MoreVerticalIcon,
  CopyIcon,
  TrashIcon,
  PlusCircleIcon,
  SearchIcon,
  FilterIcon,
  LogOutIcon,
  EditIcon,
  SaveIcon,
  SyncCloudIcon,
  RestartIcon,
  SettingsIcon,
  CheckIcon,
  HelpIcon,
  MoonSmallIcon,
  SunSmallIcon,
} from "../../Icons/Icons";
import Tooltip from "../../Display/Tooltip/Tooltip";

type IconType =
  | "briefcase"
  | "clock"
  | "bookmark"
  | "sliders"
  | "refresh"
  | "download"
  | "list"
  | "grid"
  | "timeline"
  | "external-link"
  | "share"
  | "more-vertical"
  | "copy"
  | "trash"
  | "ungroup"
  | "plus-circle"
  | "search"
  | "filter"
  | "log-out"
  | "replace"
  | "edit"
  | "save"
  | "sync-cloud"
  | "restart"
  | "settings"
  | "check"
  | "help"
  | "moon"
  | "sun";

interface Props {
  icon: IconType;
  variant?: "primary" | "secondary" | "tertiary" | "extension" | "success";
  onClick?: (e: any) => void;
  active?: boolean;
  size?: number;
  tooltip?: string;
  tooltipPosition?: "top" | "bottom" | "left" | "right";
  fill?: boolean;
}

export default function IconButton({
  icon,
  variant = "primary",
  onClick,
  active = false,
  size = 24,
  tooltip,
  tooltipPosition = "top",
  fill = false,
}: Props) {
  const Icon = IconMap.get(icon);
  const [isHovered, setIsHovered] = useState(false);
  const messageTimeoutRef = useRef<any>(null);
  const tooltipPositionOffset = ToolTipPositionMap.get(tooltipPosition);

  const handleMouseEnter = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (messageTimeoutRef.current) {
      clearTimeout(messageTimeoutRef.current);
    }

    messageTimeoutRef.current = setTimeout(() => {
      setIsHovered(true);
    }, 850);
  };

  const handleMouseLeave = () => {
    clearTimeout(messageTimeoutRef.current);
    setIsHovered(false);
  };

  return (
    <div
      id={`icon-btn-${icon}`}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: "relative",
        cursor: "pointer",
      }}
    >
      <Icon
        size={size}
        strokeWidth={2}
        className={`IconButton ${fill ? variant + "-fill" : variant} ${
          active ? "active" : ""
        }`}
      />
      {tooltip ? (
        <Tooltip
          message={tooltip}
          isVisible={isHovered}
          width="100px"
          position={tooltipPositionOffset}
        />
      ) : null}
    </div>
  );
}

const ToolTipPositionMap = new Map<string, { x: number; y: number }>([
  ["top", { x: 0, y: -30 }],
  ["bottom", { x: -40, y: 30 }],
  ["left", { x: -115, y: 0 }],
  ["right", { x: 30, y: 0 }],
]);

const IconMap = new Map<IconType, any>([
  ["briefcase", BriefcaseIcon],
  ["clock", ClockIcon],
  ["bookmark", BookmarkIcon],
  ["sliders", SlidersIcon],
  ["refresh", RefreshIcon],
  ["download", DownloadIcon],
  ["list", ListIcon],
  ["grid", GridIcon],
  ["timeline", TimeLineIcon],
  ["external-link", ExternalLinkIcon],
  ["share", ShareIcon],
  ["more-vertical", MoreVerticalIcon],
  ["copy", CopyIcon],
  ["trash", TrashIcon],
  ["ungroup", UngroupIcon],
  ["plus-circle", PlusCircleIcon],
  ["search", SearchIcon],
  ["filter", FilterIcon],
  ["log-out", LogOutIcon],
  ["replace", ReplaceIcon],
  ["edit", EditIcon],
  ["save", SaveIcon],
  ["sync-cloud", SyncCloudIcon],
  ["restart", RestartIcon],
  ["settings", SettingsIcon],
  ["check", CheckIcon],
  ["help", HelpIcon],
  ["moon", MoonSmallIcon],
  ["sun", SunSmallIcon],
]);
