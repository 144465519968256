import { PropsWithChildren } from "react";
import HorizontalDivider from "../HorizontalDivider/HorizontalDivider";
import "./style.scss";
import Logo from "../../Display/Logo/Logo";

interface Props {
  porcentage: number;
}

export default function TabdockHalfScreen({
  porcentage,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className="TabdockHalfScreenContainer">
      <HorizontalDivider splitPorcentage={porcentage}>
        <div className="TabdockHalfScreenContainer--Content">{children}</div>

        <div className="TabdockHalfScreenContainer--Blue">
          <div className="TabdockHalfScreenContainer--Blue--Image" />
          <div className="TabdockHalfScreenContainer--Blue--Icon"></div>
        </div>
      </HorizontalDivider>
    </div>
  );
}

/*
 <div className="TabdockHalfScreenContainer--Blue--Icon">
            <Logo type="full-white" />
          </div>

          <Text size="subtitle" weight="bold" variant="white">
              Tabdock
            </Text>
*/
