import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../RoutesPaths";
import { useAuth } from "../../contexts/AuthProvider";

export default function Oauth2callback() {
  const navigate = useNavigate();
  const { id } = useAuth();

  useEffect(() => {
    const handleOAuthCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code) {
        const serverUrl =
          process.env.REACT_APP_ENVIRONMENT === "development"
            ? process.env.REACT_APP_SERVER_LOCALHOST
            : process.env.REACT_APP_SERVER_URL;

        const body = {
          code: code,
          userId: id,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        try {
          const response = await fetch(`${serverUrl}/calendar/setUpUser`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          });

          await response.json();
        } catch (error) {
          console.error("Error setting up user: ", error);
        }
      } else {
        console.error("No code found in the URL");
      }
      navigate(RoutePaths.Settings);
    };
    if (window.location.pathname === "/oauth2callback") {
      handleOAuthCallback();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  return <></>;
}
