import React, { ReactNode } from "react";
import Stack from "../Stack/Stack";

interface Props {
  children: [ReactNode, ReactNode | null];
  align?: "flex-start" | "flex-end" | "center";
  justify?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | null;
  direction?: "row" | "column";
  spacing?: number;
  width?: "full" | "auto" | "fit-content";
}

const Pair = ({
  children,
  align = "flex-start",
  justify = null,
  direction = "column",
  spacing = 6,
  width = "auto",
}: Props) => {
  return (
    <Stack
      direction={direction}
      spacing={spacing}
      justifyContent={justify ? justify : align}
      alignItems={align}
      width={width}
    >
      {children}
    </Stack>
  );
};

export default Pair;
