import React from "react";
import "./style.scss";

interface Props {
  text?: string;
  secondary?: boolean;
}

export default function Line({
  text = "",
  secondary = false,
}: Props): JSX.Element {
  return <hr className={secondary ? "Line2" : "Line"} data-content={text} />;
}
