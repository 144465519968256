import { useState } from "react";
import "./style.scss";
import { Eye, EyeOff } from "react-feather";

interface Props {
  inputType?: "text" | "password" | "number" | "email";
  placeholder?: string;
  error?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  width?: "full" | "auto" | "fit-content";
  disabled?: boolean;
}

export default function TextFieldBox({
  inputType = "text",
  placeholder,
  error,
  value,
  onChange = () => {},
  width = "full",
  disabled = false,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const EyeIcon = showPassword ? EyeOff : Eye;

  return (
    <div className={`TextFieldBoxContainer ${width}`}>
      <input
        id={`text-field-box-${placeholder}`}
        className={`TextFieldBox ${error ? "TextFieldBoxError" : ""} ${width} ${
          disabled ? "TextFieldBoxDisabled" : ""
        }`}
        type={showPassword ? "text" : inputType}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={(event: any) => {
          onChange(event.target.value);
        }}
      />
      {inputType === "password" ? (
        <EyeIcon
          onClick={toggleShowPassword}
          className="TextFieldEyeIcon"
          strokeWidth={1}
        />
      ) : null}
    </div>
  );
}
