import React, { useEffect } from "react";
import "./style.scss";
import Tag from "../Tag/Tag";
import MultipleTagsIndicator from "../MultipleTagsIndicator/MultipleTagsIndicator";
import Button from "../../Inputs/Button/Button";

import { PlusCircle } from "react-feather";

type TagProps = React.ComponentProps<typeof Tag>;

interface Props {
  tags: TagProps[];
  onUpdateTags: ((tags: TagProps[]) => void) | null;
  onAddTag: (() => void) | null;
}

export default function TagList({
  tags: initialTags = [],
  onUpdateTags = () => {},
  onAddTag = () => {},
}: Props) {
  const [tags, setTags] = React.useState<TagProps[]>(initialTags);
  const MAX_TAGS = 3;

  useEffect(() => {
    if (onUpdateTags) {
      onUpdateTags(tags);
    }
  }, [onUpdateTags, tags]);

  const remainingTags = tags.slice(MAX_TAGS);

  return (
    <div className="TagListContainer">
      {tags.slice(0, MAX_TAGS).map((tag, index) => (
        <Tag key={index} text={tag.text} color={tag.color} />
      ))}

      {tags.length > MAX_TAGS ? (
        <MultipleTagsIndicator
          numberOfTags={remainingTags.length}
          color1={remainingTags[0]?.color || "transparent"}
          color2={remainingTags[1]?.color || "transparent"}
          color3={remainingTags[2]?.color || "transparent"}
        />
      ) : null}

      <Button
        label={tags.length > 0 ? "" : "Add tag"}
        startIcon={<PlusCircle />}
        variant="text"
        color="tertiary"
        onClick={onAddTag}
      />
    </div>
  );
}
