import React, { useRef, useState } from "react";
import "./style.scss";
import Text from "../Text/Text";
import { truncateString } from "../../../utils/truncateString";
import MessageCard from "../MessageCard/MessageCard";
import { wrap } from "module";

type CalendarColor =
  | "blue"
  | "green"
  | "purple"
  | "red"
  | "yellow"
  | "orange"
  | "violet"
  | "gray"
  | "pink"
  | "grass"
  | "oceanBlue";

type HourFormat = "12" | "24";

interface Props {
  width: number;
  colorCode: number;
  title: string;
  startTime: string;
  endTime: string;
  location?: string;
  id: string;
  allDay?: boolean;
  overlaped?: boolean;
  format?: HourFormat;
  htmlLink?: string;
}
export default function GoogleCalendarEvent({
  width,
  colorCode,
  title,
  startTime,
  endTime,
  location = "",
  id,
  allDay = false,
  overlaped = false,
  format = "12",
  htmlLink,
}: Props) {
  const maxLength = width <= 20 ? 4 : Math.floor(1.5 * (width / 10));
  const [isHovered, setIsHovered] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const messageTimeoutRef = useRef<any>(null);

  const getTimeString = (time: string) => {
    const timeArray = time.split(":");
    const hour = parseInt(timeArray[0]);
    const minute = parseInt(timeArray[1]);
    if (format === "12") {
      if (hour < 12) {
        return `${hour}:${minute < 10 ? "0" + minute : minute} am`;
      } else if (hour === 12) {
        return `${hour}:${minute < 10 ? "0" + minute : minute} pm`;
      } else {
        return `${hour - 12}:${minute < 10 ? "0" + minute : minute} pm`;
      }
    } else {
      return `${hour}:${minute < 10 ? "0" + minute : minute}`;
    }
  };

  const startTimeString =
    format === "12" ? getTimeString(startTime) : startTime;
  const endTimeString = format === "12" ? getTimeString(endTime) : endTime;

  const handleMouseEnter = (e: React.MouseEvent) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
    e.stopPropagation();

    if (messageTimeoutRef.current) {
      clearTimeout(messageTimeoutRef.current);
    }

    messageTimeoutRef.current = setTimeout(() => {
      setIsHovered(true);
    }, 650);
  };

  const handleMouseLeave = () => {
    clearTimeout(messageTimeoutRef.current);
    setIsHovered(false);
  };

  return (
    <div
      className={`GoogleCalendarEventContainer calendar-color-${ColorNumberToStyle.get(
        colorCode,
      )} ${allDay ? "calendar-shadow calendar-all-day" : ""} ${
        overlaped ? "calendar-shadow" : ""
      }`}
      style={{
        width: `${width}px`,
        cursor: htmlLink && htmlLink !== "" ? "pointer" : "default",
      }}
      key={id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={(e) => setCursorPosition({ x: e.clientX, y: e.clientY })}
      onClick={() => {
        if (htmlLink && htmlLink !== "") {
          window.open(htmlLink, "_blank");
        }
      }}
    >
      <div className="GoogleCalendarEventContainer--Header">
        <Text
          size={allDay ? "tiny" : "small"}
          variant="white"
          weight="semibold"
        >
          {truncateString(title, maxLength - 5)}
        </Text>
        {!allDay ? (
          <Text size="tiny" variant="white">
            {truncateString(startTimeString + " - " + endTimeString, maxLength)}
          </Text>
        ) : null}
      </div>
      <Text size="tiny" variant="white">
        {truncateString(location, maxLength - 5)}
      </Text>

      {isHovered && (
        <div
          style={{
            position: "absolute",
            top: cursorPosition.y - 90,
            left: cursorPosition.x - 400,
            zIndex: 40,
          }}
        >
          <MessageCard
            title={title}
            message={
              allDay
                ? `All day ${location}`
                : `${startTimeString} - ${endTimeString} ${
                    location ? ", " + location : ""
                  }`
            }
            position={cursorPosition}
            width={"400px"}
          />
        </div>
      )}
    </div>
  );
}

const ColorNumberToStyle = new Map<number | null | undefined, CalendarColor>([
  [1, "violet"],
  [2, "green"],
  [3, "purple"],
  [4, "pink"],
  [5, "yellow"],
  [6, "orange"],
  [7, "blue"],
  [8, "gray"],
  [9, "oceanBlue"],
  [10, "grass"],
  [11, "red"],
  [null, "blue"],
  [undefined, "blue"],
]);
