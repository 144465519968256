import React, { useState } from "react";
import "./style.scss";
import ListHeader from "../ListHeader/ListHeader";
import List from "../../Layout/List/List";
import Pair from "../../Layout/Pair/Pair";
import Text from "../Text/Text";
import { Calendar } from "react-feather";
import Stack from "../../Layout/Stack/Stack";
import IconButton from "../../Inputs/IconButton/IconButton";
import { formatDistance, parseISO } from "date-fns";
import Menu from "../Menu/Menu";
import Button from "../../Inputs/Button/Button";
import { DotArrowDown, DotArrowUp } from "../../Icons/Icons";
import { useMediaQuery } from "react-responsive";
import TooltipIcon from "../TooltipIcon/TooltipIcon";

interface Props {
  workspaceId: string;
  title: string;
  lastUsedDate: string;
  children?: React.ReactNode;
  tabsCount: number;
  groupsCount: number;
  active?: boolean;
  replaceMode?: boolean;
  activeDropZone?: boolean;
  enableTitleEditing?: boolean;
  locked?: boolean;
  upgradeLink?: string;
  openWorkspaceInNewWindow: (workspaceId: string) => void;
  addWorkspaceToCurrentWindow: (workspaceId: string) => void;
  replaceCurrentWindowWithWorkspace: (workspaceId: string) => void;
  deleteWorkspace: (workspaceId: string) => void;
  pullCloudChanges?: (workspaceId: string) => void;
  pushLocalChanges?: (workspaceId: string) => void;
  onSaveTitle?: (workspaceId: string, newTitle: string) => void;
}

export default function TabList({
  workspaceId,
  title,
  lastUsedDate: lastUsedDateString,
  children,
  tabsCount,
  groupsCount,
  active = false,
  replaceMode = false,
  activeDropZone = false,
  enableTitleEditing = false,
  locked = false,
  upgradeLink,
  openWorkspaceInNewWindow,
  addWorkspaceToCurrentWindow,
  replaceCurrentWindowWithWorkspace,
  deleteWorkspace,
  pullCloudChanges = () => {},
  pushLocalChanges = () => {},
  onSaveTitle = () => {},
}: Props) {
  const [expanded, setExpanded] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [draggingOver, setDraggingOver] = React.useState<boolean>(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 330px)" });
  const littleScreenSpace = useMediaQuery({ query: "(max-width: 550px)" });

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const RightOptionsNoActive = !replaceMode ? (
    <Pair direction="row" spacing={10} align="flex-start">
      <Stack direction="row" spacing={4} align="center">
        <IconButton
          icon="external-link"
          variant="secondary"
          onClick={() => {
            openWorkspaceInNewWindow(workspaceId);
          }}
          tooltip="Open in new window"
          tooltipPosition="bottom"
        />
        <IconButton
          icon="share"
          variant="secondary"
          onClick={() => {
            addWorkspaceToCurrentWindow(workspaceId);
          }}
          tooltip="Add to current window"
          tooltipPosition="bottom"
        />
        <IconButton
          icon="replace"
          variant="secondary"
          onClick={() => {
            replaceCurrentWindowWithWorkspace(workspaceId);
          }}
          tooltip="Replace current workspace"
          tooltipPosition="bottom"
        />
      </Stack>
      <div>
        <IconButton
          icon="more-vertical"
          variant="secondary"
          tooltip="More options"
          tooltipPosition="left"
          onClick={(e) => {
            setMenuOpen(!menuOpen);
            e.stopPropagation();
          }}
        />

        <div className="TabListContainerMenu">
          <Menu
            open={menuOpen}
            onClose={() => {
              setMenuOpen(false);
            }}
            options={[
              {
                label: "Delete",
                onClick: () => {
                  deleteWorkspace(workspaceId);
                },
                color: "error",
                icon: "delete",
              },
            ]}
          />
        </div>
      </div>
    </Pair>
  ) : (
    <Pair
      direction={littleScreenSpace ? "column" : "row"}
      spacing={10}
      align="flex-start"
    >
      <Button
        label={littleScreenSpace ? "Pull" : "Pull Cloud Changes"}
        variant="outlined"
        color="primary"
        startIcon={<DotArrowUp className="StartIcon" />}
        onClick={() => {
          pullCloudChanges(workspaceId);
        }}
        width={littleScreenSpace ? "full" : "fit-content"}
      />
      <Button
        label={littleScreenSpace ? "Push" : "Push Local Changes"}
        variant="outlined"
        color="success"
        startIcon={<DotArrowDown className="StartIcon" />}
        onClick={() => {
          pushLocalChanges(workspaceId);
        }}
        width={littleScreenSpace ? "full" : "fit-content"}
      />
    </Pair>
  );

  const RightOptionsActive = (
    <Pair direction="row" spacing={10} align="flex-start">
      <Stack direction="row" spacing={4} align="center">
        <IconButton
          icon="share"
          variant="secondary"
          tooltip="Add to current window"
          tooltipPosition="bottom"
          onClick={() => {
            addWorkspaceToCurrentWindow(workspaceId);
          }}
        />
      </Stack>
      <div>
        <IconButton
          icon="more-vertical"
          variant="secondary"
          tooltip="More options"
          tooltipPosition="left"
          onClick={(e) => {
            setMenuOpen(!menuOpen);
            e.stopPropagation();
          }}
        />
        <div className="TabListContainerMenu">
          <Menu
            open={menuOpen}
            onClose={() => {
              setMenuOpen(false);
            }}
            options={[
              {
                label: "Delete",
                onClick: () => {
                  deleteWorkspace(workspaceId);
                },
                color: "error",
                icon: "delete",
              },
            ]}
          />
        </div>
      </div>
    </Pair>
  );

  const RighOptionsLocked = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Text size="small" weight="bold" variant="tertiary">
        Locked
      </Text>
      <TooltipIcon text="This workspace is locked because you have exceeded the workspace limit for your current plan. You can not do any changes or use this workspace." />
      <Button
        label={"Upgrade to unlock"}
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => {
          window.open(upgradeLink, "_blank");
        }}
      />
    </div>
  );

  let RightOptions = active ? RightOptionsActive : RightOptionsNoActive;
  if (locked) {
    RightOptions = RighOptionsLocked;
  }

  return (
    <div className="TabListContainer">
      <ListHeader
        title={title}
        expanded={expanded}
        onExpand={toggleExpanded}
        rightOptions={isTabletOrMobile ? null : RightOptions}
        showActiveIcon={active}
        enableTitleEditing={locked ? false : enableTitleEditing}
        onSaveTitle={(newTitle) => {
          onSaveTitle(workspaceId, newTitle);
        }}
        subheader={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={10}
          >
            <Text size="small" variant="secondary">
              {tabsCount} tabs ({groupsCount} groups)
            </Text>
            <Text size="small" variant="secondary">
              |
            </Text>
            {!littleScreenSpace ? (
              <Pair direction="row" align="center">
                <Calendar size={14} className="tablistcalendaricon" />
                <Text size="small" variant="secondary">
                  Last used{" "}
                  {formatDistance(parseISO(lastUsedDateString), new Date(), {
                    addSuffix: true,
                  })}
                </Text>
              </Pair>
            ) : null}
          </Stack>
        }
      />

      <div
        className={`TabListContainer--List ${
          expanded
            ? "TabListContainer--List--expanded"
            : "TabListContainer--List--collapsed"
        }`}
      >
        <List removePadding>{children}</List>
        {activeDropZone && !locked ? (
          <div
            className={draggingOver ? "DropZoneActiveGreen" : "DropZoneActive"}
            onDragEnter={() => {
              setDraggingOver(true);
            }}
            onDragLeave={() => {
              setDraggingOver(false);
            }}
            onDrop={() => {
              setDraggingOver(false);
            }}
          >
            <p>Drop to add</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
