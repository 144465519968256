import { useNavigate } from "react-router-dom";
import "./style.scss";
import {
  SignInForm,
  TabdockHalfScreen,
  PostHogProvider,
  AnalyticsService,
  Text,
} from "@tabdock/common";
import { signInWithGithub, signInWithGoogle } from "../../api/authApi";
import { useSignInWithEmailAndPassword } from "../../hooks/auth/useSignInWithEmailAndPassword";
import { RoutePaths } from "../../RoutesPaths";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

export default function SignInScreen() {
  const navigate = useNavigate();
  const signIn = useSignInWithEmailAndPassword();
  const [error, setError] = useState<string | null>(null);
  const [providerError, setProviderError] = useState<string | null>(null);

  const postHogProvider = new PostHogProvider();
  const analyticsService = new AnalyticsService(postHogProvider);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const cleanErrors = () => {
    setError(null);
    setProviderError(null);
  };

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      cleanErrors();
      analyticsService.captureEvent("user_logged_in", {
        product: "web",
        provider: "google",
      });
    } catch (error: any) {
      console.log("Error: ", error);
      setProviderError("there was an error while signing in with google");
      analyticsService.captureError("logged_in_error", error, {
        product: "web",
        provider: "google",
      });
    }
  };

  const handleSignInWithGithub = async () => {
    try {
      await signInWithGithub();
      cleanErrors();
      analyticsService.captureEvent("user_logged_in", {
        product: "web",
        provider: "github",
      });
    } catch (error: any) {
      console.log("Error: ", error);
      setProviderError("there was an error while signing in with github");
      analyticsService.captureError("logged_in_error", error, {
        product: "web",
        provider: "github",
      });
    }
  };

  const handleSignInWithEmailAndPassword = (
    email: string,
    password: string,
  ): void => {
    signIn.mutate(
      { email, password },
      {
        onSuccess: () => {
          navigate(RoutePaths.Home);
          cleanErrors();
          analyticsService.captureEvent("user_logged_in", {
            product: "web",
            provider: "email",
          });
        },
        onError: (error: any) => {
          console.log("Error on Signin: ", error);
          setError(error.message);
          analyticsService.captureError("logged_in_error", error, {
            product: "web",
            provider: "email",
          });
        },
      },
    );
  };

  if (isTabletOrMobile) {
    return (
      <div className="SignInScreenContainerPage">
        <div className="SignInScreenContainerMobile">
          <SignInForm
            onSignIn={handleSignInWithEmailAndPassword}
            onForgotPassword={() => {}}
            onGoToSignUp={() => {
              navigate(RoutePaths.SignUp);
            }}
            onSignInWithGithub={handleSignInWithGithub}
            onSignInWithGoogle={handleSignInWithGoogle}
            error={error}
            providerError={providerError}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "50%",
            padding: "5px",
          }}
        >
          <Text size="tiny">
            By using our Services, you agree to our{" "}
            <a
              href="/privacy"
              target="_blank"
              rel="noreferrer"
            >{`Privacy Policy`}</a>{" "}
            and{" "}
            <a href="/terms" target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>
            .
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className="SignInScreenContainerPage">
      <div className="SignInScreenContainer">
        <TabdockHalfScreen porcentage={50}>
          <div className="SignInScreenContainer--Content">
            <SignInForm
              onSignIn={handleSignInWithEmailAndPassword}
              onForgotPassword={() => {}}
              onGoToSignUp={() => {
                navigate(RoutePaths.SignUp);
              }}
              onSignInWithGithub={handleSignInWithGithub}
              onSignInWithGoogle={handleSignInWithGoogle}
              error={error}
              providerError={providerError}
            />
          </div>
        </TabdockHalfScreen>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        <Text size="tiny" variant="white">
          By using our Services, you agree to our{" "}
          <a
            href="/privacy"
            target="_blank"
            rel="noreferrer"
          >{`Privacy Policy`}</a>{" "}
          and{" "}
          <a href="/terms" target="_blank" rel="noreferrer">
            Terms and Conditions
          </a>
          .
        </Text>
      </div>
    </div>
  );
}
