import React from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { Navigate } from "react-router-dom";
import { RoutePaths } from "../../RoutesPaths";
import { AnalyticsService, PostHogProvider } from "@tabdock/common";

interface Props {
  children: JSX.Element;
}

export default function UserRedirect({ children }: Props) {
  const { user } = useAuth();
  const userForcedSignOut = localStorage.getItem("forceSignOut");

  const postHogProvider = new PostHogProvider();
  const analyticsService = new AnalyticsService(postHogProvider);

  if (user && userForcedSignOut !== "true") {
    analyticsService.identifyUser(user.id, { email: user.email });
    return <Navigate to={RoutePaths.Home} />;
  }
  return <>{children}</>;
}
