import {
  PageContainer,
  Text,
  Pair,
  Stack,
  Logo,
  Button,
} from "@tabdock/common";
import withCrisp from "../../HOCs/withCrisp";

function HelpScreen() {
  const HOST =
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? process.env.REACT_APP_LOCALHOST
      : process.env.REACT_APP_HOST;

  const openTabdock = () => {
    window.open(`${HOST}`, "_blank");
  };

  return (
    <PageContainer>
      <div className="PrivacyPolicyContainerScroll">
        <div className="PrivacyPolicyNav" onClick={openTabdock}>
          <Logo type="horizontal" />
        </div>
        <div className="PrivacyPolicyContainer">
          <Pair>
            <Text size="title" weight="bold">
              TabDock Help Page
            </Text>
            <Text>
              Welcome to the TabDock Help Page! Here you'll find comprehensive
              guides and instructions to help you make the most of TabDock.
            </Text>
          </Pair>

          <Stack spacing={10}>
            <Text size="subtitle" weight="bold">
              Getting Started
            </Text>
            <Text>
              <b>Installation</b>
              <ul>
                <li>
                  <Text>
                    <b>Download TabDock extension</b>: Visit the Chrome Web
                    Store to download the TabDock extension.
                  </Text>
                </li>
                <li>
                  <Text>
                    <b>Create or log in to an account</b>: You can create a new
                    account using your Google or GitHub account.
                  </Text>
                </li>
                <li>
                  <Text>
                    <b>Launch TabDock</b>: Open the extension and log in to
                    start using Tabdock.
                  </Text>
                </li>
              </ul>
            </Text>
          </Stack>

          <Stack spacing={10}>
            <Text size="title" weight="bold">
              Features
            </Text>
            <Text size="subtitle" weight="bold">
              Creating and Managing Workspaces
            </Text>
            <Text>
              Each new Chrome window is considered a "Workspace." You can decide
              whether to save it by assigning a name to your workspace.
            </Text>

            <Button
              label="Watch Workspaces Tutorial"
              onClick={() => {
                window.open("https://youtu.be/-snUOg6MuH8", "_blank");
              }}
            />

            <Text>
              <b>Features of Workspaces:</b>
            </Text>
            <ul>
              <li>
                <Text>
                  <b>Re-open saved workspaces</b>: Quickly access your saved
                  environments.
                </Text>
              </li>
              <li>
                <Text>
                  <b>Rename workspaces</b>: Personalize your workspace names.
                </Text>
              </li>
              <li>
                <Text>
                  <b>Save workspace state</b>: Preserve the state of your tabs,
                  including groups and order.
                </Text>
              </li>
              <li>
                <Text>
                  <b>Auto-save</b>: Automatically save your workspace.
                </Text>
              </li>
            </ul>

            <Text>
              <b>Managing Workspaces:</b>
            </Text>
            <ol>
              <li>
                <Text>
                  <b>Open an empty tab</b>: See current workspace details.
                </Text>
              </li>
              <li>
                <Text>
                  <b>Open a new workspace</b>: Click the briefcase icon.
                </Text>
              </li>
              <li>
                <Text>
                  <b>Change theme</b>: Click the sun/moon icon.
                </Text>
              </li>
              <li>
                <Text>
                  <b>Rename workspace</b>: Click the top left corner, edit the
                  title, and click save.
                </Text>
              </li>
              <li>
                <Text>
                  <b>Sync Google Calendar</b>: View your day's events in the
                  workspace.
                </Text>
              </li>
            </ol>

            <Text>
              If you save a workspace, it will be stored in the cloud, meaning
              you can open this workspace from any other computer at any time
              once you log in to your account.
            </Text>

            <Text>Other options to open a workspace are:</Text>
            <ul>
              <li>
                <Text>Open the workspace in a new window.</Text>
              </li>
              <li>
                <Text>Add the workspace to the current window.</Text>
              </li>
              <li>
                <Text>Replace the current window with a workspace.</Text>
              </li>
            </ul>

            <Text>
              Every time you log in to the extension, you will be prompted with
              a notification saying that you have a session stored and will be
              asked if you want to restore it:
            </Text>
            <ul>
              <li>
                <Text>
                  Yes: All your previous workspaces will be opened again.
                </Text>
              </li>
              <li>
                <Text>
                  No: Your session will be cleared from cache, and you can start
                  a new session.
                </Text>
              </li>
            </ul>
          </Stack>

          <Stack spacing={10}>
            <Text size="subtitle" weight="bold">
              Commands
            </Text>
            <Button
              label="Watch Commands Tutorial"
              onClick={() => {
                window.open("https://youtu.be/5soVkIQ-l6s", "_blank");
              }}
            />
            <Text>
              <b>Use of Commands</b>
            </Text>

            <Text>
              To activate the commands in the search bar, you need to:
            </Text>
            <ol>
              <li>
                <Text>Click on the Google search bar.</Text>
              </li>
              <li>
                <Text>
                  Type "go" and then press Tab or Space. This will activate
                  TabDock's search extension.
                </Text>
              </li>
              <li>
                <Text>
                  You can now execute any commands available in your command{" "}
                  <a
                    href="my.tabdock.app/commands"
                    target="_blank"
                    rel="noreferrer"
                  >
                    dashboard
                  </a>
                  .
                </Text>
              </li>
            </ol>

            <Text>
              <b>Create a New Command</b>
            </Text>
            <Text>To create a new command:</Text>
            <ol>
              <li>
                <Text>
                  Access your command{" "}
                  <a
                    href="my.tabdock.app/commands"
                    target="_blank"
                    rel="noreferrer"
                  >
                    dashboard
                  </a>
                  .
                </Text>
              </li>
              <li>
                <Text>Click on the "New+" button.</Text>
              </li>
              <li>
                <Text>Complete the fields:</Text>
              </li>
              <ul>
                <li>
                  <Text>
                    <b>Command</b>: This is the trigger that will launch this
                    specific command. It must be unique among all your other
                    commands (e.g., g → to search on Google or yt → to search on
                    YouTube).
                  </Text>
                </li>
                <li>
                  <Text>
                    <b>URL</b>: This is the URL of the site that will open when
                    you execute your command.
                  </Text>
                </li>
                <li>
                  <Text>
                    <b>Description</b>: A brief explanation of what your command
                    does.
                  </Text>
                </li>
                <li>
                  <Text>
                    <b>Term</b>: You can decide to include a term in your
                    command or not by activating it.
                  </Text>
                </li>
                <ul>
                  <li>
                    <Text>
                      If you include a term, add a name to it (e.g., term,
                      search, username, etc.).
                    </Text>
                  </li>
                  <li>
                    <Text>
                      This term name must be in the URL provided, surrounded by
                      &#60;&#62;, example:
                      https://www.google.com/search?q=&#60;term&#62;.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      When you execute a command with a term, you need to write
                      the command trigger, add a space, and then the term.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      This will replace the &#60;term&#62; in the URL with the
                      value you provided.
                    </Text>
                  </li>
                </ul>
              </ul>
            </ol>

            <Text>
              <b>Example:</b>
            </Text>
            <ol>
              <li>
                <Text>
                  <b>Command</b>: g
                </Text>
              </li>
              <li>
                <Text>
                  <b>URL</b>:https://www.google.com/search?q=&#60;term&#62;
                </Text>
              </li>
              <li>
                <Text>
                  <b>Description</b>: Search term on Google.
                </Text>
              </li>
              <li>
                <Text>
                  <b>Term name</b>: term
                </Text>
              </li>
            </ol>

            <Text>
              <b>Execute:</b>
            </Text>
            <ol>
              <li>
                <Text>
                  Type "go" in the Google search bar to activate the TabDock
                  extension.
                </Text>
              </li>
              <li>
                <Text>Type "g" and add a space.</Text>
              </li>
              <li>
                <Text>
                  Type what you want to search on Google, e.g., "cute dogs".
                </Text>
              </li>
              <li>
                <Text>Result: "g cute dogs"</Text>
              </li>
              <li>
                <Text>
                  This will generate the URL
                  https://www.google.com/search?q=cute dogs that will be opened
                  in a new tab.
                </Text>
              </li>
            </ol>

            <Text>
              <b>Edition and deletion of commands</b>
            </Text>
            <Text>
              You can edit or delete any command from your command dashboard by
              clicking the edit or delete button.
            </Text>
          </Stack>

          <Stack spacing={10}>
            <Text size="subtitle" weight="bold">
              Kbar (Quick Command Bar)
            </Text>
            <Button
              label="Watch KBar Tutorial"
              onClick={() => {
                window.open("https://youtu.be/doL3eP0PSBk", "_blank");
              }}
            />
            <Text>
              The Kbar allows you to navigate faster through your Chrome browser
              while interacting with TabDock. You can open the Kbar by clicking:
            </Text>
            <ul>
              <li>
                <Text>Windows: Ctrl + K</Text>
              </li>
              <li>
                <Text>Mac: ⌘ + K</Text>
              </li>
            </ul>

            <Text>
              You can change the activation shortcut from
              chrome://extensions/shortcuts.
            </Text>
            <Text>
              The Kbar provides access to all workspace actions and more, such
              as:
            </Text>
            <ul>
              <li>
                <Text>Opening a new window.</Text>
              </li>
              <li>
                <Text>Searching for a tab.</Text>
              </li>
              <li>
                <Text>Accessing and executing your commands.</Text>
              </li>
              <li>
                <Text>Opening workspaces.</Text>
              </li>
              <li>
                <Text>And more.</Text>
              </li>
            </ul>

            <Text>
              The Kbar is an easy and fast way to navigate and use all the
              features that TabDock provides.
            </Text>
          </Stack>

          <Stack spacing={10}>
            <Text size="subtitle" weight="bold">
              Google Calendar Integration
            </Text>

            <Text>
              You can integrate your Google Calendar with TabDock from your
              settings dashboard. This will allow you to see all your day's
              events on every new page you open.
            </Text>
            <Text>
              The events of your calendar are not stored in our system; we only
              fetch and display them for you in the browser.
            </Text>
            <Text>
              From the settings dashboard, you can select which calendar you
              want to display.
            </Text>
            <Text>
              You can disconnect your calendar from TabDock at any time from the
              settings dashboard.
            </Text>

            <Text>
              <b>Integration Steps:</b>
            </Text>
            <ol>
              <li>
                <Text>
                  Go to your settings{" "}
                  <a
                    href="my.tabdock.app/settings"
                    target="_blank"
                    rel="noreferrer"
                  >
                    dashboard
                  </a>
                  .
                </Text>
              </li>
              <li>
                <Text>
                  Click on the “Connect” button in the Google integration
                  section.
                </Text>
              </li>
              <li>
                <Text>Follow the Google authentication flow.</Text>
              </li>
              <li>
                <Text>
                  Select the settings iccon next to the “Done” button to see the
                  integration settings.
                </Text>
              </li>
              <li>
                <Text>Edit your calendar preferences:</Text>
              </li>
              <ul>
                <li>
                  <Text>Hour Format: select a 2:00PM or 14:00 format</Text>
                </li>
                <li>
                  <Text>Timezone: change your timezone</Text>
                </li>
                <li>
                  <Text>
                    Calendars: Select all the calendar you want to display
                  </Text>
                </li>
              </ul>
            </ol>
          </Stack>

          <Stack spacing={10}>
            <Text size="subtitle" weight="bold">
              Online version
            </Text>

            <Text>
              TabDock has an online version that you can access at{" "}
              <a href="https://my.tabdock.app" target="_blank" rel="noreferrer">
                my.tabdock.app
              </a>
              . Here you can:
            </Text>

            <ul>
              <li>
                <Text>See and interact with all your workspaces.</Text>
              </li>
              <li>
                <Text>See, create, delete, or edit your commands.</Text>
              </li>
              <li>
                <Text>Change your settings.</Text>
              </li>
            </ul>
          </Stack>

          <Stack spacing={10}>
            <Text size="subtitle" weight="bold">
              Settings
            </Text>

            <Text>
              From your settings dashboard, which you can find at{" "}
              <a
                href="my.tabdock.app/settings"
                target="_blank"
                rel="noreferrer"
              >
                my.tabdock.app/settingsp
              </a>
              . Here you can:
            </Text>

            <ul>
              <li>
                <Text>Delete your account.</Text>
              </li>
              <li>
                <Text>Edit the background image of your extension.</Text>
              </li>
              <li>
                <Text>Integrate TabDock with your Google account.</Text>
              </li>
            </ul>
          </Stack>

          <Stack spacing={10}>
            <Text size="subtitle" weight="bold">
              Frequently Asked Questions (FAQ)
            </Text>

            <ol>
              <li>
                <Text>
                  <b>How do I sync my tabs across devices?</b>
                </Text>
              </li>
              <ul>
                <li>
                  <Text>
                    Log in to your account on your other device, and you will be
                    able to see your stored workspaces and commands.
                  </Text>
                </li>
              </ul>
              <li>
                <Text>
                  <b>Can I customize shortcuts for commands?</b>
                </Text>
              </li>
              <ul>
                <li>
                  <Text>
                    Yes, you can create customized commands from your commands
                    dashboard.
                  </Text>
                </li>
              </ul>
              <li>
                <Text>
                  <b>What should I do if I encounter a bug?</b>
                </Text>
              </li>
              <ul>
                <li>
                  <Text>Contact support at angel@tabdock.com.</Text>
                </li>
              </ul>
            </ol>
          </Stack>

          <Stack spacing={10}>
            <Text size="subtitle" weight="bold">
              Support
            </Text>

            <Text>If you need further assistance, please contact us:</Text>
            <ul>
              <li>
                <b>Email</b>: angel@tabdock.app
              </li>
            </ul>
          </Stack>

          <Text>
            Thank you for using TabDock! We hope it enhances your browsing
            experience.
          </Text>
        </div>
      </div>
    </PageContainer>
  );
}

export default withCrisp(HelpScreen);
