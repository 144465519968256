import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthProvider";
import {
  deleteUserIntegration,
  updateGoogleIntegrationConfiguration_CalendarIds,
  updateGoogleIntegrationConfiguration_HourFormat,
  updateGoogleIntegrationConfiguration_Timezone,
} from "../api/userIntegrationsApi";
import {
  AnalyticsService,
  ConfirmationModal,
  GoogleCalendarSettings,
  PostHogProvider,
} from "@tabdock/common";
import { useGetUserIntegrationToken } from "../hooks/user/useGetUserIntegrationToken";
import { useGetUserIntegrationData } from "../hooks/user/useGetUserIntegrationData";

interface Props {
  onClose: () => void;
}

const withGoogleCalendarUserSettings = <GoogleCalendarSettings extends object>(
  WrappedComponent: React.ComponentType<GoogleCalendarSettings>,
) => {
  const WithGoogleCalendarUserSettings: React.FC<
    GoogleCalendarSettings & Props
  > = (props) => {
    const { id } = useAuth();
    const { data: token, refetch: refetchIntegrationToken } =
      useGetUserIntegrationToken(id!, "google_calendar");
    const connected = !!token;
    const { data: integrationData } = useGetUserIntegrationData(
      id!,
      "google_calendar",
    );

    const [calendarIds, setCalendarIds] = useState<string[]>([]);
    const [timezone, setTimezone] = useState<string>("");
    const [format, setFormat] = useState<"12" | "24">("12");
    const [calendarOptions, setCalendarOptions] = useState<
      { id: string; name: string; primary: boolean }[]
    >([]);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    const postHogProvider = new PostHogProvider();
    const analyticsService = new AnalyticsService(postHogProvider);

    useEffect(() => {
      (async () => {
        if (!integrationData) {
          return;
        }

        const { token, configuration } = integrationData;
        const { access_token } = token;
        const { calendarIds, timezone, format: initFormat } = configuration;

        setCalendarIds(calendarIds);
        setTimezone(timezone);
        setFormat(initFormat);

        const calendarOptions = await fetchCalendarList(access_token, 0);
        setCalendarOptions(calendarOptions);
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrationData]);

    const fetchCalendarList = async (
      accessToken: string,
      count: number,
    ): Promise<any> => {
      if (count >= 3) {
        console.log("Error fetching calendars, max retries reached");
        return [];
      }

      const url =
        "https://www.googleapis.com/calendar/v3/users/me/calendarList";
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const serverUrl =
          process.env.REACT_APP_ENVIRONMENT === "development"
            ? process.env.REACT_APP_SERVER_LOCALHOST
            : process.env.REACT_APP_SERVER_URL;

        const body = {
          userId: id,
          integration: "google_calendar",
        };

        try {
          const response = await fetch(
            `${serverUrl}/calendar/reGenerateAccessToken`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            },
          );

          if (response.status !== 200) {
            console.log("Error refreshing token, cannot fetch calendars");
            return [];
          }

          const { access_token } = await response.json();
          return fetchCalendarList(access_token, count + 1);
        } catch (error) {
          console.log("Error refreshing token, cannot fetch calendars");
          analyticsService.captureError("google_calendar_fetch_error", error, {
            product: "web",
          });
          return [];
        }
      }

      const calendarList = [];
      const data = await response.json();
      for (const calendar of data.items) {
        calendarList.push({
          id: calendar.id,
          name: calendar.summary,
          primary: calendar?.primary ?? false,
        });
      }
      return calendarList;
    };

    const handleDisconnect = async () => {
      await deleteUserIntegration(id!, "google_calendar");
      await refetchIntegrationToken();
      setOpenConfirmationModal(false);
      analyticsService.captureEvent("google_calendar_disconnected", {
        product: "web",
      });
    };

    const handleConnect = async () => {
      const redirectUri = `${window.location.origin}/oauth2callback`;
      const clientId = process.env.REACT_APP_GOOGLE_CALENDAR_OAUTH_API_KEY;
      const scope = "https://www.googleapis.com/auth/calendar.readonly";

      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(
        scope,
      )}&response_type=code&prompt=consent&access_type=offline`;
      window.location.href = authUrl;

      analyticsService.captureEvent("google_calendar_connected", {
        product: "web",
      });
    };

    return (
      <>
        <ConfirmationModal
          openModal={openConfirmationModal}
          onClose={() => {
            setOpenConfirmationModal(false);
          }}
          title="Disconnect Google Calendar"
          buttonLabel="Disconnect"
          onConfirm={handleDisconnect}
          onCancel={() => {}}
          description="Are you sure you want to disconnect Google Calendar? You will not be able to access your Google Calendar events from TabDock."
        />
        <GoogleCalendarSettings
          {...props}
          initialHourFormat={format}
          initialTimezone={timezone}
          connected={connected}
          onDisconnect={() => {
            setOpenConfirmationModal(true);
          }}
          onConnect={handleConnect}
          onClose={props.onClose}
          onUpdateHourFormat={async (format) => {
            setFormat(format);
            await updateGoogleIntegrationConfiguration_HourFormat(id!, format);
          }}
          onUpdateSelectedCalendars={async (calendars) => {
            setCalendarIds(calendars);
            await updateGoogleIntegrationConfiguration_CalendarIds(
              id!,
              calendars,
            );
          }}
          onUpdateTimezone={async (timezone) => {
            setTimezone(timezone);
            await updateGoogleIntegrationConfiguration_Timezone(id!, timezone);
          }}
          calendarOptions={calendarOptions}
          initialSelectedCalendar={calendarIds}
        />
      </>
    );
  };

  return WithGoogleCalendarUserSettings;
};

export default withGoogleCalendarUserSettings;
