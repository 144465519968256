export type MessageType = "ping" | "workspace" | "auth" | "tabs";
export type PingCommands = "ping";
export type WorkspaceCommands =
  | "openWorkspaceInNewWindow"
  | "addWorkspaceToCurrentWindow"
  | "replaceCurrentWindowWithWorkspace"
  | "deleteWorkspace"
  | "saveWorkspaceTitle"
  | "getActiveWorkspaces"
  | "getAllWindows"
  | "addTabsToWorkspace"
  | "saveWorkspaceTitleUsingId";

export type TabsCommands =
  | "openUrl"
  | "deleteTab"
  | "deleteTabGroup"
  | "ungroupTabGroup"
  | "openTabGroup"
  | "focusTab"
  | "focusTabGroup"
  | "ungroupOpenTabGroup"
  | "deleteAndCloseTabGroup"
  | "deleteAndCloseTab"
  | "closeTabs";

export type AuthCommands =
  | "forceLogout"
  | "sessionStatus"
  | "setSession"
  | "logoutAuth"
  | "tokenRefreshed";

export interface Message {
  type: MessageType;
  command: PingCommands | WorkspaceCommands | AuthCommands | TabsCommands;
  data: any | null | undefined;
}

declare const chrome: any;

export const sendMessageToExtension = (
  message: Message,
  callback: (response: any) => any,
) => {
  const EXTENSION_IDS = [
    process.env.REACT_APP_CHROME_EXTENSION_ID,
    process.env.REACT_APP_CHROME_EXTENSION_ID_SECOND,
  ];

  console.log("EXTENSION_IDS", EXTENSION_IDS);

  if (!EXTENSION_IDS.some((id) => id)) {
    callback({ error: "No Extension IDs found" });
    throw new Error("No Extension IDs found");
  }

  const { type, command, data } = message;

  const pingExtension = (extensionId: string | undefined) => {
    return new Promise((resolve, reject) => {
      if (!extensionId) {
        reject("Extension ID is undefined");
        return;
      }

      chrome.runtime.sendMessage(
        extensionId,
        { type: "ping", command: "ping", data: null },
        (response: any) => {
          console.log("pingExtension response", response);
          if (
            chrome.runtime.lastError ||
            !response ||
            response.message !== "pong"
          ) {
            reject("Extension not installed or failed ping");
          } else {
            resolve(extensionId);
          }
        },
      );
    });
  };

  const sendToValidExtension = (extensionId: string) => {
    chrome.runtime.sendMessage(
      extensionId,
      { type, command, data },
      (response: any) => {
        if (chrome.runtime.lastError) {
          console.error(
            "Error sending message to extension:",
            chrome.runtime.lastError.message,
          );
          callback({ error: chrome.runtime.lastError.message });
        } else {
          callback(response);
        }
      },
    );
  };

  pingExtension(EXTENSION_IDS[0])
    .then((validExtensionId) =>
      sendToValidExtension(validExtensionId as string),
    )
    .catch(() => {
      pingExtension(EXTENSION_IDS[1])
        .then((validExtensionId) =>
          sendToValidExtension(validExtensionId as string),
        )
        .catch((error) => {
          console.error("No valid extension found:", error);
          callback({ error });
        });
    });
};
