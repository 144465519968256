import React from "react";
import "./style.scss";
import { HelpCircle } from "react-feather";
import Text from "../Text/Text";

interface Props {
  text: string;
  variant?: "primary" | "white";
}

export default function TooltipIcon({ text, variant = "primary" }: Props) {
  const [show, setShow] = React.useState(false);
  return (
    <div className="TooltipIconContainer">
      <div
        className="TooltipIconContainer--Message"
        style={show ? { visibility: "visible" } : {}}
      >
        <Text size="small" variant="secondary">
          {text}
        </Text>
      </div>
      <HelpCircle
        className={`TooltipIconContainer--Icon-${variant}`}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        size={18}
        strokeWidth={2}
      />
    </div>
  );
}
