import React, { useRef, useState } from "react";
import "./style.scss";
import Pair from "../../Layout/Pair/Pair";
import Text from "../../Display/Text/Text";

import {
  ProfileIcon,
  MoonIcon,
  SunIcon,
  PlusIcon,
  CloseIcon,
  LogOutFilledIcon,
  BriefcaseFillIcon,
} from "../../Icons/Icons";
import Tooltip from "../../Display/Tooltip/Tooltip";

type IconType =
  | "profile"
  | "dark"
  | "light"
  | "plus"
  | "close"
  | "log-out"
  | "briefcase";

interface Props {
  icon: IconType;
  onClick?: { (): void } | null;
  label?: string | null;
  tooltip?: string;
  tooltipPosition?: "top" | "bottom" | "left" | "right";
}

export default function IconSpecialButton({
  icon = "plus",
  onClick = null,
  label = null,
  tooltip,
  tooltipPosition = "top",
}: Props) {
  const Icon = genIcon(icon);
  const [isHovered, setIsHovered] = useState(false);
  const messageTimeoutRef = useRef<any>(null);
  const tooltipPositionOffset = ToolTipPositionMap.get(tooltipPosition);

  const handleMouseEnter = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (messageTimeoutRef.current) {
      clearTimeout(messageTimeoutRef.current);
    }

    messageTimeoutRef.current = setTimeout(() => {
      setIsHovered(true);
    }, 850);
  };

  const handleMouseLeave = () => {
    clearTimeout(messageTimeoutRef.current);
    setIsHovered(false);
  };

  return (
    <Pair direction="column" align="center">
      <div
        id={`icon-special-btn-${icon}`}
        className="IconSpecialButton"
        onClick={onClick ? onClick : undefined}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {icon === "log-out" ? (
          <Icon
            className="IconSpecialButtonIcon"
            style={{
              stroke: "transparent",
            }}
          />
        ) : (
          <Icon className="IconSpecialButtonIcon" />
        )}

        {tooltip ? (
          <Tooltip
            message={tooltip}
            isVisible={isHovered}
            width="100px"
            position={tooltipPositionOffset}
          />
        ) : null}
      </div>
      {label ? (
        <Text size="tiny" weight="semibold" variant="tertiary">
          {label}
        </Text>
      ) : null}
    </Pair>
  );
}

const ToolTipPositionMap = new Map<string, { x: number; y: number }>([
  ["top", { x: -30, y: -30 }],
  ["bottom", { x: -30, y: 50 }],
  ["left", { x: -115, y: 10 }],
  ["right", { x: 50, y: 10 }],
]);

const genIcon = (icon: IconType) => {
  switch (icon) {
    case "plus":
      return PlusIcon;
    case "profile":
      return ProfileIcon;
    case "dark":
      return MoonIcon;
    case "light":
      return SunIcon;
    case "log-out":
      return LogOutFilledIcon;
    case "briefcase":
      return BriefcaseFillIcon;
    case "close":
    default:
      return CloseIcon;
  }
};
