import React, { useEffect } from "react";
import { loadCrisp, unloadCrisp } from "../utils/crisp";

const withCrisp = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: P) => {
    useEffect(() => {
      loadCrisp();

      return () => {
        unloadCrisp();
      };
    }, []);

    return <Component {...props} />;
  };
};

export default withCrisp;
