import React from "react";
import "./style.scss";

import {
  HouseIcon,
  BriefcaseFillSmallIcon,
  MoonSmallIcon,
  SunSmallIcon,
  AddSmallIcon,
  SettingsSmallIcon,
  LogOutFilledSmallIcon,
} from "../../Icons/Icons";

type IconType =
  | "home"
  | "briefcase"
  | "light"
  | "dark"
  | "add"
  | "settings"
  | "log-out";

interface Props {
  onClick: () => void;
  active: boolean;
  label: string;
  icon: IconType;
  collapsed?: boolean;
}

export default function PillSidebarButton({
  onClick,
  active,
  label,
  icon,
  collapsed = false,
}: Props) {
  const Icon = IconMap.get(icon);
  return (
    <button
      id={`pill-sidebar-nav-button-${label}`}
      className={`pill-sidebar-nav-button ${
        active ? "pill-sidebar-nav-button--active" : ""
      } ${
        collapsed
          ? "pill-sidebar-nav-button-collapsed"
          : "pill-sidebar-nav-button-expanded"
      }`}
      onClick={onClick}
    >
      <Icon className={`pill-sidebar-icon`} />
      {!collapsed ? (
        <span className={`pill-sidebar-label`}>{label}</span>
      ) : null}
    </button>
  );
}

const IconMap = new Map<IconType, any>([
  ["home", HouseIcon],
  ["briefcase", BriefcaseFillSmallIcon],
  ["light", SunSmallIcon],
  ["dark", MoonSmallIcon],
  ["add", AddSmallIcon],
  ["settings", SettingsSmallIcon],
  ["log-out", LogOutFilledSmallIcon],
]);
