import React, { useEffect } from "react";
import "./style.scss";
import Confetti from "react-confetti";
import {
  BackgroundLine,
  Pair,
  ProfileImage,
  Text,
  TextField,
  IntegrationRow,
  Button,
  Stack,
  Link,
  CircularProgressbarDisplay,
  PageContainer,
  ResizableLayout,
  GoogleCalendarSettings,
  CopyButton,
  ConfirmationModal,
  PostHogProvider,
  AnalyticsService,
  Line,
  ScreenMessage,
  Section,
  Modal,
} from "@tabdock/common";
import { useAuth } from "../../contexts/AuthProvider";
import { useGetUsernameAndEmail } from "../../hooks/user/useGetUsernameAndEmail";
import { LogOut, Trash2 } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutePaths } from "../../RoutesPaths";
import withGoogleCalendarLogin from "../../HOCs/withGoogleCalendarLogin";
import withGoogleCalendarUserSettings from "../../HOCs/withGoogleCalendarUserSettings";
import {
  getUserSettings,
  updateUserSettingsImageBackground,
} from "../../api/userSettingsApi";
import { useMediaQuery } from "react-responsive";
import { useDeleteUser } from "../../hooks/user/useDeleteUser";
import { createToast } from "../../utils/toast";
import { Toaster } from "react-hot-toast";
import { useTheme } from "../../contexts/ThemeProvider";
import { useIsUserSubscribed } from "../../hooks/subscription/useIsUserSubscibed";
import { useGetWorkspacesCount } from "../../hooks/subscription/useGetWorkspacesCount";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export default function SettingsScreen() {
  const STRIPE_CUSTOMER_PORTAL_URL =
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL;

  const location = useLocation();
  const { user, signOut, forceSignOut } = useAuth();
  const { data } = useGetUsernameAndEmail(user?.id);
  const { theme } = useTheme();
  const { data: isUserSubscribed } = useIsUserSubscribed(user?.id!);
  const { data: workspacesCount } = useGetWorkspacesCount();
  const [openSettings, setOpenSettings] = React.useState<
    "Google_Calendar" | "Subscription" | null
  >(null);
  const [editImageBg, setEditImageBg] = React.useState(false);
  const [editImageBgQuery, setEditImageBgQuery] = React.useState("");
  const [prevEditImageBgQuery, setPrevEditImageBgQuery] = React.useState("");
  const [
    openConfirmationModalDeleteAccount,
    setOpenConfirmationModalDeleteAccount,
  ] = React.useState(false);
  const [
    showInstructionsToDisableNewPage,
    setShowInstructionsToDisableNewPage,
  ] = React.useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const postHogProvider = new PostHogProvider();
  const analyticsService = new AnalyticsService(postHogProvider);

  const navigate = useNavigate();

  const IntegrationRowWithGoogleCalendar =
    withGoogleCalendarLogin(IntegrationRow);

  const GoogleCalendarSettingsWithUserData = withGoogleCalendarUserSettings(
    GoogleCalendarSettings,
  );

  const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

  const handleSignOut = async () => {
    await signOut();
  };

  const deleteUserAccount = useDeleteUser({
    onSuccess: async () => {
      await forceSignOut();
      createToast("Account deleted successfully", "success");
      analyticsService.captureEvent("user_deleted", {
        product: "web",
      });
    },
    onError: (error) => {
      console.log("Error deleting user: ", error);
      createToast("Error deleting account", "error");
      analyticsService.captureError("user_deleted_error", error, {
        product: "web",
      });
    },
  });

  const handleDeleteAccount = async () => {
    try {
      await deleteUserAccount.mutateAsync();
      analyticsService.captureEvent("user_deleting", {
        product: "web",
      });
    } catch (error) {
      console.log("Error deleting account: ", error);
    }
  };

  const handleSaveImageBg = async () => {
    try {
      await updateUserSettingsImageBackground(user?.id!, editImageBgQuery);
      analyticsService.captureEvent("user_updated_image_bg", {
        product: "web",
      });
    } catch (error) {
      console.log("Error saving image background: ", error);
      analyticsService.captureError("user_updated_image_bg_error", error, {
        product: "web",
      });
    }
  };

  useEffect(() => {
    async function fetchUserSettings() {
      try {
        const userSettings = await getUserSettings(user?.id!);
        setEditImageBgQuery(userSettings.bgImageQuery);
      } catch (error) {
        console.log("Error fetching user settings: ", error);
      }
    }

    if (user?.id) {
      fetchUserSettings();
    }

    return () => {};
  }, [user?.id]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.get("openCalendarSettings") === "true") {
      setOpenSettings("Google_Calendar");
      navigate(RoutePaths.Settings);
    } else if (params.get("openSubscriptionSettings") === "true") {
      setOpenSettings("Subscription");
      analyticsService.captureEvent("open_subscription_settings", {
        product: "web",
      });
      navigate(RoutePaths.Settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, navigate]);

  if (isTabletOrMobile) {
    return (
      <div className="SettingsScreenContainer">
        <Toaster />
        <div className="SettingsScreenContainer--Header">
          <Text weight="bold" size="title">
            Settings
          </Text>
        </div>
        <div className="SettingsScreenContainer--HeaderLine">
          <BackgroundLine />
        </div>

        <div className="SettingsScreenContainer--Content">
          <div className="SettingsScreenContainer--Content--General">
            <Text size="subtitle" weight="bold">
              General
            </Text>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Pair
                direction="column"
                align="center"
                width="fit-content"
                spacing={60}
              >
                <Pair spacing={2}>
                  <Text weight="bold">Your photo</Text>
                  <Text>This will be displayed in your profile.</Text>
                </Pair>
                <ProfileImage username={data?.username} size={78} />
              </Pair>
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <Button
                  label="Log out"
                  endIcon={<LogOut />}
                  variant="outlined"
                  onClick={handleSignOut}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="SettingsScreenContainer">
      <Toaster />

      <ConfirmationModal
        openModal={openConfirmationModalDeleteAccount}
        onClose={() => setOpenConfirmationModalDeleteAccount(false)}
        onConfirm={handleDeleteAccount}
        onCancel={() => setOpenConfirmationModalDeleteAccount(false)}
        title={"Delete Account"}
        buttonLabel={"Yes, delete my account"}
        destructiveAction={true}
        description="Are you sure you want to delete your account? If you have an active subscription, it will be canceled. All your information will be deleted. This action is irreversible."
      />

      {openSettings !== null ? (
        <div className="SettingsScreenContainer--SidebarContainer">
          <ResizableLayout
            minWidth={openSettings === "Subscription" ? 600 : 780}
            maxWidth={openSettings === "Subscription" ? 700 : 900}
            onClose={() => {
              setOpenSettings(null);
            }}
          >
            <div
              className="SettingsScreenContainer--SidebarContainer--Left"
              onClick={() => {
                setOpenSettings(null);
              }}
            />

            <PageContainer>
              <>
                {openSettings === "Google_Calendar" ? (
                  <GoogleCalendarSettingsWithUserData
                    initialHourFormat="12"
                    initialTimezone="America/Mexico_City"
                    connected={false}
                    onDisconnect={() => {}}
                    onConnect={() => {}}
                    onClose={() => {
                      setOpenSettings(null);
                    }}
                    onUpdateHourFormat={() => {}}
                    onUpdateSelectedCalendars={() => {}}
                    onUpdateTimezone={() => {}}
                    calendarOptions={[]}
                    initialSelectedCalendar={[]}
                  />
                ) : null}
                {openSettings === "Subscription" ? (
                  <div className="SettingsScreenContainer--SidebarContainer--Subscription">
                    <div className="SettingsScreenContainer--SidebarContainer--Subscription--Title">
                      <Confetti
                        width={window.innerWidth}
                        height={window.innerHeight}
                        recycle={false}
                        numberOfPieces={500}
                        run={
                          isUserSubscribed && openSettings === "Subscription"
                        }
                      />
                      <Text size="title" weight="bold">
                        {isUserSubscribed
                          ? "You are Ultimate! 🚀"
                          : "Choose Your Plan"}
                      </Text>
                      <Text variant="secondary">
                        {isUserSubscribed
                          ? "You can manage your subscription here."
                          : "Enhance Your Browsing Experience and Boost Your Productivity with TabDock."}
                      </Text>
                    </div>
                    <Line />
                    {isUserSubscribed ? (
                      <Section alignItems="center">
                        <Button
                          label="Manage Your Subscription"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            window.open(STRIPE_CUSTOMER_PORTAL_URL!, "_blank");
                          }}
                        />
                      </Section>
                    ) : (
                      <stripe-pricing-table
                        pricing-table-id={
                          theme === "light"
                            ? process.env.REACT_APP_STRIPE_PRICING_TABLE_ID!
                            : process.env
                                .REACT_APP_STRIPE_PRICING_TABLE_DARK_ID!
                        }
                        publishable-key={
                          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
                        }
                        customer-email={data?.email}
                      ></stripe-pricing-table>
                    )}
                  </div>
                ) : null}
              </>
            </PageContainer>
          </ResizableLayout>
        </div>
      ) : null}

      <div className="SettingsScreenContainer--Header">
        <Text weight="bold" size="title">
          Settings
        </Text>
      </div>
      <div className="SettingsScreenContainer--HeaderLine">
        <BackgroundLine />
      </div>
      <div className="SettingsScreenContainer--Content">
        <div className="SettingsScreenContainer--Content--General">
          <Text size="subtitle" weight="bold">
            General
          </Text>
          <div className="SettingsScreenContainer--Content--General--Row">
            <Pair
              direction="row"
              align="center"
              width="fit-content"
              spacing={60}
            >
              <Pair spacing={2}>
                <Text weight="bold">Your photo</Text>
                <Text>This will be displayed in your profile.</Text>
              </Pair>
              <ProfileImage username={data?.username} size={78} />
            </Pair>
            <Pair direction="row" spacing={12}>
              <Button
                label="Log out"
                endIcon={<LogOut />}
                variant="outlined"
                color="secondary"
                onClick={handleSignOut}
              />
              {null}
            </Pair>
          </div>
        </div>
        <div className="SettingsScreenContainer--Content--Line">
          <BackgroundLine />
        </div>
        <div className="SettingsScreenContainer--Content--Details">
          <TextField
            title="Email"
            placeholder="Email"
            direction="row"
            spacing={50}
            value={data?.email || ""}
            disabled
          />
          <TextField
            title="Username"
            placeholder="username"
            direction="row"
            spacing={10}
            value={data?.username || ""}
            disabled
          />
        </div>

        <div className="SettingsScreenContainer--Content--Subscription">
          <div className="SettingsScreenContainer--Content--Line">
            <BackgroundLine />
          </div>
          <Pair spacing={15}>
            <Text weight="bold" size="subtitle">
              Subscription
            </Text>
            <div className="SettingsScreenContainer--Content--Subscription--Row">
              <Pair
                direction="row"
                spacing={10}
                align="flex-start"
                justify="space-between"
              >
                <Pair>
                  <Stack direction="column" spacing={20}>
                    <Text>You are currently on the plan:</Text>
                    <Text weight="semibold" size="subtitle" variant="primary">
                      {isUserSubscribed ? "Ultimate" : "Basic (Free)"}
                    </Text>
                    <Button
                      label={
                        isUserSubscribed
                          ? "Manage Your Subscription"
                          : "Change subscription plan"
                      }
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={() => {
                        if (isUserSubscribed) {
                          window.open(STRIPE_CUSTOMER_PORTAL_URL!, "_blank");
                          return;
                        }
                        setOpenSettings("Subscription");
                        analyticsService.captureEvent(
                          "open_subscription_settings",
                          {
                            product: "web",
                          },
                        );
                      }}
                    />
                  </Stack>
                  {isUserSubscribed ? (
                    <Text size="tiny" variant="secondary">
                      You have access to all the features. Enjoy! 🚀
                    </Text>
                  ) : (
                    <Text size="tiny" variant="secondary">
                      You can upgrade to a premium subscription to unlock all
                      the features.
                    </Text>
                  )}
                </Pair>
                {isUserSubscribed ? null : (
                  <CircularProgressbarDisplay
                    title="Workspaces"
                    max={5}
                    value={workspacesCount || 0}
                  />
                )}
              </Pair>
              {!isUserSubscribed ? (
                <ScreenMessage
                  title="Your account is limited to the Basic plan."
                  message="When you exceeds the workspace limit, you won't be able to create more workspaces. Upgrade to a premium plan to unlock all the features."
                  removable={false}
                  onRemove={() => {}}
                  type="onScreen"
                  optionsComponent={
                    <Button
                      label="Upgrade Plan"
                      variant="outlined"
                      color="secondary"
                      size="medium"
                      onClick={() => {
                        setOpenSettings("Subscription");
                        analyticsService.captureEvent(
                          "open_subscription_settings",
                          {
                            product: "web",
                          },
                        );
                      }}
                    />
                  }
                />
              ) : null}
            </div>
          </Pair>
        </div>
        <div className="SettingsScreenContainer--Content--Line">
          <BackgroundLine />
        </div>
        <div className="SettingsScreenContainer--Content--Appearance">
          <Text weight="bold" size="subtitle">
            Appearance
          </Text>
          <Pair direction="row" spacing={50} align="flex-end" width="full">
            <TextField
              title="Extension Background Image"
              placeholder=""
              direction="column"
              spacing={10}
              value={editImageBgQuery}
              disabled={!editImageBg}
              onChange={(value) => setEditImageBgQuery(value)}
            />
            <Pair direction="row" align="center" spacing={5}>
              <Button
                label={editImageBg ? "Save" : "Edit"}
                variant={editImageBg ? "contained" : "outlined"}
                color={editImageBg ? "primary" : "secondary"}
                onClick={async () => {
                  setEditImageBg(!editImageBg);
                  if (!editImageBg) {
                    setPrevEditImageBgQuery(editImageBgQuery);
                  } else {
                    await handleSaveImageBg();
                  }
                }}
              />
              {editImageBg ? (
                <Button
                  label="Cancel"
                  variant="contained"
                  color="tertiary"
                  onClick={() => {
                    setEditImageBg(!editImageBg);
                    setEditImageBgQuery(prevEditImageBgQuery);
                  }}
                />
              ) : null}
            </Pair>
          </Pair>

          <Text size="tiny" variant="secondary">
            Background images are fetched from Unsplash. You can change the
            query to get personalized images. The default value is
            "nature+background". You can have multiple words separated by a "+"
            sign.
          </Text>
        </div>

        <div className="SettingsScreenContainer--Content--Line">
          <BackgroundLine />
        </div>

        <div className="SettingsScreenContainer--Content--Appearance">
          <Text weight="bold" size="subtitle">
            KBar
          </Text>
          <Stack
            direction="row"
            spacing={10}
            alignItems="center"
            justifyContent="flex-start"
            width="full"
          >
            <Text>
              To change the activation command, you can do it from
              "chrome://extensions/shortcuts"
            </Text>
            <CopyButton copyText="chrome://extensions/shortcuts" />
            <Text>and change the command for KBar.</Text>
          </Stack>
          <Text variant="secondary" size="tiny">
            Default command set to {isMac ? "⌘" : "Ctrl"}+K
          </Text>
        </div>

        <div className="SettingsScreenContainer--Content--Line">
          <BackgroundLine />
        </div>

        <div className="SettingsScreenContainer--Content--Appearance">
          <Text weight="bold" size="subtitle">
            New Tab Page
          </Text>
          <Stack
            direction="column"
            spacing={10}
            alignItems="flex-start"
            justifyContent="flex-start"
            width="full"
          >
            <Text>
              To disable the new tab page, follow the instructions clicking the
              button below.
            </Text>
            <Button
              label="Disable New Tab Page"
              color="tertiary"
              onClick={() => {
                setShowInstructionsToDisableNewPage(true);
              }}
            />
          </Stack>
          <Text variant="secondary" size="tiny">
            Note: The new tab page is optional for TabDock, but highly
            recommended. You can disable it at any time. You can access all
            TabDock features from the extension popup clicking in the extension
            icon from the browser toolbar.
          </Text>
        </div>

        <div className="SettingsScreenContainer--Content--Line">
          <BackgroundLine />
        </div>
        <div className="SettingsScreenContainer--Content--Integrations">
          <Text size="subtitle" weight="bold">
            Integrations
          </Text>
          <div className="SettingsScreenContainer--Content--Integrations--List">
            <IntegrationRowWithGoogleCalendar
              name={"Google Calendar"}
              description={
                "Access your calendar from the new tab page any moment."
              }
              disclosure={
                <Text variant="secondary" size="small">
                  TabDock use and transfer to any other app of information
                  received from Google APIs will adhere to{" "}
                  <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                    Google API Services User Data Policy
                  </a>
                  , including the Limited Use requirements.
                </Text>
              }
              image={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Calendar_icon_%282020%29.svg/1024px-Google_Calendar_icon_%282020%29.svg.png"
              }
              details={
                "TabDock will show you your upcoming events and meetings"
              }
              onSettingsClick={() => {
                setOpenSettings("Google_Calendar");
                analyticsService.captureEvent("open_google_calendar_settings", {
                  product: "web",
                });
              }}
            />
          </div>
        </div>

        <div className="SettingsScreenContainer--Content--Line">
          <BackgroundLine />
        </div>

        <div className="SettingsScreenContainer--Content--Appearance">
          <Text weight="bold" size="subtitle">
            Danger Zone
          </Text>
          <Stack
            direction="column"
            spacing={10}
            alignItems="flex-start"
            justifyContent="flex-start"
            width="full"
          >
            <Text>
              If you delete your account, all your data will be lost. If you
              have an active subscription, it will be canceled. This action is
              irreversible.
            </Text>
            <Button
              label="Delete Account"
              endIcon={<Trash2 />}
              variant="outlined"
              color="error"
              onClick={() => {
                setOpenConfirmationModalDeleteAccount(true);
              }}
            />
          </Stack>
        </div>
      </div>

      <div className="SettingsScreenContainer--Footer">
        <Link
          text="Privacy Policy"
          onClick={() => {
            window.open(RoutePaths.Privacy, "_blank");
          }}
        />
        <Link
          text="Terms of Service"
          onClick={() => {
            window.open(RoutePaths.Terms, "_blank");
          }}
        />
        <Link
          text="Help"
          onClick={() => {
            window.open(RoutePaths.Help, "_blank");
          }}
        />
      </div>

      <Modal
        open={showInstructionsToDisableNewPage}
        onClose={() => {
          setShowInstructionsToDisableNewPage(false);
        }}
        header={
          <Text weight="bold" size="subtitle">
            Disable New Tab Page
          </Text>
        }
        height="600px"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "12px",
          }}
        >
          <Text size="subtitle">
            To disable the new tab page, follow the instructions below:
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">1.</Text>
            <Text>
              Uninstall the current version of Tabdock extension, you can do
              this from: chrome://extensions/
            </Text>
            <CopyButton copyText="chrome://extensions/" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">2.</Text>
            <Text>
              In chrome://extensions/ search for Tabdock and click on "Remove"
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">3.</Text>
            <Text>
              Install Tabdock version with no new tab page, click the button to
              copy the link, and paste it in your browser.
            </Text>
            <CopyButton copyText="https://chromewebstore.google.com/detail/tabdock-the-ultimate-tab/ejdpkjfgjdcghnleffmbabomlpdmiplo" />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              marginLeft: "20px",
            }}
          >
            <Text weight="bold">4.</Text>
            <Text>
              It is possible that you need to configure the kbar command again,
              you can do this from: chrome://extensions/shortcuts
            </Text>
            <CopyButton copyText="chrome://extensions/shortcuts" />
          </div>
          <img
            src={"https://i.imgur.com/eWvy3mO.png"}
            alt="disable new tab page instruction"
            width="90%"
            style={{
              borderRadius: "8px",
              marginTop: "10px",
              alignSelf: "center",
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
