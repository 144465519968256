export enum RoutePaths {
  Home = "/",
  Tags = "/tags",
  Settings = "/settings",
  Commands = "/commands",
  SignIn = "/signin",
  SignUp = "/signup",
  Terms = "/terms",
  Privacy = "/privacy",
  Help = "/help",
  Welcome = "/welcome",
  Feedback = "/feedback",
  Oauth2callback = "/oauth2callback",
  NotFound = "*",
}
