import React from "react";
import { Toaster } from "react-hot-toast";
import "./style.scss";
import {
  AnalyticsService,
  BackgroundLine,
  CommandComponentList,
  CommandsList,
  PostHogProvider,
  Text,
} from "@tabdock/common";
import { useAuth } from "../../contexts/AuthProvider";
import { useGetCommands } from "../../hooks/commands/useGetCommands";
import { useCreateCommand } from "../../hooks/commands/useCreateCommand";
import { ICommand } from "../../api/commandsApi";
import { useDeleteCommand } from "../../hooks/commands/useDeleteCommand";
import { useUpdateCommand } from "../../hooks/commands/useUpdateCommand";
import { createToast } from "../../utils/toast";
import { useMediaQuery } from "react-responsive";
import { useIsUserSubscribed } from "../../hooks/subscription/useIsUserSubscibed";

export default function CommandsCenterScreen() {
  const { id } = useAuth();
  const { data: isUserSubscribed } = useIsUserSubscribed(id!);
  const { data: commands } = useGetCommands(id!);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const postHogProvider = new PostHogProvider();
  const analyticsService = new AnalyticsService(postHogProvider);

  const HOST =
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? process.env.REACT_APP_LOCALHOST
      : process.env.REACT_APP_HOST;

  const createCommand = useCreateCommand({
    onSuccess: () => {
      createToast("Command created successfully", "success");
      analyticsService.captureEvent("command_created", {
        product: "web",
      });
    },
    onError: (error) => {
      createToast(error.message, "error");
      analyticsService.captureError("command_created_error", error, {
        product: "web",
      });
    },
  });
  const deleteCommand = useDeleteCommand({
    onSuccess: () => {
      createToast("Command deleted successfully", "success");
      analyticsService.captureEvent("command_deleted", {
        product: "web",
      });
    },
    onError: (error) => {
      createToast(error.message, "error");
      analyticsService.captureError("command_deleted_error", error, {
        product: "web",
      });
    },
  });
  const updateCommand = useUpdateCommand({
    onSuccess: () => {
      createToast("Command updated successfully", "success");
      analyticsService.captureEvent("command_updated", {
        product: "web",
      });
    },
    onError: (error) => {
      createToast(error.message, "error");
      analyticsService.captureError("command_updated_error", error, {
        product: "web",
      });
    },
  });

  const handleCreateCommand = (command: ICommand) => {
    if (!isUserSubscribed && command.term === true) {
      createToast(
        "You need to update to the Ultimate plan to create term commands",
        "error",
      );
      return;
    }
    createCommand.mutate({
      userId: id!,
      command: command,
    });
  };

  const handleDeleteCommand = (commandId: string) => {
    deleteCommand.mutate({
      userId: id!,
      commandId: commandId,
    });
  };

  const handleUpdateCommand = (command: ICommand) => {
    if (!isUserSubscribed && command.term === true) {
      createToast(
        "You need to update to the Ultimate plan to update term commands",
        "error",
      );
      return;
    }
    updateCommand.mutate({
      userId: id!,
      command: command,
    });
  };

  return (
    <div className="CommandsCenterScreenContainer">
      <Toaster />
      <div className="CommandsCenterScreenContainer--Header">
        <Text weight="bold" size="title">
          Commands Center
        </Text>
      </div>
      <div className="CommandsCenterScreenContainer--HeaderLine">
        <BackgroundLine />
      </div>
      <div
        className="CommandsCenterScreenContainer--Content"
        style={
          isTabletOrMobile
            ? {
                height: "70%",
              }
            : undefined
        }
      >
        <CommandsList
          onNewCommand={handleCreateCommand}
          showUpgradeMessage={!isUserSubscribed}
          upgradePlanLink={`${HOST}/settings?openSubscriptionSettings=true`}
        >
          <CommandComponentList
            commands={commands}
            onDelete={handleDeleteCommand}
            onEdit={handleUpdateCommand}
            showUpgradeMessage={!isUserSubscribed}
            upgradePlanLink={`${HOST}/settings?openSubscriptionSettings=true`}
          />
        </CommandsList>
      </div>
    </div>
  );
}
