import React from "react";
import "./style.scss";
import { SuccessIcon, ErrorIcon } from "../../Icons/Icons";
import Text from "../Text/Text";
import { RotatingLines } from "react-loader-spinner";

interface Props {
  message: string;
  type: "success" | "error" | "loading";
  visible?: boolean;
}

export default function ToastNotification({
  message,
  type,
  visible = true,
}: Props) {
  const getIcon = () => {
    switch (type) {
      case "success":
        return <SuccessIcon />;
      case "error":
        return <ErrorIcon />;
      case "loading":
        return (
          <RotatingLines
            visible={true}
            width="18"
            strokeWidth="2"
            strokeColor="gray"
            animationDuration="1"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`ToastNotificationContainer ${
        visible ? "animateEnter" : "animateLeave"
      }`}
    >
      {getIcon()}
      <Text>{message}</Text>
    </div>
  );
}
