import { useNavigate } from "react-router-dom";
import "./style.scss";
import {
  SignUpForm,
  TabdockHalfScreen,
  PostHogProvider,
  AnalyticsService,
  Text,
} from "@tabdock/common";
import {
  signInWithGithub as signUpWithGithub,
  signInWithGoogle as signUpWithGoogle,
} from "../../api/authApi";
import { useSignUpWithEmailAndPassword } from "../../hooks/auth/useSignUpWithEmailAndPassword";
import { RoutePaths } from "../../RoutesPaths";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

export default function SignUpScreen() {
  const navigate = useNavigate();
  const signUp = useSignUpWithEmailAndPassword();
  const [error, setError] = useState<string | null>(null);
  const [providerError, setProviderError] = useState<string | null>(null);
  const [showVerifyEmailMessage, setShowVerifyEmailMessage] = useState(false);

  const postHogProvider = new PostHogProvider();
  const analyticsService = new AnalyticsService(postHogProvider);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  const cleanErrors = () => {
    setError(null);
    setProviderError(null);
  };

  const handleSignUpWithGoogle = async () => {
    try {
      await signUpWithGoogle();
      cleanErrors();
      analyticsService.captureEvent("user_signed_up", {
        product: "web",
        provider: "google",
      });
    } catch (error: any) {
      console.log("Error: ", error);
      setProviderError("there was an error while signing up with google");
      analyticsService.captureError("signed_up_error", error, {
        product: "web",
        provider: "google",
      });
    }
  };

  const handleSignUpWithGithub = async () => {
    try {
      await signUpWithGithub();
      cleanErrors();
      analyticsService.captureEvent("user_signed_up", { provider: "github" });
    } catch (error: any) {
      console.log("Error: ", error);
      setProviderError("there was an error while signing up with github");
      analyticsService.captureError("signed_up_error", error, {
        product: "web",
        provider: "github",
      });
    }
  };

  const handleSignUpWithEmailAndPassword = (
    email: string,
    password: string,
    username: string,
  ): void => {
    signUp.mutate(
      { email, password, username },
      {
        onSuccess: () => {
          setShowVerifyEmailMessage(true);
          cleanErrors();
          analyticsService.captureEvent("user_signed_up", {
            product: "web",
            provider: "email",
          });
        },
        onError: (error: any) => {
          console.log("Error on Signup: ", error);
          setError(error.message);
          analyticsService.captureError("signed_up_error", error, {
            product: "web",
            provider: "email",
          });
        },
      },
    );
  };

  if (isTabletOrMobile) {
    return (
      <div className="SignUpScreenContainerPage">
        <div className="SignUpScreenContainerMobile">
          <SignUpForm
            onSignUp={handleSignUpWithEmailAndPassword}
            onGoToSignIn={() => {
              navigate(RoutePaths.SignIn);
            }}
            onSignUpWithGithub={handleSignUpWithGithub}
            onSignUpWithGoogle={handleSignUpWithGoogle}
            error={error}
            providerError={providerError}
            showVerifyEmailMessage={showVerifyEmailMessage}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "50%",
            padding: "5px",
          }}
        >
          <Text size="tiny">
            By using our Services, you agree to our{" "}
            <a
              href="/privacy"
              target="_blank"
              rel="noreferrer"
            >{`Privacy Policy`}</a>{" "}
            and{" "}
            <a href="/terms" target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>
            .
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className="SignUpScreenContainerPage">
      <div className="SignUpScreenContainer">
        <TabdockHalfScreen porcentage={50}>
          <div className="SignUpScreenContainer--Content">
            <SignUpForm
              onSignUp={handleSignUpWithEmailAndPassword}
              onGoToSignIn={() => {
                navigate(RoutePaths.SignIn);
              }}
              onSignUpWithGithub={handleSignUpWithGithub}
              onSignUpWithGoogle={handleSignUpWithGoogle}
              error={error}
              providerError={providerError}
              showVerifyEmailMessage={showVerifyEmailMessage}
            />
          </div>
        </TabdockHalfScreen>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        <Text size="tiny" variant="white">
          By using our Services, you agree to our{" "}
          <a
            href="/privacy"
            target="_blank"
            rel="noreferrer"
          >{`Privacy Policy`}</a>{" "}
          and{" "}
          <a href="/terms" target="_blank" rel="noreferrer">
            Terms and Conditions
          </a>
          .
        </Text>
      </div>
    </div>
  );
}
