import React from "react";
import TagRow from "../TagRow/TagRow";

type TagType = {
  id: string;
  text: string;
  color: string;
};

interface Props {
  tags?: TagType[];
}

export default function TagComponent({ tags }: Props) {
  if (tags === undefined) return <></>;

  return (
    <>
      {tags?.map((tag) => (
        <TagRow
          id={tag.id}
          tag={tag.text}
          color={tag.color}
          onDelete={() => {}}
          onEdit={() => {}}
        />
      ))}
    </>
  );
}
