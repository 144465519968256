import "./style.scss";
import Text from "../../Display/Text/Text";
import TextPair from "../../Display/TextPair/TextPair";
import Pair from "../../Layout/Pair/Pair";
import Stack from "../../Layout/Stack/Stack";
import TextField from "../TextField/TextField";
import Link from "../../Display/Link/Link";
import Button from "../Button/Button";
import Line from "../../Display/Line/Line";

import GoogleLogo from "../../../assets/google_icon_2.svg";
import GithubLogo from "../../../assets/Github_icon.svg";
import Logo from "../../Display/Logo/Logo";
import { useEffect, useState } from "react";

interface Props {
  onSignIn: (email: string, password: string) => void;
  onForgotPassword: () => void;
  onSignInWithGoogle: () => void;
  onSignInWithGithub: () => void;
  onGoToSignUp: () => void;
  error?: string | null;
  providerError?: string | null;
  loading?: "google" | "github" | "email" | null;
  secondary?: boolean;
}

export default function SignInForm({
  onSignIn,
  onForgotPassword,
  onSignInWithGoogle,
  onSignInWithGithub,
  onGoToSignUp,
  error = null,
  providerError = null,
  loading: initialLoading = null,
  secondary = false,
}: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [loading, setLoading] = useState(initialLoading);

  useEffect(() => {
    setLoading(initialLoading);
  }, [initialLoading]);

  const validate = () => {
    if (email === "") {
      setEmailError("Email is required");
      return false;
    } else {
      setEmailError(null);
    }
    if (password === "") {
      setPasswordError("Password is required");
      return false;
    } else {
      setPasswordError(null);
    }
    return true;
  };

  const handleSignIn = () => {
    if (validate()) {
      try {
        setPasswordError(null);
        setEmailError(null);
        onSignIn(email, password);
        setLoading("email");
      } catch (error: any) {
        setEmailError("Invalid email or password");
        setLoading(null);
      }
    }
  };

  const handleSignInWithGoogle = () => {
    try {
      onSignInWithGoogle();
      setLoading("google");
    } catch (error: any) {
      console.log(error);
      setPasswordError("Error signing in with Google");
      setLoading(null);
    }
  };

  const handleSignInWithGithub = () => {
    try {
      onSignInWithGithub();
      setLoading("github");
    } catch (error: any) {
      console.log(error);
      setPasswordError("Error signing in with Github");
      setLoading(null);
    }
  };

  return (
    <div className="SignInFormContainer">
      <Pair align="center" direction="column" spacing={2}>
        <Logo type="icon" />
        <TextPair
          header="Welcome Back!"
          subheader="Please enter your details."
          size="h2"
          align="center"
        />
      </Pair>
      <Stack direction="column" width="full" spacing={10}>
        <TextField
          placeholder="e.g. email@email.com "
          inputType="email"
          width="full"
          title="Email"
          value={email}
          onChange={setEmail}
          error={emailError}
          disabled={loading !== null}
        />
        <TextField
          placeholder="e.g. password "
          inputType="password"
          width="full"
          title="Password"
          value={password}
          onChange={setPassword}
          error={passwordError}
          disabled={loading !== null}
        />
        {error !== null ? (
          <Text variant="error" size="small">
            {error}
          </Text>
        ) : null}
        <div
          className="SignInFormContainer--Link"
          style={{
            display: "none",
          }}
        >
          <Link
            variant="secondary"
            onClick={onForgotPassword}
            text="Forgot password?"
          />
        </div>
        <Button
          label="Sign In"
          size="large"
          width="full"
          onClick={() => {
            handleSignIn();
          }}
          loading={loading === "email"}
          disabled={loading !== null}
        />
      </Stack>
      <Line text="or" secondary={secondary} />
      <Stack direction="column" width="full" spacing={10}>
        <Button
          label="Sign in with Google"
          startIcon={<img src={GoogleLogo} alt="icon" />}
          variant="outlined"
          color="secondary"
          width="full"
          size="large"
          fixedWidth={true}
          onClick={handleSignInWithGoogle}
          loading={loading === "google"}
          disabled={loading !== null}
        />
        <Button
          label="Sign in with Github"
          startIcon={<img src={GithubLogo} alt="icon" />}
          variant="outlined"
          color="secondary"
          width="full"
          size="large"
          fixedWidth={true}
          onClick={handleSignInWithGithub}
          loading={loading === "github"}
          disabled={loading !== null}
        />
        {providerError !== null ? (
          <Text variant="error" size="small">
            {providerError}
          </Text>
        ) : null}
      </Stack>
      <div className="SignInFormContainer--Bottom">
        <Pair direction="row" align="center" spacing={5}>
          <Text size="regular" weight="regular">
            Don’t have an account?
          </Text>
          <Link variant="primary" onClick={onGoToSignUp} text="Sign Up" />
        </Pair>
      </div>
    </div>
  );
}
