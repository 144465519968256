import { supabaseClient } from "../config/supabase-client";
const COMMANDS = "commands";

export interface ICommand {
  command: string;
  url: string;
  term: boolean;
  termName?: string;
  description: string;
  id: string;
}

export async function getCommands(userId: string) {
  const { data, error } = await supabaseClient
    .from(COMMANDS)
    .select("*")
    .eq("userId", userId);

  if (error) {
    throw error;
  }
  return data[0].command_list;
}

export async function createCommand(userId: string, command: ICommand) {
  const { data, error } = await supabaseClient
    .from(COMMANDS)
    .select("command_list")
    .eq("userId", userId);

  if (error) throw error;

  const commandList = data[0].command_list;
  const commandExists = commandList.find(
    (c: any) => c.command === command.command,
  );

  if (commandExists) {
    throw new Error("Command already exists: " + command.command);
  }

  const newCommand = { ...command, id: command.command, editable: true };
  commandList.push(newCommand);

  const { data: insertData, error: insertError } = await supabaseClient
    .from(COMMANDS)
    .update({ command_list: commandList })
    .eq("userId", userId)
    .select("command_list");

  if (insertError) throw insertError;
  return insertData;
}

export async function updateCommand(userId: string, command: ICommand) {
  const { data, error } = await supabaseClient
    .from(COMMANDS)
    .select("command_list")
    .eq("userId", userId);

  if (error) throw error;

  const commandList = data[0].command_list;
  const commandExists = commandList.find(
    (c: any) => c.command === command.command,
  );
  if (commandExists) {
    throw new Error("Command already exists: " + command.command);
  }

  const commandIndex = commandList.findIndex((c: any) => c.id === command.id);
  commandList[commandIndex] = {
    ...command,
    editable: true,
    id: command.command,
  };

  const { data: updateData, error: updateError } = await supabaseClient
    .from(COMMANDS)
    .update({ command_list: commandList })
    .eq("userId", userId)
    .select("command_list");

  if (updateError) throw updateError;
  return updateData;
}

export async function deleteCommand(userId: string, commandId: string) {
  const { data, error } = await supabaseClient
    .from(COMMANDS)
    .select("command_list")
    .eq("userId", userId);

  if (error) throw error;

  const commandList = data[0].command_list;
  const commandIndex = commandList.findIndex((c: any) => c.id === commandId);
  commandList.splice(commandIndex, 1);

  const { data: deleteData, error: deleteError } = await supabaseClient
    .from(COMMANDS)
    .update({ command_list: commandList })
    .eq("userId", userId)
    .select("command_list");

  if (deleteError) throw deleteError;
  return deleteData;
}
