import { useState } from "react";
import "./style.scss";
import ExpandButton from "../../utils/ExpandButton/ExpandButton";
import TabGroupLabel from "../TabGroupLabel/TabGroupLabel";
import List from "../../Layout/List/List";
import { useSelection } from "../../../Contexts/OpenTabSelectionContext";

type Color =
  | "blue"
  | "grey"
  | "red"
  | "yellow"
  | "green"
  | "pink"
  | "purple"
  | "cyan"
  | "orange";

interface Props {
  id: string;
  title: string;
  color: string;
  children?: React.ReactNode;
  tabs?: any[];
}

export default function OpenTabGroup({
  id,
  title,
  color: passedColor,
  children,
  tabs,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const color = passedColor as Color;
  const { deselectTabs, selectAllTabs, isSelected } = useSelection();

  return (
    <div
      className={`OpenTabGroupContainer OpenTabGroupContainer--${color}`}
      id={id}
      key={id}
    >
      <div className="OpenTabGroupContainer--Header">
        <div className="OpenTabGroupContainer--Header--Title">
          <ExpandButton
            onClick={() => setExpanded(!expanded)}
            expanded={expanded}
          />
          <TabGroupLabel label={title} color={color} />
        </div>

        <div className="OpenTabGroupContainer--Header--Actions">
          <input
            type="checkbox"
            className="OpenTabRow--checkbox"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              if (e.target.checked) {
                selectAllTabs(tabs ?? []);
              } else {
                deselectTabs(tabs ?? []);
              }
            }}
            checked={
              tabs?.length === 0
                ? false
                : tabs?.every((tab) => isSelected(tab.chromeId))
            }
          />
        </div>
      </div>
      <div
        className={`OpenTabGroupContainer--List ${
          expanded
            ? "OpenTabGroupContainer--List--expanded"
            : "OpenTabGroupContainer--List--collapsed"
        }`}
      >
        <List>{children}</List>
      </div>
    </div>
  );
}
