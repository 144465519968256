import { useContext, useState, useEffect, createContext } from "react";
import { useGetUserSettings } from "../hooks/user/useGetUserSettings";
import { useUpdateUserTheme } from "../hooks/user/useUpdateUserTheme";
import { useAuth } from "./AuthProvider";

const ThemeContext = createContext<{
  theme: "light" | "dark";
  toggleTheme: () => void;
}>({
  theme: "light",
  toggleTheme: () => {},
});

export const ThemeProvider = ({ children }: any) => {
  const { user } = useAuth();
  const { data: userSettings } = useGetUserSettings(user?.id);
  const [theme, setTheme] = useState<"light" | "dark">("light");
  const updateTheme = useUpdateUserTheme();

  useEffect(() => {
    if (userSettings) {
      const htmlEl = document.getElementById("root") as HTMLElement;
      setTheme(userSettings.theme);

      updateTheme.mutate({
        userId: user?.id!,
        theme: userSettings.theme,
      });

      if (userSettings.theme === "dark") {
        htmlEl.classList.add("dark");
      } else {
        htmlEl.classList.remove("dark");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings]);

  const toggleTheme = () => {
    const htmlEl = document.getElementById("root") as HTMLElement;
    if (theme === "light") {
      htmlEl.classList.add("dark");
      setTheme("dark");
      updateTheme.mutate({
        userId: user?.id!,
        theme: "dark",
      });
    } else {
      htmlEl.classList.remove("dark");
      setTheme("light");
      updateTheme.mutate({
        userId: user?.id!,
        theme: "light",
      });
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
