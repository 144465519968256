import React, { ReactNode } from "react";
import "./style.scss";
import Resizable from "react-resizable-layout";
import { DragHandleIcon } from "../../Icons/Icons";
import { X, ArrowLeftCircle, ChevronsLeft } from "react-feather";

interface Props {
  children: [ReactNode, ReactNode];
  minWidth?: number;
  maxWidth?: number;
  onClose?: { (): void } | null;
}

export default function ResizableLayout({
  children,
  minWidth = 100,
  maxWidth = 500,
  onClose = () => {},
}: Props) {
  const [hidden, setHidden] = React.useState(false);

  const hideBar = () => {
    setHidden(true);
  };

  const showBar = () => {
    setHidden(false);
  };

  const toggleBar = () => {
    if (!hidden && onClose) {
      setHidden(!hidden);
      onClose();
    } else {
      setHidden(!hidden);
    }
  };

  const Icon = hidden ? ChevronsLeft : X;
  return (
    <Resizable
      axis="x"
      initial={minWidth}
      reverse
      min={minWidth}
      max={maxWidth}
    >
      {({ position: x, separatorProps }) => (
        <div className="ResizableLayoutContent">
          <div
            className="ResizableLayoutContent--Left"
            style={{
              width: `${hidden ? "100%" : `calc(100% - ${x}px)`}`,
            }}
          >
            {children[0]}
          </div>
          <div
            {...separatorProps}
            className="ResizableLayoutContent--Separator"
            onClick={hidden ? showBar : undefined}
          >
            <DragHandleIcon className="ResizableLayoutContent--Separator--Handler" />
          </div>
          <div
            className="ResizableLayoutContent--Right"
            style={{
              width: `${hidden ? "0px" : `${x}px`}`,
            }}
          >
            <div className="ResizableLayoutContent--Right--Close">
              <Icon onClick={toggleBar} />
            </div>
            {children[1]}
          </div>
        </div>
      )}
    </Resizable>
  );
}

/*

{hidden ? (
              <ArrowLeftCircle className="ResizableLayoutContent--Separator--OpenButton" />
            ) : (
              <DragHandleIcon className="ResizableLayoutContent--Separator--Handler" />
            )}

*/
