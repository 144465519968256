import React from "react";
import "./style.scss";
import {
  BackgroundLine,
  Text,
  EditTagList,
  TagComponent,
  Modal,
  Button,
  Pair,
  TextField,
  DropDownMenu,
  DropDownMenuOption,
  ColorOption,
  ColorArray,
} from "@tabdock/common";

const testTags = [
  {
    id: "1",
    text: "tag1",
    color: "blue",
  },
  {
    id: "2",
    text: "tag2",
    color: "red",
  },
  {
    id: "3",
    text: "tag3",
    color: "green",
  },
  {
    id: "4",
    text: "tag4",
    color: "pink",
  },
  {
    id: "5",
    text: "tag5",
    color: "orange",
  },
  {
    id: "6",
    text: "tag6",
    color: "purple",
  },
  {
    id: "7",
    text: "tag7",
    color: "blue",
  },
  {
    id: "8",
    text: "tag8",
    color: "red",
  },
  {
    id: "9",
    text: "tag9",
    color: "green",
  },
  {
    id: "10",
    text: "tag10",
    color: "pink",
  },
  {
    id: "11",
    text: "tag11",
    color: "orange",
  },
  {
    id: "12",
    text: "tag12",
    color: "purple",
  },
  {
    id: "13",
    text: "tag13",
    color: "blue",
  },
  {
    id: "14",
    text: "tag14",
    color: "red",
  },
  {
    id: "15",
    text: "tag15",
    color: "green",
  },
  {
    id: "16",
    text: "tag16",
    color: "pink",
  },
  {
    id: "17",
    text: "tag17",
    color: "orange",
  },
  {
    id: "18",
    text: "tag18",
    color: "purple",
  },
  {
    id: "19",
    text: "tag19",
    color: "blue",
  },
  {
    id: "20",
    text: "tag20",
    color: "red",
  },
];

export default function TagsCenterScreen() {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedColor, setSelectedColor] = React.useState<number | null>(null);

  return (
    <div className="TagsCenterScreenContainer">
      <div className="TagsCenterScreenContainer--Header">
        <Text weight="bold" size="title">
          Tags Center
        </Text>
      </div>
      <div className="TagsCenterScreenContainer--HeaderLine">
        <BackgroundLine />
      </div>
      <div className="TagsCenterScreenContainer--Content">
        <EditTagList onNewTag={() => setOpenModal(true)}>
          <TagComponent tags={testTags} />
        </EditTagList>
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        header={
          <Text weight="bold" size="subtitle">
            Create Tag
          </Text>
        }
        footer={
          <Pair direction="row">
            <Button
              label="cancel"
              color="tertiary"
              onClick={() => setOpenModal(false)}
            />
            <Button label="create" />
          </Pair>
        }
      >
        <Pair>
          <TextField placeholder="tag" title="Add Tags Name" />
          <DropDownMenu
            title="Color"
            placeholder="Color"
            width="full"
            selected={selectedColor}
          >
            {ColorArray.map((color, index) => (
              <DropDownMenuOption
                key_value={color}
                onClick={() => {
                  setSelectedColor(index);
                }}
                active={index === selectedColor}
              >
                <ColorOption color={color} />
              </DropDownMenuOption>
            ))}
          </DropDownMenu>
        </Pair>
      </Modal>
    </div>
  );
}
