import "./style.scss";
import Tag from "../Tag/Tag";
import Pair from "../../Layout/Pair/Pair";
import IconButton from "../../Inputs/IconButton/IconButton";

interface Props {
  id: string;
  tag: string;
  color: string;
  onDelete: () => void;
  onEdit: () => void;
}

export default function TagRow({
  id,
  tag,
  color,
  onDelete = () => {},
  onEdit = () => {},
}: Props) {
  return (
    <div className="TagRow" id={id}>
      <Tag text={tag} color={color} />
      <div className="TagRowActions">
        <Pair direction="row">
          <IconButton icon="edit" variant="tertiary" onClick={onEdit} />
          <IconButton icon="trash" variant="tertiary" onClick={onDelete} />
        </Pair>
      </div>
    </div>
  );
}
