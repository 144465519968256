export const getTabsAndGroupsCount = (tabsOrGroups: any) => {
  let tabsCount = 0,
    groupsCount = 0;

  tabsOrGroups?.forEach((tabOrGroup: any) => {
    if (typeof tabOrGroup === "object" && "tabs" in tabOrGroup) {
      groupsCount++;
      if (Array.isArray(tabOrGroup.tabs)) {
        tabsCount += tabOrGroup.tabs.length;
      }
    } else {
      tabsCount++;
    }
  });

  return { tabsCount, groupsCount };
};
