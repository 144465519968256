import React from "react";
import "./style.scss";
import ModalContainer from "../ModalContainer/ModalContainer";
import { X } from "react-feather";

interface Props {
  open: boolean;
  onClose: () => any;
  header?: React.ReactNode | null;
  footer?: React.ReactNode | null;
  children: React.ReactNode;
  width?: string;
  height?: string;
  injectStyles?: { [key: string]: string };
}

export default function Modal({
  open,
  onClose,
  header = null,
  footer = null,
  children,
  width = "550px",
  height = "410px",
  injectStyles = {},
}: Props) {
  return (
    <ModalContainer open={open} onClose={onClose}>
      <div
        className="ModalComponentContainer"
        style={{
          width: width,
          height: height,
          ...injectStyles,
        }}
      >
        <div className="ModalComponentContainer--Header">
          {header}
          <X
            className="ModalComponentContainer--Header--Close"
            onClick={onClose}
          />
        </div>
        <div className="ModalComponentContainer--Content">{children}</div>
        {footer !== null ? (
          <div className="ModalComponentContainer--Footer">{footer}</div>
        ) : null}
      </div>
    </ModalContainer>
  );
}
