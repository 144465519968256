import { useMutation } from "react-query";
import { deleteUser } from "../../api/userApi";

interface Props {
  onSuccess: () => void;
  onError: (error: Error) => void;
}

export function useDeleteUser({ onSuccess, onError }: Props) {
  return useMutation(() => deleteUser(), {
    onSuccess: () => {
      onSuccess();
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
}
