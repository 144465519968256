import React from "react";
import "./style.scss";
import { ChevronLeft } from "react-feather";

interface Props {
  onClick: () => void;
  expanded: boolean;
  rounded?: boolean;
}

export default function ExpandSideBarButton({
  onClick,
  expanded,
  rounded = false,
}: Props) {
  return (
    <div
      className={`ExpandSideBarButtonContainer ${
        rounded ? "ExpandSideBarButtonContainerRounded" : ""
      }`}
      onClick={onClick}
    >
      <ChevronLeft
        className={`ExpandSideBarButtonIcon ${
          expanded
            ? "ExpandSideBarButtonIcon--Left"
            : "ExpandSideBarButtonIcon--Right"
        }`}
        size={24}
      />
    </div>
  );
}
