import "./style.scss";
import {
  HorizontalBlueLogo,
  IconBlueLogo,
  IconBlueLogoSmall,
  FullBlueLogo,
  FullWhiteLogo,
  HorizontalBlueSmallLogo,
} from "../../Icons/Icons";

interface Props {
  type?:
    | "horizontal"
    | "icon"
    | "icon-small"
    | "full-white"
    | "full-blue"
    | "horizontal-small";
}

export default function Logo({ type = "icon" }: Props) {
  switch (type) {
    case "horizontal":
      return <HorizontalBlueLogo />;
    case "horizontal-small":
      return <HorizontalBlueSmallLogo />;
    case "full-white":
      return <FullWhiteLogo />;
    case "full-blue":
      return <FullBlueLogo />;
    case "icon":
      return <IconBlueLogo />;
    case "icon-small":
      return <IconBlueLogoSmall />;
    default:
      return <IconBlueLogo />;
  }
}
