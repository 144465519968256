import "./style.scss";
import Logo from "../../Display/Logo/Logo";
import Stack from "../../Layout/Stack/Stack";
import SidebarNavButton from "../SidebarNavButton/SidebarNavButton";
import Line from "../../Display/Line/Line";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";
import ProfileDisplay from "../../Display/ProfileDisplay/ProfileDisplay";
import React from "react";
import ExpandSideBarButton from "../../utils/ExpandSideBarButton/ExpandSideBarButton";

type Active = "all" | "tags" | "settings" | "commands" | "feedback";

interface Props {
  username: string;
  email: string;
  onSignOut: () => void;
  active: Active;
  setActive: (active: Active) => void;
  toggleTheme: () => void;
  currerntTheme: "light" | "dark";
}

export default function SideNavBar({
  username,
  email,
  onSignOut,
  active,
  setActive,
  toggleTheme,
  currerntTheme = "light",
}: Props) {
  const [barState, setBarState] = React.useState<"open" | "closed">("closed");

  const handleBarStateToggle = () => {
    if (barState === "open") {
      setBarState("closed");
    } else {
      setBarState("open");
    }
  };

  const opened = barState === "open";
  return (
    <div
      className={`SideNavBarContainer ${
        opened
          ? "SideNavBarContainer--expanded"
          : "SideNavBarContainer--collapsed"
      }`}
    >
      <div className="SideNavBarContainer--header">
        <Logo type={opened ? "horizontal-small" : "icon-small"} />
        <div className="SideNavBarContainer--header--statebutton">
          <ExpandSideBarButton
            onClick={handleBarStateToggle}
            expanded={opened}
          />
        </div>
        <Stack spacing={8}>
          <SidebarNavButton
            onClick={() => {
              setActive("all");
            }}
            active={active === "all"}
            label="All workspaces"
            icon="briefcase"
            hideLabel={!opened}
          />
          <SidebarNavButton
            onClick={() => {
              setActive("commands");
            }}
            active={active === "commands"}
            label="Commands center"
            icon="commands"
            hideLabel={!opened}
          />
          <SidebarNavButton
            onClick={() => {
              window.open(
                "https://tabdock.canny.io/feature-requests",
                "_blank",
              );
            }}
            active={active === "feedback"}
            label="Request Feature"
            icon="feedback"
            hideLabel={!opened}
          />
          <Line />
          <SidebarNavButton
            onClick={() => {
              setActive("settings");
            }}
            active={active === "settings"}
            label="Settings"
            icon="settings"
            hideLabel={!opened}
          />
        </Stack>
      </div>

      <div className="SideNavBarContainer--bottom">
        <Stack spacing={8}>
          <ThemeSwitch
            isDark={currerntTheme === "dark"}
            onChange={toggleTheme}
            simple={!opened}
          />
          <Line />
          <ProfileDisplay
            username={username}
            email={email}
            onSignOut={onSignOut}
            simple={!opened}
          />
        </Stack>
      </div>
    </div>
  );
}

/*
<SidebarNavButton
            onClick={() => {
              setActive("tags");
            }}
            active={active === "tags"}
            label="Tags center"
            icon="bookmark"
            hideLabel={!opened}
          />
*/
