import { useQuery } from "react-query";
import {
  getUserIntegrationToken,
  INTEGRATIONS,
} from "../../api/userIntegrationsApi";

export function useGetUserIntegrationToken(
  userId: string,
  integration: INTEGRATIONS,
) {
  return useQuery(
    ["user_integration_token", userId, integration],
    () => getUserIntegrationToken(userId, integration),
    {
      enabled: !!userId,
    },
  );
}
