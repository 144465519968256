import React from "react";
import "./style.scss";
import Pair from "../../Layout/Pair/Pair";
import Text from "../Text/Text";
import Button from "../../Inputs/Button/Button";
import { ConnectIcon, CheckCircleIcon } from "../../Icons/Icons";
import { ChevronDown } from "react-feather";
import IconButton from "../../Inputs/IconButton/IconButton";
import Stack from "../../Layout/Stack/Stack";

interface Props {
  name: string;
  description: string;
  image: string;
  details: string;
  disclosure?: React.ReactNode;
  connected?: boolean;
  onClick?: () => void;
  onSettingsClick?: () => void;
}

export default function IntegrationRow({
  name,
  description,
  image,
  details,
  disclosure = null,
  connected = false,
  onClick,
  onSettingsClick,
}: Props) {
  const [expanded, setExpanded] = React.useState(false);

  const Icon = connected ? CheckCircleIcon : ConnectIcon;

  return (
    <div className="IntegrationRowMainContainer">
      <div className="IntegrationRowContainer">
        <Pair direction="row" spacing={20}>
          <img
            src={image}
            alt="Integration_image"
            className="IntegrationRowContainer--Image"
          />
          <Stack direction="column" spacing={2}>
            <Text weight="bold">{name}</Text>
            <Text>{description}</Text>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "90%",
              }}
            >
              {disclosure}
            </div>
          </Stack>
        </Pair>
        <div className="IntegrationRowContainer--Actions">
          <div
            className="IntegrationRowContainer--Actions--LearnMore"
            onClick={() => setExpanded(!expanded)}
          >
            <Pair direction="row" align="center">
              <Text weight="semibold">Learn more</Text>
              <ChevronDown
                className={`ExpandButtonIconIntegration ${
                  expanded
                    ? "ExpandButtonIconIntegration--Up"
                    : "ExpandButtonIconIntegration--Down"
                }`}
              />
            </Pair>
          </div>
          <div className="IntegrationRowContainer--Actions--Button">
            <Pair direction="row" align="center">
              <Button
                label={connected ? "Done" : "Connect"}
                variant="contained"
                color={connected ? "success" : "black"}
                startIcon={<Icon />}
                onClick={onClick}
              />
              {connected ? (
                <IconButton
                  icon="settings"
                  fill
                  variant="secondary"
                  onClick={onSettingsClick}
                />
              ) : null}
            </Pair>
          </div>
        </div>
      </div>
      {expanded ? (
        <div className="IntegrationRowDetails">
          <Text variant="secondary" size="small">
            {details}
          </Text>
        </div>
      ) : null}
    </div>
  );
}
