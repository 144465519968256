import React from "react";
import "./style.scss";

interface Props {
  [key: string]: any;
}

export default function PageContainer({ ...props }: Props) {
  return <div className="PageContainerContainer">{props.children}</div>;
}
