import React from "react";
import "./style.scss";

interface Props {
  message: string;
  isVisible: boolean;
  width?: string;
  position?: { x: number; y: number };
}

export default function Tooltip({
  message,
  position,
  width = "auto",
  isVisible,
}: Props) {
  if (!isVisible) return null;

  return (
    <div
      className="TooltipContainer"
      style={{
        width: width,
        top: position?.y + "px",
        left: position?.x + "px",
      }}
    >
      <p className="TooltipContainer--Message">{message}</p>
    </div>
  );
}
