import { supabaseClient } from "../config/supabase-client";
const USER = "user";

export async function getTotalAmountOfWorkspacesFromServer(): Promise<number> {
  const { data, error } = await supabaseClient.rpc("get_workspace_count");
  if (error) throw error;

  return data;
}

export async function isUserSubscribed(userId: string): Promise<boolean> {
  const { data, error } = await supabaseClient
    .from(USER)
    .select("ultimate_plan_active")
    .eq("id", userId)
    .single();
  if (error) throw error;

  return data.ultimate_plan_active;
}
