import React, { useState } from "react";
import SignInForm from "../SignInForm/SignInForm";
import SignUpForm from "../SignUpForm/SignUpForm";

interface Props {
  initialForm: "sign-in" | "sign-up";
  secondary?: boolean;
}

export default function SignInUpForms({
  initialForm,
  secondary = false,
  onSignIn,
  onForgotPassword,
  onSignInWithGoogle,
  onSignInWithGithub,
  onGoToSignUp = () => {},
  onSignUp,
  onSignUpWithGoogle,
  onSignUpWithGithub,
  onGoToSignIn = () => {},
  showVerifyEmailMessage = false,
  error,
  providerError,
}: Props &
  React.ComponentProps<typeof SignInForm> &
  React.ComponentProps<typeof SignUpForm>) {
  const [activeForm, setActiveForm] = useState(initialForm);

  if (activeForm === "sign-in") {
    return (
      <SignInForm
        onSignIn={onSignIn}
        onForgotPassword={onForgotPassword}
        onGoToSignUp={() => {
          setActiveForm("sign-up");
        }}
        onSignInWithGithub={onSignInWithGithub}
        onSignInWithGoogle={onSignInWithGoogle}
        secondary={secondary}
        error={error}
        providerError={providerError}
      />
    );
  }

  return (
    <SignUpForm
      onSignUp={onSignUp}
      onGoToSignIn={() => {
        setActiveForm("sign-in");
      }}
      onSignUpWithGithub={onSignUpWithGithub}
      onSignUpWithGoogle={onSignUpWithGoogle}
      secondary={secondary}
      error={error}
      providerError={providerError}
      showVerifyEmailMessage={showVerifyEmailMessage}
    />
  );
}
