import React from "react";
import "./style.scss";
import { RotatingLines } from "react-loader-spinner";

interface Props {
  variant?: "text" | "outlined" | "contained";
  disabled?: boolean;
  onClick?: { (e: React.MouseEvent): void } | null;
  size?: "small" | "medium" | "large";
  startIcon?: React.ReactNode | null;
  endIcon?: React.ReactNode | null;
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "black"
    | "success"
    | "error"
    | "white-outline";
  width?: "full" | "auto" | "fit-content";
  label: string;
  fixedWidth?: boolean;
  loading?: boolean;
}

export default function Button({
  variant = "contained",
  disabled = false,
  onClick = null,
  size = "medium",
  startIcon = null,
  endIcon = null,
  color = "primary",
  width = "fit-content",
  label,
  fixedWidth = false,
  loading = false,
}: Props): JSX.Element {
  const buttonClass = `Button ${variant} ${color} ${size} ${width} ${
    fixedWidth ? "icon" : ""
  } ${loading ? "loading" : ""}`;

  return (
    <button
      id={`btn-${label}`}
      className={buttonClass}
      onClick={onClick ? onClick : undefined}
      disabled={disabled}
    >
      {loading ? (
        <RotatingLines
          visible={true}
          width="18"
          strokeWidth="2"
          strokeColor="white"
          animationDuration="1"
        />
      ) : null}
      {startIcon}
      {loading ? "Loading..." : label}
      {endIcon}
    </button>
  );
}
