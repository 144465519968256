import React from "react";
import "./style.scss";
import Text from "../Text/Text";
import Pair from "../../Layout/Pair/Pair";
import IconButton from "../../Inputs/IconButton/IconButton";
import CommandModal, { ICommand } from "../../Inputs/CommandModal/CommandModal";

interface Props {
  id: string;
  command: string;
  url: string;
  term: boolean;
  termName?: string;
  description: string;
  editable: boolean;
  onDelete: () => void;
  onEdit: (command: ICommand) => void;
  showUpgradeMessage?: boolean;
  upgradePlanLink?: string;
}

export default function CommandRow({
  id,
  command,
  url,
  term,
  termName = "",
  description,
  editable,
  onDelete = () => {},
  onEdit = () => {},
  showUpgradeMessage = false,
  upgradePlanLink = "",
}: Props) {
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <div className="CommandRowContainer" key={id}>
      <div className="CommandRowContainer--Row">
        <div className="CommandRowContainer--Row--Left">
          <Text weight="regular" size="regular">
            <b>{command}</b>
            {term ? " <" + termName + ">" : ""}
          </Text>
        </div>
        <div className="CommandRowContainer--Row--Divider" />
        <div className="CommandRowContainer--Row--Right">
          <Text weight="regular" size="regular">
            {description}
          </Text>
          {editable ? (
            <div className="CommandRowContainer--Row--Right--Actions">
              <Pair direction="row">
                <IconButton
                  icon="edit"
                  variant="tertiary"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                />
                <IconButton
                  icon="trash"
                  variant="tertiary"
                  onClick={onDelete}
                />
              </Pair>
            </div>
          ) : null}
        </div>
      </div>

      <hr className="CommandRowContainer--Divider" />

      <CommandModal
        openModal={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={(newCommand) => onEdit(newCommand)}
        command={{
          id: id,
          command: command,
          url: url,
          term: term,
          termName: termName,
          description: description,
          editable: editable,
        }}
        showUpgradeMessage={showUpgradeMessage}
        upgradePlanLink={upgradePlanLink}
      />
    </div>
  );
}
