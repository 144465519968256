import React, { useEffect, useState } from "react";
import "./style.scss";
import Modal from "../../Layout/Modal/Modal";
import Text from "../../Display/Text/Text";
import Pair from "../../Layout/Pair/Pair";
import Button from "../Button/Button";
import TextField from "../TextField/TextField";
import Stack from "../../Layout/Stack/Stack";
import Switch from "react-switch";
import TooltipIcon from "../../Display/TooltipIcon/TooltipIcon";

export interface ICommand {
  command: string;
  url: string;
  term: boolean;
  termName?: string;
  description: string;
  editable: boolean;
  id: string;
}

interface Props {
  onConfirm: (command: ICommand) => void;
  openModal: boolean;
  onClose: () => void;
  command?: ICommand;
  error?: string;
  showUpgradeMessage?: boolean;
  upgradePlanLink?: string;
}

export default function CommandModal({
  openModal,
  onClose,
  command = undefined,
  onConfirm,
  error: initialError = "",
  showUpgradeMessage = false,
  upgradePlanLink = "",
}: Props) {
  const [commandInput, setCommandInput] = useState<ICommand>({
    command: "",
    url: "",
    term: false,
    termName: "",
    description: "",
    id: "",
    editable: true,
  });
  const [error, setError] = useState(initialError);
  const [commandError, setCommandError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [termNameError, setTermNameError] = useState(false);

  const title = command ? "Edit Command" : "Create New Command";
  const buttonLabel = command ? "Save" : "Create";

  useEffect(() => {
    if (command) {
      setCommandInput({ ...command });
    }
  }, [command]);

  const clearInput = () => {
    setCommandInput({
      command: "",
      url: "",
      term: false,
      termName: "",
      description: "",
      id: "",
      editable: true,
    });
    clearErrors();
  };

  const clearErrors = () => {
    setCommandError(false);
    setUrlError(false);
    setDescriptionError(false);
    setTermNameError(false);

    setError("");
  };

  const handleConfirm = () => {
    if (!commandInput.command) {
      clearErrors();
      setError("Command is required");
      setCommandError(true);
      return;
    }
    if (!commandInput.url) {
      clearErrors();
      setError("Url is required");
      setUrlError(true);
      return;
    }
    if (!commandInput.description) {
      clearErrors();
      setError("Description is required");
      setDescriptionError(true);
      return;
    }
    if (commandInput.term && !commandInput.termName) {
      clearErrors();
      setError("Term name is required");
      setTermNameError(true);
      return;
    }
    clearErrors();

    onConfirm(commandInput);
    onClose();
    clearInput();
  };

  const isError = commandError || urlError || descriptionError || termNameError;
  let heightNumber = commandInput.term ? 630 : 550;
  if (isError) heightNumber += 40;

  return (
    <Modal
      open={openModal}
      injectStyles={{
        minHeight: "550px",
        height: `${heightNumber}px`,
      }}
      onClose={() => {
        onClose();
        clearInput();
      }}
      header={
        <Text weight="bold" size="subtitle">
          {title}
        </Text>
      }
      footer={
        <Pair direction="row">
          <Button
            label="cancel"
            color="tertiary"
            onClick={() => {
              onClose();
              clearInput();
            }}
          />
          <Button
            label={buttonLabel}
            onClick={() => {
              handleConfirm();
            }}
          />
        </Pair>
      }
    >
      <Stack spacing={16}>
        {error &&
          !commandError &&
          !urlError &&
          !descriptionError &&
          !termNameError && (
            <Pair>
              <Text color="error" size="small" variant="error" weight="bold">
                Error:
              </Text>
              <Text color="error" size="small" variant="error">
                {error}
              </Text>
            </Pair>
          )}
        <TextField
          placeholder="gs"
          title="Command"
          value={commandInput.command}
          onChange={(value) => {
            setCommandInput({
              ...commandInput,
              command: value,
            });
          }}
          error={commandError ? error : null}
        />
        <TextField
          placeholder="https://www.google.com/"
          title="Url"
          value={commandInput.url}
          onChange={(value) => {
            setCommandInput({
              ...commandInput,
              url: value,
            });
          }}
          error={urlError ? error : null}
        />
        <TextField
          placeholder="Open Google Browser"
          title="Description"
          value={commandInput.description}
          onChange={(value) => {
            setCommandInput({
              ...commandInput,
              description: value,
            });
          }}
          error={descriptionError ? error : null}
        />
        <Stack direction="row" spacing={16}>
          <Stack direction="row" spacing={8} alignItems="center">
            <Text
              weight="bold"
              variant={showUpgradeMessage ? "tertiary" : "primary"}
            >
              Term
            </Text>
            <Switch
              checked={commandInput.term}
              height={22}
              width={45}
              handleDiameter={15}
              disabled={showUpgradeMessage}
              onChange={(value) => {
                setCommandInput({
                  ...commandInput,
                  term: value,
                });
              }}
            />
            <TooltipIcon text="term name will be replace in the URL when executed" />
          </Stack>
          {showUpgradeMessage && (
            <Button
              label="Upgrade Plan to use this feature"
              size="small"
              variant="outlined"
              onClick={() => {
                window.open(upgradePlanLink, "_blank");
              }}
            />
          )}
        </Stack>

        <TextField
          placeholder="search"
          title="Term name"
          titleVariant={showUpgradeMessage ? "tertiary" : "primary"}
          value={commandInput.termName}
          onChange={(value) => {
            setCommandInput({
              ...commandInput,
              termName: value,
            });
          }}
          disabled={!commandInput.term || showUpgradeMessage}
          error={termNameError ? error : null}
        />
        {commandInput.term && (
          <Pair>
            <Text size="small" variant="secondary">
              Note: Url must contain the term name in the format of{" "}
              {`<termName>`}
            </Text>
            <Text size="small" variant="secondary">
              example: https://www.google.com/search?q=
              {`<termName>`}
            </Text>
          </Pair>
        )}
      </Stack>
    </Modal>
  );
}
