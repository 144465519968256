import React, { useEffect, useState } from "react";
import { Clock, Briefcase } from "react-feather";
import ListHeader from "../ListHeader/ListHeader";
import Stack from "../../Layout/Stack/Stack";
import Text from "../Text/Text";
import Pair from "../../Layout/Pair/Pair";
import List from "../../Layout/List/List";
import { useSelection } from "../../../Contexts/OpenTabSelectionContext";
import { TabGroupType, TabsOrGroups } from "../../../Types/Types";

interface Props {
  workspaceName?: string | null;
  windowNumber: number;
  tabsCount: number;
  groupsCount: number;
  children?: React.ReactNode;
  id?: string;
  tabsOrGroups: TabsOrGroups[];
}

export default function OpenTabList({
  workspaceName = null,
  windowNumber,
  tabsCount,
  groupsCount,
  children,
  id,
  tabsOrGroups,
}: Props) {
  const [expanded, setExpanded] = useState(true);
  const [allTabs, setAllTabs] = useState<any[]>([]);
  const { deselectTabs, selectAllTabs, isSelected } = useSelection();
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setAllTabs(getAllTabs(tabsOrGroups));
  }, [tabsOrGroups]);

  const getAllTabs = (tabsOrGroups: TabsOrGroups[]): any[] => {
    return tabsOrGroups.reduce((acc: any, tabOrGroup) => {
      if (typeof tabOrGroup === "object" && "tabs" in tabOrGroup) {
        const group = tabOrGroup as TabGroupType;
        if (group.tabs === undefined) return [...acc];
        return [...acc, ...group.tabs];
      } else {
        return [...acc, tabOrGroup];
      }
    }, []);
  };

  const Icon = workspaceName ? Briefcase : Clock;
  return (
    <div className="TabListContainer" key={id}>
      <ListHeader
        title={`Window ${windowNumber}`}
        expanded={expanded}
        onExpand={toggleExpanded}
        subheader={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={10}
          >
            <Pair direction="row" align="center">
              <Icon size={14} className="tablistcalendaricon" />
              <Text size="small" variant="secondary" cutText>
                {workspaceName ?? "Unsaved"}
              </Text>
            </Pair>
            <Text size="small" variant="secondary">
              |
            </Text>
            <Text size="small" variant="secondary" cutText>
              {tabsCount} tabs ({groupsCount} groups)
            </Text>
          </Stack>
        }
        rightOptions={
          <input
            type="checkbox"
            className="OpenTabRow--checkbox"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              if (e.target.checked) {
                selectAllTabs(allTabs ?? []);
              } else {
                deselectTabs(allTabs ?? []);
              }
            }}
            checked={
              allTabs.length === 0
                ? false
                : allTabs?.every((tab) => isSelected(tab.chromeId))
            }
          />
        }
      />
      <div
        className={`TabListContainer--List ${
          expanded
            ? "TabListContainer--List--expanded"
            : "TabListContainer--List--collapsed"
        }`}
      >
        <List removePadding>{children}</List>
      </div>
    </div>
  );
}
