import { supabaseClient } from "../config/supabase-client";
const TAB = "tab";

export async function createTab(
  workspaceId: string,
  title: string,
  url: string,
  faviconUrl: string,
  index: number,
  tabGroupId?: string,
  active?: boolean,
  audible?: boolean,
  autodiscardable?: boolean,
  discarded?: boolean,
  pinned?: boolean,
  windowId?: number,
  openerTabId?: string,
) {
  let favicon = faviconUrl;
  if (
    !faviconUrl ||
    faviconUrl === "" ||
    faviconUrl === null ||
    faviconUrl === undefined
  ) {
    favicon = "https://i.imgur.com/di2d4PI.png";
  }

  const { data, error } = await supabaseClient
    .from(TAB)
    .insert([
      {
        workspaceId,
        tabGroupId,
        title,
        url,
        faviconUrl: favicon,
        index,
        active,
        audible,
        autodiscardable,
        discarded,
        pinned,
        windowId,
        openerTabId,
      },
    ])
    .select("*");

  if (error) throw error;
  return data;
}

export async function getTabs(workspaceId: string) {
  const { data, error } = await supabaseClient
    .from(TAB)
    .select("*")
    .eq("workspaceId", workspaceId)
    .order("index", { ascending: true });

  if (error) throw error;
  return data;
}

export async function deleteTab(id: string) {
  const { data, error } = await supabaseClient.from(TAB).delete().eq("id", id);

  if (error) throw error;
  return data;
}

export async function updateTab(
  id: string,
  title?: string,
  url?: string,
  faviconUrl?: string,
  index?: number,
  tabGroupId?: string,
  active?: boolean,
  audible?: boolean,
  autodiscardable?: boolean,
  discarded?: boolean,
  pinned?: boolean,
  windowId?: number,
  openerTabId?: string,
) {
  const { data, error } = await supabaseClient
    .from(TAB)
    .update({
      title,
      url,
      faviconUrl,
      index,
      tabGroupId,
      active,
      audible,
      autodiscardable,
      discarded,
      pinned,
      windowId,
      openerTabId,
    })
    .eq("id", id)
    .select("*");

  if (error) throw error;
  return data;
}
