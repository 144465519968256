import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface ITab {
  chromeId: string;
  title: string;
  url: string;
  faviconUrl: string;
}

interface ISelectionContext {
  selectedTabs: { [key: string]: ITab };
  draggingSelection: boolean;
  toggleTabSelection: (tab: ITab) => void;
  selectAllTabs: (tabs: ITab[]) => void;
  deselectTabs: (tabs: ITab[]) => void;
  deselectAllTabs: () => void;
  isSelected: (tabId: string) => boolean;
  setDraggingSelection: (dragging: boolean) => void;
}

const SelectionContext = createContext<ISelectionContext | undefined>(
  undefined,
);

export const SelectionProvider = ({ children }: { children: ReactNode }) => {
  const [selectedTabs, setSelectedTabs] = useState<{ [key: string]: ITab }>({});
  const [draggingSelection, setDraggingSelection] = useState(false);

  const toggleTabSelection = (tab: ITab) => {
    if (selectedTabs[tab.chromeId]) {
      const newSelectedTabs = { ...selectedTabs };
      delete newSelectedTabs[tab.chromeId];
      setSelectedTabs(newSelectedTabs);
    } else {
      setSelectedTabs({ ...selectedTabs, [tab.chromeId]: tab });
    }
  };

  const selectAllTabs = (tabs: ITab[]) => {
    const newSelectedTabs = tabs.reduce((acc, tab) => {
      acc[tab.chromeId] = tab;
      return acc;
    }, {} as { [key: string]: ITab });

    setSelectedTabs({ ...selectedTabs, ...newSelectedTabs });
  };

  const deselectAllTabs = () => {
    setSelectedTabs({});
  };

  const deselectTabs = (tabs: ITab[]) => {
    const newSelectedTabs = { ...selectedTabs };
    tabs.forEach((tab) => {
      delete newSelectedTabs[tab.chromeId];
    });

    setSelectedTabs(newSelectedTabs);
  };

  const isSelected = (tabId: string) => {
    return !!selectedTabs[tabId];
  };

  return (
    <SelectionContext.Provider
      value={{
        selectedTabs,
        draggingSelection,
        toggleTabSelection,
        selectAllTabs,
        deselectAllTabs,
        isSelected,
        deselectTabs,
        setDraggingSelection,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

export const useSelection = () => {
  const context = useContext(SelectionContext);
  if (context === undefined) {
    throw new Error("useSelection must be used within a SelectionProvider");
  }
  return context;
};
