import { Children, PropsWithChildren } from "react";
import "./style.scss";

interface Props {
  gap?: number;
  removePadding?: boolean;
}

export default function List({
  children,
  gap = 5,
  removePadding = false,
}: PropsWithChildren<Props>) {
  const arrayChildren = Children.toArray(children);

  return (
    <div
      className={`ListContainer ${removePadding ? "no-padding" : ""}`}
      style={{
        gap: `${gap}px`,
      }}
    >
      {arrayChildren.map((child, index) => child)}
    </div>
  );
}
