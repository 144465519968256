import { InstallationProcess } from "@tabdock/common";
import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import { useSignInWithEmailAndPassword } from "../../hooks/auth/useSignInWithEmailAndPassword";
import { signInWithGithub, signInWithGoogle } from "../../api/authApi";
import { useSignUpWithEmailAndPassword } from "../../hooks/auth/useSignUpWithEmailAndPassword";
import withCrisp from "../../HOCs/withCrisp";

function InstallationProcessScreen() {
  const { user } = useAuth();

  const signIn = useSignInWithEmailAndPassword();
  const signUp = useSignUpWithEmailAndPassword();
  const [error, setError] = useState<string | null>(null);
  const [providerError, setProviderError] = useState<string | null>(null);
  const [showVerifyEmailMessage, setShowVerifyEmailMessage] = useState(false);

  const cleanErrors = () => {
    setError(null);
    setProviderError(null);
  };

  const handleSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      cleanErrors();
    } catch (error: any) {
      console.log("Error: ", error);
      setProviderError("there was an error while signing in with google");
    }
  };

  const handleSignInWithGithub = async () => {
    try {
      await signInWithGithub();
      cleanErrors();
    } catch (error: any) {
      console.log("Error: ", error);
      setProviderError("there was an error while signing in with github");
    }
  };

  const handleSignInWithEmailAndPassword = (
    email: string,
    password: string,
  ): void => {
    signIn.mutate(
      { email, password },
      {
        onSuccess: () => {
          //navigate(RoutePaths.Home);
          cleanErrors();
        },
        onError: (error: any) => {
          console.log("Error on Signin: ", error);
          setError(error.message);
        },
      },
    );
  };

  const handleSignUpWithGoogle = async () => {
    try {
      await signInWithGoogle();
      cleanErrors();
    } catch (error: any) {
      console.log("Error: ", error);
      setProviderError("there was an error while signing up with google");
    }
  };

  const handleSignUpWithGithub = async () => {
    try {
      await signInWithGithub();
      cleanErrors();
    } catch (error: any) {
      console.log("Error: ", error);
      setProviderError("there was an error while signing up with github");
    }
  };

  const handleSignUpWithEmailAndPassword = (
    email: string,
    password: string,
    username: string,
  ): void => {
    signUp.mutate(
      { email, password, username },
      {
        onSuccess: () => {
          setShowVerifyEmailMessage(true);
          cleanErrors();
        },
        onError: (error: any) => {
          console.log("Error on Signup: ", error);
          setError(error.message);
        },
      },
    );
  };

  return (
    <div className="SignInScreenContainerPage">
      <div className="SignInScreenContainer">
        <InstallationProcess
          isUserAuthenticated={user !== undefined}
          onSignIn={handleSignInWithEmailAndPassword}
          onSignInWithGithub={handleSignInWithGithub}
          onSignInWithGoogle={handleSignInWithGoogle}
          onSignUp={handleSignUpWithEmailAndPassword}
          onSignUpWithGithub={handleSignUpWithGithub}
          onSignUpWithGoogle={handleSignUpWithGoogle}
          showVerifyEmailMessage={showVerifyEmailMessage}
          error={error}
          providerError={providerError}
        />
      </div>
    </div>
  );
}

export default withCrisp(InstallationProcessScreen);
