import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthProvider";
import { RoutePaths } from "../../RoutesPaths";
import { AnalyticsService, PostHogProvider } from "@tabdock/common";

interface Props {
  children: JSX.Element;
}

export default function RequireAuth({ children }: Props) {
  const { user } = useAuth();
  const location = useLocation();
  const postHogProvider = new PostHogProvider();
  const analyticsService = new AnalyticsService(postHogProvider);

  if (!user) {
    if (!location.search.includes("signout")) {
      localStorage.setItem(
        "redirectAfterLogin",
        location.pathname + location.search,
      );
    }

    return (
      <Navigate to={RoutePaths.SignIn} state={{ from: location }} replace />
    );
  } else {
    analyticsService.identifyUser(user.id, { email: user.email });
  }

  return <>{children}</>;
}

// http://localhost:3000/settings?openSubscriptionSettings=true
// http://localhost:3000/settings?openCalendarSettings=true
