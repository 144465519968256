import { AnalyticsProvider } from "./AnalyticsProvider";
import PostHog from "posthog-js-lite";

export class PostHogProvider implements AnalyticsProvider {
  private posthog = new PostHog(
    "phc_kvV7PG4XetWmBearryuRriWnZM2VpOGWRxTeIKPKxXH",
    {
      persistence: "localStorage",
    },
  );

  captureEvent(eventName: string, properties?: Record<string, any>): void {
    this.posthog?.capture(eventName, properties);
  }

  identifyUser(userId: string, properties?: Record<string, any>): void {
    this.posthog?.identify(userId, properties);
  }

  optOut(): void {
    this.posthog?.optOut();
  }
}
