import React from "react";
import "./style.scss";

type Color =
  | "blue"
  | "grey"
  | "red"
  | "yellow"
  | "green"
  | "pink"
  | "purple"
  | "cyan"
  | "orange";

interface Props {
  text: string;
  color: string;
}

export default function Tag({ text, color: passColor = "blue" }: Props) {
  const color = passColor as Color;
  return (
    <div className={`tag tag--${color}`}>
      <div className="tagCircle"></div>
      {text}
    </div>
  );
}
