import React from "react";

interface Props {
  children: [React.ReactNode, React.ReactNode];
  splitPorcentage: number;
}

export default function HorizontalDivider({
  children,
  splitPorcentage,
}: Props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: `${splitPorcentage}%`,
        }}
      >
        {children[0]}
      </div>
      <div
        style={{
          width: `${100 - splitPorcentage}%`,
        }}
      >
        {children[1]}
      </div>
    </div>
  );
}
