import React from "react";
import "./style.scss";
import Text from "../Text/Text";

type Color =
  | "blue"
  | "gray"
  | "red"
  | "yellow"
  | "green"
  | "pink"
  | "purple"
  | "cian"
  | "orange"
  | "transparent";

interface Props {
  numberOfTags: number;
  color1: string;
  color2: string;
  color3: string;
}

export default function MultipleTagsIndicator({
  numberOfTags,
  color1: passColor1,
  color2: passColor2,
  color3: passColor3,
}: Props) {
  const color1 = passColor1 as Color;
  const color2 = passColor2 as Color;
  const color3 = passColor3 as Color;

  return (
    <div className="multipleTagsIndicator">
      <Text size="tiny" variant="tertiary">
        {numberOfTags}
      </Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className={`multipleTagsIndicatorCircle multipleTagsIndicatorCircle--${color1}`}
        ></div>
        <div
          className={`multipleTagsIndicatorCircle multipleTagsIndicatorCircle--${color2}`}
          style={{ marginLeft: "-7px" }}
        ></div>
        <div
          className={`multipleTagsIndicatorCircle multipleTagsIndicatorCircle--${color3}`}
          style={{ marginLeft: "-7px" }}
        ></div>
      </div>
    </div>
  );
}
