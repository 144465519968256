import { useQuery } from "react-query";
import {
  getUserIntegrationData,
  INTEGRATIONS,
} from "../../api/userIntegrationsApi";

export function useGetUserIntegrationData(
  userId: string,
  integration: INTEGRATIONS,
) {
  return useQuery(
    ["user_integration_data", userId, integration],
    () => getUserIntegrationData(userId, integration),
    {
      enabled: !!userId,
    },
  );
}
