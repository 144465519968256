import { AnalyticsProvider } from "./AnalyticsProvider";

class AnalyticsService {
  private provider: AnalyticsProvider;

  constructor(provider: AnalyticsProvider) {
    this.provider = provider;
  }

  identifyUser(userId: string, properties?: Record<string, any>) {
    try {
      this.provider.identifyUser(userId, properties);
    } catch (e) {
      console.log(e);
    }
  }

  captureEvent(eventName: string, properties?: Record<string, any>) {
    try {
      this.provider.captureEvent(eventName, properties);
    } catch (e) {
      console.log(e);
    }
  }

  captureError(type: string, error: any, properties?: Record<string, any>) {
    try {
      this.captureEvent("error", {
        type,
        error: error.message || error,
        ...properties,
      });
    } catch (e) {
      console.log(e);
    }
  }

  optOut() {
    try {
      this.provider.optOut();
    } catch (e) {
      console.log(e);
    }
  }
}

export default AnalyticsService;
