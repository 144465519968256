import Text from "../../Display/Text/Text";
import Pair from "../../Layout/Pair/Pair";
import "./style.scss";

const defaultOptions = {
  invertedIconLogic: false,
};

interface Props {
  isDark: boolean;
  onChange: () => void;
  invertedIconLogic?: boolean;
  hideLabel?: boolean;
  simple?: boolean;
}

export default function ThemeSwitch({
  isDark,
  onChange,
  invertedIconLogic = defaultOptions.invertedIconLogic,
  hideLabel = false,
  simple = false,
}: Props) {
  //const [isDarkMode, setIsDarkMode] = useState(false);

  const handleToggle = () => {
    onChange();
    //setIsDarkMode(!isDarkMode);
  };

  if (simple) {
    return (
      <div
        className="theme-switch"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <label className="theme-switch-container">
          <input
            type="checkbox"
            checked={isDark}
            onChange={(e) => {
              e.stopPropagation();
              handleToggle();
            }}
          />
          <div />
        </label>
      </div>
    );
  }

  return (
    <div className="theme-switch" onClick={handleToggle}>
      <Pair direction="row" align="center" spacing={10}>
        <label className="theme-switch-container">
          <input
            type="checkbox"
            onChange={(e) => {
              e.stopPropagation();
              handleToggle();
            }}
            checked={isDark}
          />
          <div />
        </label>
        {!hideLabel ? (
          <Text weight="regular" size="small">
            {isDark ? "Light" : "Dark"}
          </Text>
        ) : null}
      </Pair>
    </div>
  );
}

/*
  title={isDark ? "Activate light mode" : "Activate dark mode"}
  aria-label={isDark ? "Activate light mode" : "Activate dark mode"}
  onClick={onChange}
  defaultChecked={invertedIconLogic ? !isDark : isDark}
*/
