import React from "react";
import "./style.scss";

import Pair from "../../Layout/Pair/Pair";
import Text from "../Text/Text";
import { Check } from "react-feather";

interface Props {
  title: string;
  description: string;
  index: number;
  state: "pending" | "completed" | "active";
}

export default function InstallationStep({
  title,
  description,
  index,
  state,
}: Props) {
  return (
    <Pair direction="row" spacing={5} align="center">
      <div className={`InstallationStepIndex ${state}`}>
        {state === "completed" ? <Check color="white" /> : index}
      </div>
      <Pair spacing={8}>
        <Text weight="bold">{title}</Text>
        <Text>{description}</Text>
      </Pair>
    </Pair>
  );
}
