import React from "react";
import "./style.scss";
import Text from "../Text/Text";
import BackgroundLine from "../BackgroundLine/BackgroundLine";
import DropDownMenu from "../../Inputs/DropDownMenu/DropDownMenu";
import DropDownMenuOption from "../../Inputs/DropDownMenuOption/DropDownMenuOption";
import Pair from "../../Layout/Pair/Pair";
import Button from "../../Inputs/Button/Button";
import { ConnectIcon } from "../../Icons/Icons";

type CalendarOption = {
  id: string;
  name: string;
  primary: boolean;
};

interface Props {
  initialHourFormat: "12" | "24";
  initialTimezone: string;
  connected: boolean;
  onClose: () => void;
  onDisconnect: () => void;
  onConnect: () => void;
  onUpdateTimezone: (timezone: string) => void;
  onUpdateHourFormat: (format: "12" | "24") => void;
  initialSelectedCalendar: string[];
  calendarOptions: CalendarOption[];
  onUpdateSelectedCalendars: (calendars: string[]) => void;
}

const AllTimezones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay",
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export default function GoogleCalendarSettings({
  initialHourFormat,
  initialTimezone,
  connected,
  onClose,
  onDisconnect,
  onConnect,
  onUpdateTimezone,
  onUpdateHourFormat,
  initialSelectedCalendar,
  calendarOptions,
  onUpdateSelectedCalendars,
}: Props) {
  const getSelectedHourIndex = () => {
    if (initialHourFormat === "12") {
      return 0;
    } else {
      return 1;
    }
  };

  if (!connected) {
    return (
      <div className="GoogleCalendarSettings">
        <div className="GoogleCalendarSettings--Header">
          <Text weight="bold" size="subtitle">
            Google Calendar Settings
          </Text>
          <div className="GoogleCalendarSettings--HeaderLine">
            <BackgroundLine />
          </div>
        </div>
        <div className="GoogleCalendarSettings--Content">
          <Pair direction="column">
            <Text size="regular">
              Connect your Google Calendar to start using this feature
            </Text>
            <Button
              label={"Connect Google Calendar"}
              onClick={onConnect}
              color="primary"
              width="full"
              startIcon={<ConnectIcon />}
            />
          </Pair>
          <Button
            label={"Close Settings"}
            onClick={onClose}
            color="tertiary"
            width="full"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="GoogleCalendarSettings">
      <div className="GoogleCalendarSettings--Header">
        <Text weight="bold" size="subtitle">
          Google Calendar Settings
        </Text>
        <div className="GoogleCalendarSettings--HeaderLine">
          <BackgroundLine />
        </div>
      </div>
      <div className="GoogleCalendarSettings--Content">
        <DropDownMenu
          title="Hour Format"
          placeholder="12:00 PM"
          width="full"
          selected={getSelectedHourIndex()}
        >
          <DropDownMenuOption
            key_value={"12"}
            key={"12"}
            onClick={() => {
              onUpdateHourFormat("12");
            }}
            active={initialHourFormat === "12"}
          >
            <Text>2:00 PM</Text>
          </DropDownMenuOption>

          <DropDownMenuOption
            key_value={"24"}
            key={"24"}
            onClick={() => {
              onUpdateHourFormat("24");
            }}
            active={initialHourFormat === "24"}
          >
            <Text>14:00</Text>
          </DropDownMenuOption>
        </DropDownMenu>

        <DropDownMenu
          title={"Timezone: " + initialTimezone}
          placeholder="Timezone"
          width="full"
          selected={null}
          enableSearch
        >
          {AllTimezones.map((timezone) => (
            <DropDownMenuOption
              key={timezone}
              key_value={timezone}
              searchValue={timezone}
              onClick={() => {
                onUpdateTimezone(timezone);
              }}
              active={initialTimezone === timezone}
            >
              <Text>{timezone}</Text>
            </DropDownMenuOption>
          ))}
        </DropDownMenu>

        <DropDownMenu
          title="Calendars"
          placeholder="Calendars"
          width="full"
          selected={null}
          enableSearch
          loading={calendarOptions.length === 0}
        >
          {calendarOptions.map((calendar) => (
            <DropDownMenuOption
              key={calendar.id}
              key_value={calendar.id}
              onClick={() => {
                if (calendar.primary) {
                  if (initialSelectedCalendar.includes("primary")) {
                    onUpdateSelectedCalendars(
                      initialSelectedCalendar.filter((id) => id !== "primary"),
                    );
                  } else {
                    onUpdateSelectedCalendars([
                      ...initialSelectedCalendar,
                      "primary",
                    ]);
                  }
                } else {
                  if (initialSelectedCalendar.includes(calendar.id)) {
                    onUpdateSelectedCalendars(
                      initialSelectedCalendar.filter(
                        (id) => id !== calendar.id,
                      ),
                    );
                  } else {
                    onUpdateSelectedCalendars([
                      ...initialSelectedCalendar,
                      calendar.id,
                    ]);
                  }
                }
              }}
              active={
                initialSelectedCalendar.includes(calendar.id) ||
                (calendar.primary &&
                  initialSelectedCalendar.includes("primary"))
              }
              searchValue={calendar.primary ? "primary" : calendar.name}
            >
              <Pair direction="row">
                <input
                  type="checkbox"
                  checked={
                    initialSelectedCalendar.includes(calendar.id) ||
                    (calendar.primary &&
                      initialSelectedCalendar.includes("primary"))
                  }
                  readOnly={true}
                />
                <Text>{calendar.name}</Text>
              </Pair>
            </DropDownMenuOption>
          ))}
        </DropDownMenu>

        <Pair direction="row">
          <Button
            label={"Close Settings"}
            onClick={onClose}
            color="tertiary"
            width="full"
          />
          <Button
            label={"Disconnect Google Calendar"}
            onClick={onDisconnect}
            color={"error"}
            width="full"
          />
        </Pair>
      </div>
    </div>
  );
}
