import React from "react";
import "./style.scss";
import Text from "../Text/Text";
import Stack from "../../Layout/Stack/Stack";
import Logo from "../../Display/Logo/Logo";

interface Props {
  email: string;
}

export default function VerifyEmailMessage({ email }: Props) {
  return (
    <div className="VerifyEmailMessage">
      <Stack alignItems="center" spacing={15}>
        <Logo />
        <Text size="title" weight="bold" alignCenter>
          Verify your email
        </Text>

        <Stack alignItems="center" spacing={25}>
          <Text alignCenter>
            You're almost there! We sent an email to <b>{email}</b> to verify
            your account.
          </Text>
          <Text alignCenter>
            Please click the link in the email to activate your account. If you
            don't see the email, check your spam folder.
          </Text>
        </Stack>
      </Stack>
    </div>
  );
}

/*
<Line />
        <Stack alignItems="center" spacing={10}>
          <Text alignCenter size="small" variant="secondary">
            Still can't find the email?
          </Text>
          <Button label="Resend email" />
        </Stack>
 */
