import "./style.scss";
import Text from "../../Display/Text/Text";
import TextPair from "../../Display/TextPair/TextPair";
import Logo from "../../Display/Logo/Logo";
import Pair from "../../Layout/Pair/Pair";
import Stack from "../../Layout/Stack/Stack";
import TextField from "../TextField/TextField";
import Link from "../../Display/Link/Link";
import Button from "../Button/Button";
import Line from "../../Display/Line/Line";

import GoogleLogo from "../../../assets/google_icon_2.svg";
import GithubLogo from "../../../assets/Github_icon.svg";
import { useEffect, useState } from "react";
import VerifyEmailMessage from "../../Display/VerifyEmailMessage/VerifyEmailMessage";

interface Props {
  onSignUp: (email: string, password: string, username: string) => void;
  onSignUpWithGoogle: () => void;
  onSignUpWithGithub: () => void;
  onGoToSignIn: () => void;
  error?: string | null;
  providerError?: string | null;
  showVerifyEmailMessage?: boolean;
  loading?: "google" | "github" | "email" | null;
  secondary?: boolean;
}

export default function SignUpForm({
  onSignUp,
  onSignUpWithGoogle,
  onSignUpWithGithub,
  onGoToSignIn,
  error = null,
  providerError = null,
  showVerifyEmailMessage = false,
  loading: initialLoading = null,
  secondary = false,
}: Props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassowrd] = useState("");
  const [username, setUsername] = useState("");

  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [loading, setLoading] = useState(initialLoading);

  useEffect(() => {
    setLoading(initialLoading);
  }, [initialLoading]);

  const validate = () => {
    if (username === "") {
      setUsernameError("Username is required");
      return false;
    } else {
      setUsernameError(null);
    }
    if (email === "") {
      setEmailError("Email is required");
      return false;
    } else {
      setEmailError(null);
    }
    if (password === "") {
      setPasswordError("Password is required");
      return false;
    } else {
      setPasswordError(null);
    }
    if (confirmPassword === "") {
      setConfirmPasswordError("Confirm password is required");
      return false;
    } else {
      setConfirmPasswordError(null);
    }
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords must match");
      return false;
    } else {
      setConfirmPasswordError(null);
    }
    return true;
  };

  const handleSignUp = () => {
    if (validate()) {
      try {
        setPasswordError(null);
        setEmailError(null);
        setUsernameError(null);
        setConfirmPasswordError(null);
        onSignUp(email, password, username);
        setLoading("email");
      } catch (error: any) {
        setEmailError("Error creating account");
        setLoading(null);
      }
    }
  };

  const handleSignUpWithGoogle = () => {
    try {
      onSignUpWithGoogle();
      setLoading("google");
    } catch (error: any) {
      console.log(error);
      setPasswordError("Error signing up with Google");
      setLoading(null);
    }
  };

  const handleSignUpWithGithub = () => {
    try {
      onSignUpWithGithub();
      setLoading("github");
    } catch (error: any) {
      console.log(error);
      setPasswordError("Error signing up with Github");
      setLoading(null);
    }
  };

  if (showVerifyEmailMessage) {
    return (
      <div className="SignUpFormContainer">
        <VerifyEmailMessage email={email} />
      </div>
    );
  }

  return (
    <div className="SignUpFormContainer">
      <Pair align="center" direction="column" spacing={2}>
        <Logo type="icon" />
        <TextPair
          header="Create an account!"
          subheader="Let’s get started!"
          size="h2"
          align="center"
        />
      </Pair>
      <Stack direction="column" width="full" spacing={10}>
        <TextField
          placeholder="e.g.  username"
          inputType="text"
          width="full"
          title="Username"
          value={username}
          onChange={setUsername}
          error={usernameError}
          disabled={loading !== null}
        />
        <TextField
          placeholder="e.g. email@email.com"
          inputType="email"
          width="full"
          title="Email"
          value={email}
          onChange={setEmail}
          error={emailError}
          disabled={loading !== null}
        />
        <TextField
          placeholder="e.g. password"
          inputType="password"
          width="full"
          title="Password"
          value={password}
          onChange={setPassword}
          error={passwordError}
          disabled={loading !== null}
        />
        <TextField
          placeholder="e.g. password"
          inputType="password"
          width="full"
          title="Confirm Password"
          value={confirmPassword}
          onChange={setConfirmPassowrd}
          error={confirmPasswordError}
          disabled={loading !== null}
        />
        {error !== null ? (
          <Text variant="error" size="small">
            {error}
          </Text>
        ) : null}
        <Button
          label="Create account"
          size="large"
          width="full"
          onClick={handleSignUp}
          loading={loading === "email"}
          disabled={loading !== null}
        />
      </Stack>
      <Line text="or" secondary={secondary} />
      <Stack direction="column" width="full" spacing={10}>
        <Button
          label="Sign up with Google"
          startIcon={<img src={GoogleLogo} alt="icon" />}
          variant="outlined"
          color="secondary"
          width="full"
          size="large"
          fixedWidth={true}
          onClick={handleSignUpWithGoogle}
          loading={loading === "google"}
          disabled={loading !== null}
        />
        <Button
          label="Sign up with Github"
          startIcon={<img src={GithubLogo} alt="icon" />}
          variant="outlined"
          color="secondary"
          width="full"
          size="large"
          fixedWidth={true}
          onClick={handleSignUpWithGithub}
          loading={loading === "github"}
          disabled={loading !== null}
        />
        {providerError !== null ? (
          <Text variant="error" size="small">
            {providerError}
          </Text>
        ) : null}
      </Stack>
      <div className="SignUpFormContainer--Bottom">
        <Pair direction="row" align="center" spacing={5}>
          <Text size="regular" weight="regular">
            Already have an account?
          </Text>
          <Link variant="primary" onClick={onGoToSignIn} text="Sign In" />
        </Pair>
      </div>
    </div>
  );
}
