import { supabaseClient } from "../config/supabase-client";
const HOST =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? process.env.REACT_APP_LOCALHOST
    : process.env.REACT_APP_HOST;

async function signInWithProvider(providerName: "google" | "github") {
  let redirectTo = HOST + "/";
  redirectTo = redirectTo.replace(/(\r\n|\n|\r)/gm, "");

  const { error } = await supabaseClient.auth.signInWithOAuth({
    provider: providerName,
    options: {
      redirectTo: redirectTo,
    },
  });

  if (error) throw error;
}

export async function signInWithGoogle() {
  await signInWithProvider("google");
  localStorage.setItem("forceSignOut", "false");
}

export async function signInWithGithub() {
  await signInWithProvider("github");
  localStorage.setItem("forceSignOut", "false");
}

export async function signInWithEmailAndPassword(
  email: string,
  password: string,
) {
  const { data, error } = await supabaseClient.auth.signInWithPassword({
    email: email,
    password: password,
  });

  if (error) throw error;
  localStorage.setItem("forceSignOut", "false");
  return { data, error };
}

export async function signUpWithEmailAndPassword(
  email: string,
  password: string,
  username: string,
) {
  let redirectTo = HOST + "/";
  redirectTo = redirectTo.replace(/(\r\n|\n|\r)/gm, "");

  const { error } = await supabaseClient.auth.signUp({
    email: email,
    password: password,
    options: {
      emailRedirectTo: redirectTo,
      data: {
        full_name: username,
      },
    },
  });

  if (error) throw error;
  localStorage.setItem("forceSignOut", "false");
}

export async function signOut() {
  const { error } = await supabaseClient.auth.signOut();
  if (error) throw error;
}

export async function sendForgetPasswordEmail(email: string) {
  let redirectTo = HOST + "/reset-password";
  redirectTo = redirectTo.replace(/(\r\n|\n|\r)/gm, "");

  const { error } = await supabaseClient.auth.resetPasswordForEmail(email, {
    redirectTo: redirectTo,
  });

  if (error) throw error;
}

export async function resetPasswordEventListener(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    supabaseClient.auth.onAuthStateChange(async (event, session) => {
      if (event === "PASSWORD_RECOVERY") {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
}

export async function updatePassword(newPassword: string) {
  const { error } = await supabaseClient.auth.updateUser({
    password: newPassword,
  });
  if (error) throw error;
}
