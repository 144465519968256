import React, { useEffect, useRef } from "react";
import "./style.scss";
import { TrashIcon, SaveIcon, CheckIcon, CloseIcon } from "../../Icons/Icons";
import Text from "../Text/Text";

type Color = "primary" | "secondary" | "error";
type Icon = "delete" | "save" | "check" | "cancel" | null;

interface Option {
  label: string;
  onClick: () => void;
  color?: Color;
  icon?: Icon;
  disabled?: boolean;
}

interface Props {
  open: boolean;
  onClose: () => void;
  options: Option[];
  minWidth?: string;
}

export default function Menu({
  open,
  onClose,
  options,
  minWidth = undefined,
}: Props) {
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    }

    if (open) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open, onClose]);
  return open ? (
    <div
      className="MenuContainer"
      style={{
        minWidth: minWidth,
      }}
      ref={menuRef}
    >
      <div className="MenuContainer--Menu">
        {options.map((option, index) => (
          <div
            key={index}
            className={`MenuContainer--Menu--MenuItem${
              option.disabled ? "--disabled" : ""
            }`}
            onClick={() => {
              if (option.disabled) return;
              option.onClick();
              onClose();
            }}
          >
            {option?.icon && option?.icon !== null ? (
              <div className="MenuContainer--Menu--MenuItem--Icon">
                {IconMap.get(option.icon)}
              </div>
            ) : null}
            <Text variant={option.disabled ? "tertiary" : option.color}>
              {option.label}
            </Text>
          </div>
        ))}
      </div>
    </div>
  ) : null;
}

const IconMap = new Map<Icon, any>([
  [
    "delete",
    <TrashIcon
      style={{
        stroke: "red",
      }}
    />,
  ],
  ["save", <SaveIcon className="MenuContainer--Icon_Fill" />],
  ["check", <CheckIcon className="MenuContainer--Icon_Fill" />],
  ["cancel", <CloseIcon className="MenuContainer--Icon" />],
  [null, null],
]);
