import {
  PageContainer,
  Text,
  Pair,
  Stack,
  Logo,
  PostHogProvider,
  AnalyticsService,
  Button,
} from "@tabdock/common";
import React from "react";
import "./style.scss";
import withCrisp from "../../HOCs/withCrisp";

function PrivacyPolicyScreen() {
  const HOST =
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? process.env.REACT_APP_LOCALHOST
      : process.env.REACT_APP_HOST;

  const openTabdock = () => {
    window.open(`${HOST}`, "_blank");
  };

  const postHogProvider = new PostHogProvider();
  const analyticsService = new AnalyticsService(postHogProvider);

  return (
    <PageContainer>
      <div className="PrivacyPolicyContainerScroll">
        <div className="PrivacyPolicyNav" onClick={openTabdock}>
          <Logo type="horizontal" />
        </div>
        <div className="PrivacyPolicyContainer">
          <Pair>
            <Text size="title" weight="bold">
              Privacy Policy
            </Text>
            <Text size="small">Last updated: July 10, 2024</Text>
          </Pair>
          <Stack spacing={10}>
            <Text>
              This privacy notice for Tabdock(<b>”we”</b>, <b>“us”</b>, or
              <b>“our”</b>), describes how and why we might collect, store, use,
              and/or share (<b>”process”</b>) your information when you use our
              services (<b>”Services”</b>), such as when you:
            </Text>
            <ul>
              <li>
                <Text>
                  Visit our website at{" "}
                  <a href="https://tabdock.app">https://tabdock.app</a>,{" "}
                  <a href="https://my.tabdock.app">https://my.tabdock.app</a>,
                  or any website of ours that links to this privacy notice.
                </Text>
              </li>
              <li>
                <Text>When you use our Chrome extension</Text>
              </li>
              <li>
                <Text>
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </Text>
              </li>
            </ul>
            <Text>
              Tabdock is committed to protecting your privacy and personal
              information.
            </Text>
            <Text>
              This privacy policy relates to all of our activities, unless we
              have provided you with a separate privacy policy for a particular
              product, service or activity.
            </Text>
          </Stack>

          <Text>
            <b>Questions or concers? </b>Reading this privacy notice will help
            you to understand your privacy rights and choices. If you do not
            agree with our policies and practices, please contact us at
            angel@tabdock.app.
          </Text>

          <Stack spacing={20}>
            <Text size="subtitle" weight="bold">
              Summary of key points
            </Text>
            <Text weight="semibold">
              This summary provides key points from our privacy notice, but you
              can find our more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for.
            </Text>
            <Text>
              <b>What personal information do we process? </b> When you visit,
              use, or navigate our Services, we may process personal information
              depending on how you interact with us and the Services, the
              choices you make, and the products and features that you use.
              <a href="#what_information_do_we_collect">Learn more </a>about
              personal information you disclose to us.
            </Text>
            <Text>
              <b>Do we process any sensitive personal information? </b> We do
              not process sensitive personal information.
            </Text>
            <Text>
              <b>Do we receive any information from third parties? </b> We do
              not receive any information from third parties unless you choose
              to connect with Google Calendar. In that case, we fetch your
              calendar events from Google to provide your events of the day
              every time you open a new tab.
            </Text>
            <Text>
              <b>How do we process your information? </b> We process your
              information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so.{" "}
              <a href="#how_do_we_process_your_information">Learn more</a> about
              how we process your information.
            </Text>
            <Text>
              <b>
                In what situations and with wich parties do we share personal
                information?
              </b>{" "}
              We may share information in specific situations and with specific
              third paties.{" "}
              <a href="#when_and_with_whom_do_we_share_your_personal_information">
                Learn more
              </a>{" "}
              about when and with whom we share your personal information.
            </Text>
            <Text>
              <b>How do we keep your information safe?</b> We have
              organizational and technical process and procedures in place to
              protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              garantee that hackers, cybercriminals, or other unauthorized third
              parties will not be able to defeat our security and improperly
              collect, access, steal, or modify your information.{" "}
              <a href="how_do_we_keep_your_information_safe">Learn more</a>
              about how we keep your information safe.
            </Text>
            <Text>
              <b>What are your rights?</b> Depending on where you are located
              geographically, the applicable privacy law may mean you have
              certain rights regarding your personal information.{" "}
              <a href="#what_are_your_privacy_rights">Learn more</a>
              about in the section “What are your privacy rights?“
            </Text>
          </Stack>

          <Stack spacing={10}>
            <Text size="subtitle" weight="bold">
              What is covered in this policy?
            </Text>
            <ol>
              <li>
                <a href="#what_information_do_we_collect">
                  What information do we collect?
                </a>
              </li>
              <li>
                <a href="#how_do_we_process_your_information">
                  How do we process your information?
                </a>
              </li>
              <li>
                <a href="#what_legal_bases_do_we_rely_on">
                  What legal bases do we rely on to process your information?
                </a>
              </li>
              <li>
                <a href="#when_and_with_whom_do_we_share_your_personal_information">
                  When and with whom do we share your personal information?
                </a>
              </li>
              <li>
                <a href="#how_do_we_handle_your_social_logins">
                  How do we handle your social logins?
                </a>
              </li>
              <li>
                <a href="#is_your_information_transferred_internationally">
                  Is your information transferred internationally?
                </a>
              </li>
              <li>
                <a href="#how_long_do_we_kepp_your_information">
                  How long do we keep your information?
                </a>
              </li>
              <li>
                <a href="#how_do_we_keep_your_information_safe">
                  How do we keep your information safe?
                </a>
              </li>
              <li>
                <a href="#do_we_collect_information_from_minors">
                  Do we collect information from minors?
                </a>
              </li>
              <li>
                <a href="#what_are_your_privacy_rights">
                  What are your privacy rights?
                </a>
              </li>
              <li>
                <a href="#control_for_do_not_track_features">
                  Control for do-not-track features
                </a>
              </li>
              <li>
                <a href="#how_to_your_edit_data">
                  How can you review, update, or delete the data we collect from
                  you?
                </a>
              </li>
              <li>
                <a href="#do_we_make_updates_to_this_notice">
                  Do we make updates to this notice?
                </a>
              </li>
              <li>
                <a href="#how_can_you_contact_us">How can you contact us?</a>
              </li>
            </ol>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="what_information_do_we_collect"
              >
                1. What information do we collect?
              </Text>
              <Text>
                <b>In Short:</b> We collect information that you provide to us.
              </Text>
            </Stack>

            <Pair spacing={0}>
              <Text>
                We collect personal information from you in the following ways:
              </Text>
              <ul>
                <li>
                  <Text>
                    We collect information that you voluntary provide to us when
                    you register on the Services.
                  </Text>
                </li>
                <li>
                  <Text>
                    Through your use of the Services, whether directly created
                    by you, or automatically collected by us as you use the
                    Services. This includes individuals who use the Services in
                    the course of their employment or at the direction of their
                    employer.
                  </Text>
                </li>
                <li>
                  <Text>
                    If you choose to connect with Google Calendar, we fetch your
                    calendar events from Google to provide you with integrated
                    scheduling features.
                  </Text>
                </li>
              </ul>
            </Pair>

            <Pair>
              <Text>
                <b>What types of personal information we collect</b>
              </Text>
              <Text>
                The personal information that we collect depends on the context
                of your interactions with us and the Services, the choices you
                make, and the products and features that you use. The personal
                information that we collect may include the following:
              </Text>
            </Pair>

            <ul>
              <li>
                <Text>
                  Account and profile information: We collect the minimum amount
                  of Personal Information necessary for you to use the Services.
                </Text>
              </li>
              <>
                <ul>
                  <li>
                    <Text>
                      <i>email address: </i>Provided by you directly, or through
                      third-party registration via GitHub or Google.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      <i>usernames:</i> Provided by you directly, or through
                      third-party registration via GitHub or Google.
                    </Text>
                  </li>
                  <li>
                    <Text>
                      <i>passwords: </i>Collected only if you register manually.
                    </Text>
                  </li>
                </ul>
              </>
              <li>
                <Text>
                  Customer information: When you use our Services, you will
                  control what data is sent to and stored on our systems. For
                  example, you might upload browser tabs or other browsing data
                  into Tabdock in order to use the Services.
                </Text>
              </li>
              <li>
                <Text>
                  Tabs, workspaces and commands: All tabs, workspaces and
                  commands that you decide to save will be stored on our
                  systems.
                </Text>
              </li>
            </ul>

            <Text>
              <b>Sensitive Information. </b>We do not process sensitive
              information.
            </Text>
            <Text>
              <b>Payment Data. </b>We may collect data necessary to process your
              payment if you make purchases, such as your payment instrument
              number, and the security code associated with your payment
              instrument. We use stripe to process your payment. You may find
              their privacy notice link(s) here:
              <a href="https://stripe.com/legal/privacy-center">
                Stripe Privacy Policy.
              </a>
            </Text>
            <Text>
              <b>Social Media Login Data. </b>We may provide you with the option
              to register with us using your existing social media account
              details, like your Google or Github account. If you choose to
              register in this way, we will collect the information described in
              the section called “How do we handle your social logins?”
            </Text>

            <Pair>
              <Text>
                <b>Information automatically collected</b>
              </Text>
              <Text>
                <b>In short:</b> Some information — such as your internet
                protocol (IP) address and/or browser and device characteristics
                — is collected automatically when you visit our Services.
              </Text>
            </Pair>

            <Text>
              We automatically collect certain information whe you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your iP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </Text>

            <Pair spacing={0}>
              <Text>The information we collect includes:</Text>
              <ul>
                <li>
                  <Text>
                    <i>Log and Usage Data.</i> Log and usage data is
                    service-related, diagnostic, usage, and performance
                    information our servers automatically collect when you
                    access or use our Services and which we record in log files.
                    Depending on how you interact with us, this log data may
                    include your IP address, device information, browser type,
                    and settings and information about your activity in the
                    Services (such as the date/time stamps associated with your
                    usage, pages and files viewed, searches, and other actions
                    you take such as wich features you use), device event
                    information (such as system activity, error reports
                    (sometimes called “crash dumps”), and hardware settings).
                  </Text>
                </li>
              </ul>
            </Pair>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="how_do_we_process_your_information"
              >
                2. How do we process your information?
              </Text>
              <Text>
                <b>In Short:</b> We process your information to provide,
                improve, and administer our Services, communicate with you, for
                security and fraud prevention, and to comply with law. We may
                also process your information for other purposes with your
                consent.
              </Text>
            </Stack>

            <Pair spacing={0}>
              <Text>
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </Text>
              <ul>
                <li>
                  <Text>
                    We process your personal information for a variety of
                    reasons, depending on how you interact with our Services,
                    including:
                  </Text>
                </li>
                <li>
                  <Text>
                    To deliver and facilitate delivery of services to the user.
                    We may process your information to provide you with the
                    requested service.
                  </Text>
                </li>
                <li>
                  <Text>
                    To request feedback. We may process your information when
                    necessary to request feedback and to contact you about your
                    use of our Services.
                  </Text>
                </li>
                <li>
                  <Text>
                    To protect our Services. We may process your information as
                    part of our effort to keep our Services safe and secure,
                    including fraud monitoring and prevention.
                  </Text>
                </li>
                <li>
                  <Text>
                    To identify usage trends. We may process information about
                    how you use our Services to better understand how they are
                    being used so we can improve them.
                  </Text>
                </li>
                <li>
                  <Text>
                    To save or protect and individual’s vital interest. We may
                    process your information when necessary to save or protect
                    and individual’s vital interest, such as to prevent harm.
                  </Text>
                </li>
              </ul>
            </Pair>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="what_legal_bases_do_we_rely_on"
              >
                3. What legal bases do we rely on to process your information?
              </Text>
              <Text>
                <b>In Short:</b> We only process your personal information when
                we believe it is necessary and we have a valid legal reason
                (i.e., legal basis) to do so under applicable law, like with
                your consent, to comply with laws, to provide you with services
                to enter into or fulfill our contractual obligations, to protect
                your rights, or to fulfill our legitimate business interests.
              </Text>
            </Stack>

            <Stack spacing={10}>
              <Text>
                <b>
                  If you are located in the EU or UK, this section applies to
                  you.
                </b>
              </Text>
              <Text>
                The General Data Protection Regulation (GDPR) and the UK GDPR
                require us to explain the valid legal bases we rely on in order
                to process your personal information. As such, we may rely on
                the following legal bases to process your personal information:
              </Text>
              <ul>
                <li>
                  <b>Consent.</b> We may process your information if you have
                  given us permission (i.e., consent) to use your personal
                  information for specific purpose. You can withdraw your
                  consent at any time. Learn more about this in the section What
                  are your privacy rights?
                </li>
                <li>
                  <b>Performance of a Contract. </b>We may process your personal
                  information when we believe it is necessary to fulfill our
                  contractual obligations to you, including providing our
                  Services or at your request prior to entering into a contract
                  with you.
                </li>
                <li>
                  <b>Legitimate Interests. </b>We may process your information
                  when we believe it is reasonably necessary to achieve our
                  legitimate business interests and those interests do not
                  outweigh your interests and fundamental right and freedoms.
                  For example, we may process your personal information for some
                  of the purposes described in order to:
                  <ul>
                    <li>
                      <Text>
                        Analyze how our Services are used so we can improve them
                        to engage and retain users
                      </Text>
                    </li>
                    <li>
                      <Text>
                        Diagnose problems and/or prevent fraudalent activities
                      </Text>
                    </li>
                    <li>
                      <Text>
                        Understand how our users use our products and services
                        so we can improve user experience.
                      </Text>
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Legal Obligations. </b>We may process your information
                  where we believe it is necessary for compliance with our legal
                  obligations, such as to cooperate with a law enforcement body
                  or regulatory agency, exercise or defend our legal rights, or
                  disclose your information as evidence in litigation in which
                  we are involved.
                </li>
                <li>
                  <b>Vital interests. </b>We may process your information where
                  we believe it is necessary to protect your vital interests or
                  the vital interests of a third party, such as situations
                  involving potential threats to the safety of any person.
                </li>
              </ul>
            </Stack>

            <Stack spacing={20}>
              <Pair spacing={10}>
                <Text>
                  <b>
                    If you are located in Canada, this section applies to you.
                  </b>
                </Text>
                <Text>
                  We may process your information if you have given us specific
                  permission (i.e., express consent) to use your personal
                  information for specific purpose, or in situations where your
                  permission can be inferred (i.e., implied consent). You can
                  withdraw your consent at any time.
                </Text>
              </Pair>

              <Pair>
                <Text>
                  In some exceptional cases, we may be legally permitted under
                  applicable law to process your information without your
                  consent, including, for example:
                </Text>
                <ul>
                  <li>
                    <Text>
                      If collection is clearly in the interests of an individual
                      and consent cannot be obtained in a timely way
                    </Text>
                  </li>
                  <li>
                    <Text>
                      For investigations and fraud detection and prevention
                    </Text>
                  </li>
                  <li>
                    <Text>
                      For business transactions provided certain conditions are
                      met
                    </Text>
                  </li>
                  <li>
                    <Text>
                      If it is contained in a witness statement an dthe
                      collection is necessary to assess, process, or settle and
                      insurance claim
                    </Text>
                  </li>
                  <li>
                    <Text>
                      For indentifying injured, ill, or deceased persons and
                      communicating with next of kin
                    </Text>
                  </li>
                  <li>
                    <Text>
                      If we have reasonable grounds to believe and individual
                      has been, ir, or may be victim of financial abuse
                    </Text>
                  </li>
                  <li>
                    <Text>
                      If the information is publicly available and is specified
                      by regulations
                    </Text>
                  </li>
                </ul>
              </Pair>
            </Stack>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="when_and_with_whom_do_we_share_your_personal_information"
              >
                4. When and with whom do we share your personal information?
              </Text>
              <Text>
                <b>In Short:</b> We may share information in specific situations
                described in this section and/or with the following third
                parties.
              </Text>
            </Stack>

            <Pair spacing={0}>
              <Text>
                We may need to share your personal information in the following
                situations:
              </Text>
              <ul>
                <li>
                  <Text>
                    Busines Transfers. We may share or transfare your
                    information in connection with, or during negotiations of,
                    any merger, sale of company assets, financing, or
                    acquisition of all or portion of our business to another
                    company.
                  </Text>
                </li>
                <li>
                  <Text>
                    To respond to requests from public or governmental
                    authorities, including for national security or law
                    enforcement purposes.
                  </Text>
                </li>
                <li>
                  <Text>
                    To operate and maintain the security of our Services,
                    including to prevent or stop an attack on our computer
                    systems or networks.
                  </Text>
                </li>
                <li>
                  <Text>
                    To prevent or investigate possible wrongdoing in connection
                    with the Services.
                  </Text>
                </li>
                <li>
                  <Text>To enforce our Terms of Service.</Text>
                </li>
                <li>
                  <Text>
                    When we use Post Hog. We may share your information with
                    Post Hog to track and analyze the use of the Services. The
                    Post Hog Advertising Features that we may use include:
                    Activity, Product analytics and Events. To opt out of being
                    tracked by Post Hog in our Services, click:{" "}
                    <Button
                      label="Opt Out Post Hog"
                      onClick={analyticsService.optOut}
                    />
                    You can also send us an email at: angel@tabdock.app. For
                    more information on the privacy practices of Post Hog,
                    please visit the Post Hog Privacy and Terms page.
                  </Text>
                </li>
              </ul>
            </Pair>

            <Text>
              We do not sell or trade your personal information to third
              parties.
            </Text>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="how_do_we_handle_your_social_logins"
              >
                5. How do we handle your social logins?
              </Text>
              <Text>
                <b>In Short:</b> If you choose to register or log in to our
                Services using a social media ccount, we may have access to
                certain information about you.
              </Text>
            </Stack>

            <Text>
              Our Services oofer you the ability to register and log in using
              your third-party social account details (like your Google or
              Githun logins). Where you choose todo this, we will receive
              certain profile information about you from your social
              mediaprovider. The profile information we receive may vary
              depending on the social media provider concerned, but will oftern
              include your name, email address, username and profile picture, as
              well as other information you choose to make public on such social
              media platform.
            </Text>

            <Pair spacing={0}>
              <Text>
                We will use the information we receive only for the purposes
                that ared described in this privacy notice or that are
                otherwiser amde clear to you on the relevant Services. Please
                note that we donot control, and are not responsible for, other
                uses of your personal information by your third-party social
                media provider. We recommend that you review their privacy
                notice to understand how they collect, use, and share your
                personal information, and how you can set your privacy
                preferences on their sites and apps.
              </Text>
              <ul>
                <li>
                  <a href="https://policies.google.com/privacy">
                    <Text>Google Privacy Policy</Text>
                  </a>
                </li>
                <li>
                  <a href="https://docs.github.com/en/site-policy/privacy-policies">
                    <Text>Github Privacy Policy</Text>
                  </a>
                </li>
              </ul>
            </Pair>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="is_your_information_transferred_internationally"
              >
                6. Is your information transferred internationally?
              </Text>
              <Text>
                <b>In Short:</b> We may transfer, store, and process your
                information in contries other than your own.
              </Text>
            </Stack>

            <Text>
              Our servers are located in the United States. if you are accessing
              our Services from outside the United States, please be aware that
              your information may be trasnferred to, stored, and processed by
              us in our facilities and by those third parties with whom we may
              share your personal information (see “When and with whom do we
              share your personal information?” above), in the United States,
              and other countries.
            </Text>

            <Text>
              If you are a resident in the European Economic Area (EEA), United
              Kingdom (UK), or Switzerland, then these conuntries may not
              necessarily have data protection laws or other similar laws as
              comprehensive as those in your country. However, we sill take all
              necessary measures to protect your personal information in
              accordance with this privacy notice and applicable law.
            </Text>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="how_long_do_we_kepp_your_information"
              >
                7. How long do we keep your information?
              </Text>
              <Text>
                <b>In Short:</b> We keep your information for as long as
                necessary to fulfill the purposes outlined in this privacy
                notice unless otherwise required by law.
              </Text>
            </Stack>
            <Text>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information longer
              than the period of time in which user have an account with us.
            </Text>
            <Text>
              When we have no ongoing business need to process your personal
              information, we will delete it securely.
            </Text>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="how_do_we_keep_your_information_safe"
              >
                8. How do we keep your information safe?
              </Text>
              <Text>
                <b>In Short:</b> We aim to protect your personal information
                through a system of organizational and technical security
                measures.
              </Text>
            </Stack>

            <Text>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure enviroment.
            </Text>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="do_we_collect_information_from_minors"
              >
                9. Do we collect information from minors?
              </Text>
              <Text>
                <b>In Short:</b> We do not knowingly collect data from or market
                to children unser 18 years of age.
              </Text>
            </Stack>
            <Text>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such minor
              and consent to such minor dependent’s use of the Services. If we
              learnn that personal information from user less than 18 years of
              ages has been collected, we will deactivate the account and take
              reasonable measures to promply delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at angel@tabdock.app.
            </Text>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="what_are_your_privacy_rights"
              >
                10. What are your privacy rights?
              </Text>
              <Text>
                <b>In Short:</b> In some regions, such as the European Economic
                Area (EEA), United Kingdom (UK), Switzerland, and Canada, you
                have the rights that allow you greater access to and control
                over your personal information. You may review, change, or
                terminate your account at any time.
              </Text>
            </Stack>

            <Text>
              In some regions (like the EEA, UK, Switzzerland, and Canada), you
              have certain rights under applicable data protection laws. These
              may include the right (i) to request access to obtain a copy of
              your personal information, (ii) to request rectification or
              erasure; (iii) to restrict the processing of your personal
              information; (iv) if applicable, to data portability; and (v) not
              to be subject to automated decision making. In certain
              circumstances, you may also have the right to object to the
              processing of your personal information. You can make such a
              request by contacting us by using the contact details provided in
              the section “How can you contact us?” below.
            </Text>

            <Text>
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </Text>

            <Text>
              If you are located in the EEA or UK and you belive we are
              unlawfully processing your personal information, you also have the
              right to complain to your{" "}
              <a href="https://ec.europa.eu/newsroom/article29/items/612080/en">
                Member State data protection authority
              </a>{" "}
              or{" "}
              <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">
                UK data protection authority
              </a>
              .
            </Text>

            <Text>
              If you are located in Switzerland, you may contact the{" "}
              <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                Federal Data Protection and Information Commissioner
              </a>
              .
            </Text>

            <Text>
              <b>Withdrawing your consent:</b> If we are relying on your consent
              to process your personal information, which may be express and/or
              implied consent depending on the applicable law, you have the
              right to withdraw your consent at any time. You can withdraw your
              consent by contacting us by using the contact details provided in
              the section “How can yoi contact us” below.
            </Text>

            <Text>
              <b>Opting out of marketing and promotional communications:</b> You
              can unsubscribe from our marketing and promotional communications
              at any time by clicking on the unsubscribe link in the emails that
              we send. You will then be removed from the marketing lists.
              However, we may still communicate with you — for example, to send
              you service-related messages that are necessary for the
              administration and use of your account, to respond to sevice
              requests, or for other non-marketing purposes.
            </Text>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="control_for_do_not_track_features"
              >
                11. Control for do-not-track features
              </Text>
              <Text>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track (”DNT”) feature or setting
                you can activate to signal your privacy preferences not to have
                data about you online browsing activities monitored and
                collected. At this satge no uniform technology standard for
                recognizing and implementing DNT signlas has been finalized. As
                such, we do not currently respond to DNT browser signals or any
                other mechanism that automatically communicates your choice not
                to be tracked online. If a standard for onlune tracking is
                adopted that we must follow in the future, we will inform you
                about that practice in a revised version of this privacy notice.
              </Text>
            </Stack>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text size="subtitle" weight="bold" id="how_to_your_edit_data">
                12. How can you review, update, or delete the data we collect
                from you?
              </Text>
              <Text>
                <b>In Short:</b> You can view, access, edit, delete, or request
                a copy of your Personal Information for many aspects of the
                Services via the settings page in Tabdock.
              </Text>
            </Stack>

            <Text>
              You can view, access, edit, delete your Personal Information for
              many aspects of the Services via the settings page in Tabdock in{" "}
              <a
                href="https://my.tabdock.app/settings"
                target="_blank"
                rel="noreferrer"
              >
                https://my.tabdock.app/settings
              </a>{" "}
              , you can delete your account from here and this will delete all
              your information from our servers.
            </Text>

            <Text>
              <b>Data Access.</b> You can access your Personal Information on
              your account’s settings dashboard{" "}
              <a
                href="https://my.tabdock.app/settings"
                target="_blank"
                rel="noreferrer"
              >
                https://my.tabdock.app/settings
              </a>{" "}
              .
            </Text>

            <Text>
              <b>Data Portability.</b> You can request a copy of your Personal
              Information by submitting an email to us at angel@tabdock.app and
              including “Send me a copy of my Personal Information” in the
              “Subject” line. We will verify your ability to access that email,
              then send you a digital export of the data we hold that is
              associated with your email address. We will use reasonable efforts
              to respond to your request within 30 days.
            </Text>

            <Text>
              <b>Data Erasure.</b> You can delete your account information in{" "}
              <a href="https://my.tabdock.app/settings">
                https://my.tabdock.app/settings
              </a>{" "}
              , this will delete all your information from our servers.
            </Text>

            <Text>
              <b>Data Correction.</b> You can modify your Personal Information
              on your account’s settings dashboard.
            </Text>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text
                size="subtitle"
                weight="bold"
                id="do_we_make_updates_to_this_notice"
              >
                13. Do we make updates to this notice?
              </Text>
              <Text>
                <b>In Short:</b> Yes, we will update this notice as necessary to
                stay compliant with relevant laws.
              </Text>
            </Stack>

            <Text>
              We will update this privacy statement when necessary to reflect
              customer feedback and changes in our Services. When we post
              changes to this statement, we will revise the “last updated” date
              at the top of the statement. If there are material changes to the
              statement or in how we will use your Personal Information, we will
              notify you either by prominently posting a notice of such changes
              before they take effect or by sending you a notification directly.
              We encourage you to periodically review this privacy statement to
              learn how we are protecting your information.
            </Text>
          </Stack>

          <Stack spacing={25}>
            <Stack spacing={10}>
              <Text size="subtitle" weight="bold" id="how_can_you_contact_us">
                14. How can you contact us?
              </Text>
              <Text>
                If you have a technical or support question, or have a privacy
                concern, complaint, or a question, please contact us by sending
                us an email at angel@tabdock.com.
              </Text>
            </Stack>
          </Stack>
        </div>
      </div>
    </PageContainer>
  );
}

export default withCrisp(PrivacyPolicyScreen);
