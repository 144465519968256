import React from "react";
import TabRow from "../TabRow/TabRow";
import TabGroup from "../TabGroup/TabGroup";
import { TabGroupType, TabsOrGroups } from "../../../Types/Types";

/*
type TabsOrGroups =
  | React.ComponentProps<typeof TabRow>
  | React.ComponentProps<typeof TabGroup>;
  */

interface Props {
  tabsOrGroups?: TabsOrGroups[];
  onClickTab?: (url: string, id: string, chromeId?: string) => void;
  deleteTab: (tabId: string, chromeId?: string) => void;
  onDeleteTabGroup: (groupId: string, chromeId?: string) => void;
  onUnGroup: (groupId: string, chromeId?: string) => void;
  onOpenTabGroup: (groupId: string, chromeId?: string) => void;
  locked?: boolean;
}

/*
 This functions also needs to recieve:
  - onUnGroup
  - onOpenAll
  - onDeleteGroup
  - onUpdateTags
  - onAddTag
  - onDeleteTab

  and we are going to pass this methods here, example:
    onUnGroup={() => {onUnGroup(group.id)}}
    onOpenAll={() => {onOpenAll(group.id)}}
    onDelete={() => {onDelete(group.id)}}
    onUpdateTags={() => {onUpdateTags(tab.id)}}
    onAddTag={() => {onAddTag(tab.id)}}
    onDelete={() => {onDelete(tab.id)}}
*/

export default function TabComponent({
  tabsOrGroups,
  onClickTab = () => {},
  deleteTab = () => {},
  onDeleteTabGroup = () => {},
  onUnGroup = () => {},
  onOpenTabGroup = () => {},
  locked = false,
}: Props) {
  if (tabsOrGroups === undefined) return <></>;
  if (tabsOrGroups.length === 0) return <></>;

  return (
    <>
      {tabsOrGroups?.map((tabOrGroup) => {
        if (typeof tabOrGroup === "object" && "tabs" in tabOrGroup) {
          const group = tabOrGroup as TabGroupType;
          return (
            <TabGroup
              id={group.id}
              title={group.title ?? ""}
              color={group.color ?? "blue"}
              onUnGroup={() => {
                onUnGroup(group.id, group.chromeId);
              }}
              onOpenAll={() => {
                onOpenTabGroup(group.id, group.chromeId);
              }}
              onDelete={() => {
                onDeleteTabGroup(group.id, group.chromeId);
              }}
              key={group.id}
              locked={locked}
            >
              {group.tabs?.map((tab) => (
                <TabRow
                  {...tab}
                  onUpdateTags={() => {}}
                  onAddTag={() => {}}
                  onClick={() => {
                    onClickTab(tab.url, tab.id, tab.chromeId);
                  }}
                  onDelete={() => {
                    deleteTab(tab.id, tab.chromeId);
                  }}
                  key={tab.id}
                  locked={locked}
                />
              ))}
            </TabGroup>
          );
        } else {
          const tab = tabOrGroup as React.ComponentProps<typeof TabRow>;
          return (
            <TabRow
              {...tab}
              onUpdateTags={() => {}}
              onAddTag={() => {}}
              onClick={() => {
                onClickTab(tab.url, tab.id, tab.chromeId);
              }}
              onDelete={() => {
                deleteTab(tab.id, tab.chromeId);
              }}
              key={tab.id}
              locked={locked}
            />
          );
        }
      })}
    </>
  );
}
