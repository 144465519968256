import { useEffect, useRef, useState } from "react";
import "./style.scss";
import listenForOutsideClicks from "../../../hooks/listenForOutsideClicks";

interface Props {
  children: React.ReactNode | [React.ReactNode];
  width?: "full" | "auto" | number;
  onClose?: () => void;
}

export default function Popover({
  children,
  width = 375,
  onClose = () => {},
}: Props) {
  const ref = useRef(null);
  const [listening, setListening] = useState(false);

  useEffect(
    () =>
      listenForOutsideClicks({
        listening,
        setListening,
        ref,
        onOutsideClick: onClose,
      })(),
    [listening, onClose, ref],
  );

  return (
    <div
      ref={ref}
      className="PopoverContainer"
      style={{
        width:
          width === "full" ? "100%" : width === "auto" ? "auto" : `${width}px`,
      }}
    >
      {children}
    </div>
  );
}
