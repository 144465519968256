import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  PageContainer,
  ScreenMessage,
  SideNavBar,
  Button,
} from "@tabdock/common";
import { useAuth } from "../../contexts/AuthProvider";
import { useGetUsernameAndEmail } from "../../hooks/user/useGetUsernameAndEmail";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutePaths } from "../../RoutesPaths";
import { sendMessageToExtension } from "../../utils/extension";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "../../contexts/ThemeProvider";

interface Props {
  children: React.ReactNode;
}

export default function ScreenBase({ children }: Props) {
  const { user, signOut } = useAuth();
  const { data } = useGetUsernameAndEmail(user?.id);
  const location = useLocation();
  const { theme, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(true);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  useEffect(() => {
    if (user) {
      const redirectTo = localStorage.getItem("redirectAfterLogin");
      localStorage.removeItem("redirectAfterLogin");

      if (redirectTo) {
        navigate(redirectTo, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    try {
      sendMessageToExtension(
        {
          type: "ping",
          command: "ping",
          data: null,
        },
        (response) => {
          if (
            response &&
            response.status === "success" &&
            response.message === "pong"
          ) {
            setIsExtensionInstalled(true);
          } else {
            setIsExtensionInstalled(false);
          }
        },
      );
    } catch (e) {
      setIsExtensionInstalled(false);
    }
  }, []);

  const handleSignOut = async () => {
    await signOut();
  };

  const active = React.useMemo(() => {
    switch (location.pathname) {
      case RoutePaths.Home:
        return "all";
      case RoutePaths.Tags:
        return "tags";
      case RoutePaths.Settings:
        return "settings";
      case RoutePaths.Commands:
        return "commands";
      case RoutePaths.Feedback:
        return "feedback";
      default:
        return "all";
    }
  }, [location.pathname]);

  const handleSetActive = (
    active: "all" | "tags" | "settings" | "commands" | "feedback",
  ) => {
    switch (active) {
      case "all":
        navigate(RoutePaths.Home);
        break;
      case "tags":
        navigate(RoutePaths.Tags);
        break;
      case "settings":
        navigate(RoutePaths.Settings);
        break;
      case "commands":
        navigate(RoutePaths.Commands);
        break;
      case "feedback":
        navigate(RoutePaths.Feedback);
        break;
      default:
        navigate(RoutePaths.Home);
        break;
    }
  };

  return (
    <PageContainer>
      {!isExtensionInstalled && !isTabletOrMobile && (
        <div
          style={{
            width: "100%",
          }}
        >
          <ScreenMessage
            title="Maximize Your Experience"
            message="It looks like the TabDock extension is missing from your browser. Install it now to unlock all features."
            removable={false}
            onRemove={() => {}}
            optionsComponent={
              <Button
                label="Download"
                variant="outlined"
                color="white-outline"
                size="large"
                onClick={() => {
                  window.open(
                    "https://chromewebstore.google.com/detail/tabdock/pehlpehimcfhglbhnnegaeadmcbbekfa",
                    "_blank",
                  );
                }}
              />
            }
          />
        </div>
      )}

      {isTabletOrMobile && (
        <div
          style={{
            width: "100%",
          }}
        >
          <ScreenMessage
            title="TabDock is best experienced on desktop"
            message="Please visit us on a desktop browser to enjoy all the features."
            removable={false}
            onRemove={() => {}}
          />
        </div>
      )}
      <div
        className="ScreenBaseContainer"
        style={{
          height: !isExtensionInstalled ? "calc(100vh - 100px)" : "100vh",
        }}
      >
        <div className="ScreenBaseContainer--Sidebar">
          <SideNavBar
            username={data?.username}
            email={data?.email.split("@")[0]}
            onSignOut={handleSignOut}
            active={active}
            setActive={handleSetActive}
            toggleTheme={toggleTheme}
            currerntTheme={theme}
          />
        </div>

        <div className="ScreenBaseContainer--Content">{children}</div>
      </div>
    </PageContainer>
  );
}
