import React from "react";
import CommandRow from "../CommandRow/CommandRow";

type CommandType = {
  id: string;
  command: string;
  url: string;
  term: boolean;
  termName?: string;
  description: string;
  editable: boolean;
};

type Props = {
  commands?: CommandType[];
  onDelete: (id: string) => void;
  onEdit: (command: CommandType) => void;
  showUpgradeMessage?: boolean;
  upgradePlanLink?: string;
};

export default function CommandComponentList({
  commands,
  onDelete,
  onEdit,
  showUpgradeMessage = false,
  upgradePlanLink = "",
}: Props) {
  if (commands === undefined) return <></>;

  return (
    <>
      {commands?.map((command) => (
        <CommandRow
          key={command.id}
          id={command.id}
          command={command.command}
          url={command.url}
          term={command.term}
          termName={command.termName}
          description={command.description}
          editable={command.editable}
          onDelete={() => onDelete(command.id)}
          onEdit={(newCommand) => onEdit(newCommand)}
          showUpgradeMessage={showUpgradeMessage}
          upgradePlanLink={upgradePlanLink}
        />
      ))}
    </>
  );
}
