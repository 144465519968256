import React, { useState } from "react";
import "./style.scss";
import Stack from "../../Layout/Stack/Stack";
import IconButton from "../IconButton/IconButton";

interface Props {
  onSearch: (value: string) => void;
}

export default function WorkspacesTopBar({ onSearch }: Props) {
  const [activeView, setActiveView] = useState("list");
  const [value, onChange] = React.useState("");

  return (
    <div className="WorkspacesTopBarContainer">
      <div className="WorkspacesTopBarContainer--Searchbar">
        <input
          className={`SearchBarTemplateTopBar`}
          placeholder={"Search"}
          value={value}
          onChange={(event: any) => {
            onChange(event.target.value);
            onSearch(event.target.value);
          }}
        />
      </div>
    </div>
  );
}

/*

  <Stack direction="row" spacing={15}>
        <IconButton
          icon="list"
          variant="primary"
          active={activeView === "list"}
          onClick={() => setActiveView("list")}
        />
        <IconButton
          icon="grid"
          variant="primary"
          active={activeView === "grid"}
          onClick={() => setActiveView("grid")}
        />
        <IconButton
          icon="timeline"
          variant="primary"
          active={activeView === "timeline"}
          onClick={() => setActiveView("timeline")}
        />
      </Stack>

*/
