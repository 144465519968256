import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import "./style.scss";
import {
  Text,
  List,
  ResizableLayout,
  getTabsAndGroupsCount,
  Button,
  OpenTabList,
  BackgroundLine,
  OpenTabComponent,
  WorkspacesList,
  Menu,
  useSelection,
  PostHogProvider,
  AnalyticsService,
} from "@tabdock/common";
import { ChevronDown } from "react-feather";
import { useAuth } from "../../contexts/AuthProvider";
import { useMediaQuery } from "react-responsive";

import { useGetWorkspaces } from "../../hooks/workspaces/useGetWorkspaces";
import { sendMessageToExtension } from "../../utils/extension";
import { createToast } from "../../utils/toast";
import { TabGroupType, WorkspaceType } from "@tabdock/common/src/Types/Types";
import { CloseIcon } from "@tabdock/common/src/components/Icons/Icons";
import { DragAndDropProvider } from "@tabdock/common";
import { useCreateWorkspaceWithTabs } from "../../hooks/workspaces/useCreateWorkspaceWithTabs";
import { useIsUserSubscribed } from "../../hooks/subscription/useIsUserSubscibed";
import { useGetWorkspacesCount } from "../../hooks/subscription/useGetWorkspacesCount";

export default function WorkspacesScreen() {
  const { id } = useAuth();
  const { data, refetch } = useGetWorkspaces(id!);
  const { data: isUserSubscribed } = useIsUserSubscribed(id!);
  const { data: workspacesCount } = useGetWorkspacesCount();
  const title = "Workspaces";
  const tooltip = null;

  const [currentOpenWindows, setCurrentOpenWindows] = useState<WorkspaceType[]>(
    [],
  );
  const [activeWorkspaces, setActiveWorkspaces] = useState<WorkspaceType[]>([]);
  const [workspaces, setWorkspaces] = useState<WorkspaceType[]>([]);
  const [openSideBarMenu, setOpenSideBarMenu] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const { selectAllTabs, deselectAllTabs, selectedTabs } = useSelection();

  const postHogProvider = new PostHogProvider();
  const analyticsService = new AnalyticsService(postHogProvider);

  const HOST =
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? process.env.REACT_APP_LOCALHOST
      : process.env.REACT_APP_HOST;

  const createWorkspaceWithTabs = useCreateWorkspaceWithTabs({
    onSuccess: () => {
      createToast("Workspace created successfully", "success");
      analyticsService.captureEvent("workspace_created", {
        product: "web",
        from: "saved_selection_as_workspace",
      });
    },
    onError: (error) => {
      createToast(error.message, "error");
      analyticsService.captureError("workspace_created_error", error, {
        product: "web",
        from: "saved_selection_as_workspace",
      });
    },
  });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1100px)" });

  const getWorkspacesWindowState = () => {
    sendMessageToExtension(
      {
        type: "workspace",
        command: "getAllWindows",
        data: null,
      },
      (response) => {
        if (response.error) {
          createToast("Error getting active workspaces", "error");
          return;
        }
        setCurrentOpenWindows(response.windows);
      },
    );

    sendMessageToExtension(
      {
        type: "workspace",
        command: "getActiveWorkspaces",
        data: null,
      },
      (response) => {
        if (response.error) {
          createToast("Error getting active workspaces", "error");
          return;
        }
        setActiveWorkspaces(response.workspaces);
      },
    );
  };

  useEffect(() => {
    getWorkspacesWindowState();
  }, []);

  useEffect(() => {
    if (!data) return;
    if (!activeWorkspaces) return;

    if (activeWorkspaces.length > 0 && data.length > 0) {
      const newWorkspaces = data.map((workspace: WorkspaceType) => {
        const activeWorkspace = activeWorkspaces.find(
          (activeWorkspace) => activeWorkspace.id === workspace.id,
        );
        if (activeWorkspace) {
          return activeWorkspace;
        }
        return workspace;
      });
      setWorkspaces(newWorkspaces);
    } else {
      setWorkspaces(data);
    }
  }, [activeWorkspaces, data]);

  const markWorkspaceAsActive = (workspaceId: string) => {
    const newWorkspaces = workspaces.map((workspace) => {
      if (workspace.id === workspaceId) {
        return {
          ...workspace,
          active: true,
        };
      }
      return {
        ...workspace,
      };
    });

    setWorkspaces(newWorkspaces);
  };

  const handleCreateWorkspace = () => {
    if (!isUserSubscribed && (workspacesCount ?? 0) >= 5) {
      createToast(
        "You have reached the limit of 5 workspaces. Upgrade to premium to create more workspaces",
        "error",
      );
      return;
    }
    const tabs = Object.values(selectedTabs);
    createWorkspaceWithTabs.mutate({
      userId: id!,
      title: tabs[0].title,
      tabs: tabs,
    });

    setSelectedAll(false);
    deselectAllTabs();
  };

  const toggleSideBarMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenSideBarMenu(!openSideBarMenu);
  };

  const toggleSelectAll = () => {
    if (selectedAll) {
      setSelectedAll(false);
      deselectAllTabs();
    } else {
      setSelectedAll(true);
      selectAllOpenTabs();
    }

    analyticsService.captureEvent("select_all_open_tabs", {
      product: "web",
      selectedAll: !selectedAll,
    });
  };

  const selectAllOpenTabs = () => {
    const tabs = currentOpenWindows.reduce((acc, tabList) => {
      return [...acc, ...tabList.tabsOrGroups];
    }, [] as any[]);
    const nestedTabs = tabs.reduce((acc, tab) => {
      if ("tabs" in tab) {
        return [...acc, ...tab.tabs];
      }
      return [...acc, tab];
    }, [] as any[]);
    selectAllTabs(nestedTabs);
  };

  const handleOpenWorkspaceInNewWindow = (workspaceId: string) => {
    sendMessageToExtension(
      {
        type: "workspace",
        command: "openWorkspaceInNewWindow",
        data: { workspaceId: workspaceId },
      },
      (response) => {
        if (response.error) {
          createToast("Error opening the workspace", "error");
          analyticsService.captureError(
            "open_workspace_in_new_window_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        markWorkspaceAsActive(workspaceId);
        createToast("Workspace opened in new window", "success");
        analyticsService.captureEvent("open_workspace_in_new_window", {
          product: "web",
        });
      },
    );
  };

  const handleAddWorkspaceToCurrentWindow = (workspaceId: string) => {
    sendMessageToExtension(
      {
        type: "workspace",
        command: "addWorkspaceToCurrentWindow",
        data: { workspaceId: workspaceId },
      },
      (response) => {
        if (response.error) {
          createToast(
            "Error adding the workspace to the current window",
            "error",
          );
          analyticsService.captureError(
            "add_workspace_to_current_window_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        createToast("Workspace added to the current window", "success");
        analyticsService.captureEvent("add_workspace_to_current_window", {
          product: "web",
        });
      },
    );
  };

  const handleReplaceCurrentWindowWithWorkspace = (workspaceId: string) => {
    sendMessageToExtension(
      {
        type: "workspace",
        command: "replaceCurrentWindowWithWorkspace",
        data: { workspaceId: workspaceId },
      },
      (response) => {
        if (response.error) {
          createToast(
            "Error replacing the current window with the workspace",
            "error",
          );
          analyticsService.captureError(
            "replace_current_window_with_workspace_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        markWorkspaceAsActive(workspaceId);
        createToast("Workspace replaced the current window", "success");
        analyticsService.captureEvent("replace_current_window_with_workspace", {
          product: "web",
        });
      },
    );
  };

  const handleDeleteWorkspace = (workspaceId: string) => {
    sendMessageToExtension(
      {
        type: "workspace",
        command: "deleteWorkspace",
        data: { workspaceId: workspaceId },
      },
      (response) => {
        if (response.error) {
          createToast("Error deleting the workspace", "error");
          analyticsService.captureError(
            "delete_workspace_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        createToast("Workspace deleted", "success");

        setWorkspaces((prevWorkspaces) =>
          prevWorkspaces.filter((workspace) => workspace.id !== workspaceId),
        );

        analyticsService.captureEvent("delete_workspace", {
          product: "web",
        });
      },
    );
  };

  const handleOpenTabInCurrentWindow = (url: string) => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "openUrl",
        data: { url: url },
      },
      (response) => {
        if (response.error) {
          createToast("Error opening the tab", "error");
          analyticsService.captureError("open_tab_error", response.error, {
            product: "web",
          });
          return;
        }
        createToast("Tab opened", "success");
        analyticsService.captureEvent("open_tab", {
          product: "web",
        });
      },
    );
  };

  const handleDeleteTab = (tabId: string) => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "deleteTab",
        data: { tabId: tabId },
      },
      async (response) => {
        if (response.error) {
          createToast("Error deleting the tab", "error");
          analyticsService.captureError("delete_tab_error", response.error, {
            product: "web",
          });
          return;
        }
        createToast("Tab deleted", "success");

        setWorkspaces((prevWorkspaces) => {
          const newWorkspaces = prevWorkspaces.map((workspace) => {
            const newTabs = workspace.tabsOrGroups.filter((tabOrGroup) => {
              if (typeof tabOrGroup === "object" && "tabs" in tabOrGroup) {
                const group = tabOrGroup as TabGroupType;
                return (
                  group.tabs && group.tabs.every((tab) => tab.id !== tabId)
                );
              }
              return tabOrGroup.id !== tabId;
            });
            return {
              ...workspace,
              tabsOrGroups: newTabs,
            };
          });
          return newWorkspaces;
        });

        await refetch();

        analyticsService.captureEvent("delete_tab", {
          product: "web",
        });
      },
    );
  };

  const handleDeleteTabGroup = (groupId: string) => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "deleteTabGroup",
        data: { groupId: groupId },
      },
      async (response) => {
        if (response.error) {
          createToast("Error deleting the tab group", "error");
          analyticsService.captureError(
            "delete_tab_group_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        createToast("Tab group deleted", "success");

        setWorkspaces((prevWorkspaces) => {
          const newWorkspaces = prevWorkspaces.map((workspace) => {
            const newTabs = workspace.tabsOrGroups.filter(
              (tab) => tab.id !== groupId,
            );
            return {
              ...workspace,
              tabsOrGroups: newTabs,
            };
          });
          return newWorkspaces;
        });

        await refetch();
        analyticsService.captureEvent("delete_tab_group", {
          product: "web",
        });
      },
    );
  };

  const handleUnGroupTabGroup = (groupId: string) => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "ungroupTabGroup",
        data: { groupId: groupId },
      },
      async (response) => {
        if (response.error) {
          createToast("Error ungrouping the tab group", "error");
          analyticsService.captureError(
            "ungroup_tab_group_error",
            response.error,
            {
              product: "web",
            },
          );

          return;
        }
        createToast("Tab group ungrouped", "success");
        await refetch();
        analyticsService.captureEvent("ungroup_tab_group", {
          product: "web",
        });
      },
    );
  };

  const handleOpenTabGroupInCurrentWindow = (groupId: string) => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "openTabGroup",
        data: { groupId: groupId },
      },
      (response) => {
        if (response.error) {
          createToast("Error opening the tab group", "error");
          analyticsService.captureError(
            "open_tab_group_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        createToast("Tab group opened", "success");
        analyticsService.captureEvent("open_tab_group", {
          product: "web",
        });
      },
    );
  };

  const handleFocusTab = (chromeId: string) => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "focusTab",
        data: { chromeId: chromeId },
      },
      (response) => {
        if (response.error) {
          createToast("Error focusing the tab", "error");
          analyticsService.captureError("focus_tab_error", response.error, {
            product: "web",
          });
          return;
        }
        analyticsService.captureEvent("focus_tab", {
          product: "web",
        });
      },
    );
  };

  const handleFocusTabGroup = (groupId: string) => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "focusTabGroup",
        data: { groupId: groupId },
      },
      (response) => {
        if (response.error) {
          createToast("Error focusing the tab group", "error");
          analyticsService.captureError(
            "focus_tab_group_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        analyticsService.captureEvent("focus_tab_group", {
          product: "web",
        });
      },
    );
  };

  const handleUngroupOpenTabGroup = (chromeId: string) => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "ungroupOpenTabGroup",
        data: { chromeId: chromeId },
      },
      async (response) => {
        if (response.error) {
          createToast("Error ungrouping the tab group", "error");
          analyticsService.captureError(
            "ungroup_open_tab_group_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        createToast("Tab group ungrouped", "success");
        await refetch();
        analyticsService.captureEvent("ungroup_open_tab_group", {
          product: "web",
        });
      },
    );
  };

  const handleDeleteAndCloseTab = (tabId: string, chromeId: string) => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "deleteAndCloseTab",
        data: { tabId: tabId, chromeId: chromeId },
      },
      async (response) => {
        if (response.error) {
          createToast("Error deleting and closing the tab", "error");
          analyticsService.captureError(
            "delete_and_close_tab_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        await refetch();
        createToast("Tab deleted and closed", "success");
        analyticsService.captureEvent("delete_and_close_tab", {
          product: "web",
        });
      },
    );
  };

  const handleDeleteAndCloseTabGroup = (chromeId: string) => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "deleteAndCloseTabGroup",
        data: { chromeId: chromeId },
      },
      async (response) => {
        if (response.error) {
          createToast("Error deleting and closing the tab group", "error");
          analyticsService.captureError(
            "delete_and_close_tab_group_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        createToast("Tab group deleted and closed", "success");
        await refetch();
        analyticsService.captureEvent("delete_and_close_tab_group", {
          product: "web",
        });
      },
    );
  };

  const handleAddTabToWorkspace = (workspaceId: string, tabs: any) => {
    sendMessageToExtension(
      {
        type: "workspace",
        command: "addTabsToWorkspace",
        data: {
          workspaceId: workspaceId,
          tabs: tabs,
          openIfWorkspaceActive: true,
        },
      },
      async (response) => {
        if (response.error) {
          createToast("Error adding the tab to the workspace", "error");
          analyticsService.captureError(
            "add_tab_to_workspace_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        createToast("Tab added to the workspace", "success");
        await refetch();
        analyticsService.captureEvent("add_tab_to_workspace", {
          product: "web",
        });
      },
    );
  };

  const handleCloseOpenTabs = () => {
    sendMessageToExtension(
      {
        type: "tabs",
        command: "closeTabs",
        data: {
          tabIds: Object.keys(selectedTabs),
        },
      },
      async (response) => {
        if (response.error) {
          createToast("Error closing the tabs", "error");
          analyticsService.captureError("close_tabs_error", response.error, {
            from: "close_selected_tabs",
            product: "web",
          });
          return;
        }
        createToast("Tabs closed", "success");
        await refetch();
        analyticsService.captureEvent("close_tabs", {
          from: "close_selected_tabs",
          product: "web",
        });
      },
    );

    getWorkspacesWindowState();
    setSelectedAll(false);
    deselectAllTabs();
  };

  const handleSaveWorkspaceTitle = (workspaceId: string, title: string) => {
    sendMessageToExtension(
      {
        type: "workspace",
        command: "saveWorkspaceTitleUsingId",
        data: { workspaceId: workspaceId, title: title },
      },
      async (response) => {
        if (response.error) {
          createToast("Error saving the workspace title", "error");
          analyticsService.captureError(
            "save_workspace_title_error",
            response.error,
            {
              product: "web",
            },
          );
          return;
        }
        createToast("Workspace title saved", "success");
        await refetch();
        analyticsService.captureEvent("save_workspace_title", {
          product: "web",
        });
      },
    );
  };

  if (isTabletOrMobile) {
    return (
      <DragAndDropProvider>
        <div className="WorkspacesScreenContainer">
          <Toaster />
          <WorkspacesList
            title={title}
            tooltip={tooltip}
            workspaces={workspaces}
            showLimitReached={!isUserSubscribed && (workspacesCount ?? 0) >= 5}
            upgradePlanLink={`${HOST}/settings?openSubscriptionSettings=true`}
            openWorkspaceInNewWindow={handleOpenWorkspaceInNewWindow}
            addWorkspaceToCurrentWindow={handleAddWorkspaceToCurrentWindow}
            replaceCurrentWindowWithWorkspace={
              handleReplaceCurrentWindowWithWorkspace
            }
            deleteWorkspace={handleDeleteWorkspace}
            onClickTab={(url, id) => {
              handleOpenTabInCurrentWindow(url);
            }}
            deleteTab={handleDeleteTab}
            onDeleteTabGroup={handleDeleteTabGroup}
            onUnGroup={handleUnGroupTabGroup}
            onOpenTabGroup={handleOpenTabGroupInCurrentWindow}
            focusTab={handleFocusTab}
            ungroupOpenTabGroup={handleUngroupOpenTabGroup}
            focusTabGroup={handleFocusTabGroup}
            deleteAndCloseTab={handleDeleteAndCloseTab}
            deleteAndCloseTabGroup={handleDeleteAndCloseTabGroup}
            addTabToWorkspace={handleAddTabToWorkspace}
          />
        </div>
      </DragAndDropProvider>
    );
  }

  return (
    <DragAndDropProvider>
      <div className="WorkspacesScreenContainer">
        <Toaster />
        <ResizableLayout minWidth={450} maxWidth={590}>
          <WorkspacesList
            title={title}
            tooltip={tooltip}
            workspaces={workspaces}
            enableTitleEditing={true}
            showLimitReached={!isUserSubscribed && (workspacesCount ?? 0) >= 5}
            upgradePlanLink={`${HOST}/settings?openSubscriptionSettings=true`}
            openWorkspaceInNewWindow={handleOpenWorkspaceInNewWindow}
            addWorkspaceToCurrentWindow={handleAddWorkspaceToCurrentWindow}
            replaceCurrentWindowWithWorkspace={
              handleReplaceCurrentWindowWithWorkspace
            }
            deleteWorkspace={handleDeleteWorkspace}
            onClickTab={(url, id) => {
              handleOpenTabInCurrentWindow(url);
            }}
            deleteTab={handleDeleteTab}
            onDeleteTabGroup={handleDeleteTabGroup}
            onUnGroup={handleUnGroupTabGroup}
            onOpenTabGroup={handleOpenTabGroupInCurrentWindow}
            focusTab={handleFocusTab}
            ungroupOpenTabGroup={handleUngroupOpenTabGroup}
            focusTabGroup={handleFocusTabGroup}
            deleteAndCloseTab={handleDeleteAndCloseTab}
            deleteAndCloseTabGroup={handleDeleteAndCloseTabGroup}
            addTabToWorkspace={handleAddTabToWorkspace}
            onSaveTitle={handleSaveWorkspaceTitle}
          />
          <div className="WorkspacesScreenContainer--Right">
            <div className="WorkspacesScreenContainer--Right--Header">
              <Text weight="bold" size="title" cutText>
                Open Windows
              </Text>
              <Button
                label="Action"
                endIcon={
                  openSideBarMenu ? (
                    <CloseIcon style={{ stroke: "white" }} />
                  ) : (
                    <ChevronDown />
                  )
                }
                onClick={toggleSideBarMenu}
              />
              <div className="WorkspacesScreenContainer--Right--Header--Menu">
                <Menu
                  open={openSideBarMenu}
                  onClose={() => {
                    setOpenSideBarMenu(false);
                  }}
                  minWidth="340px"
                  options={[
                    {
                      label: selectedAll ? "Deselect all" : "Select all",
                      onClick: () => {
                        toggleSelectAll();
                      },
                      color: "primary",
                      icon: selectedAll ? "cancel" : "check",
                    },
                    {
                      label: "Save selection in a new workspace",
                      onClick: handleCreateWorkspace,
                      color: "primary",
                      icon: "save",
                      disabled:
                        (!isUserSubscribed && (workspacesCount ?? 0) >= 5) ||
                        Object.keys(selectedTabs).length === 0,
                    },
                    {
                      label: "Close selection",
                      onClick: handleCloseOpenTabs,
                      color: "error",
                      icon: "delete",
                      disabled: Object.keys(selectedTabs).length === 0,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="WorkspacesScreenContainer--Right--Line">
              <BackgroundLine />
            </div>
            <div className="WorkspacesScreenContainer--Right--List">
              <List gap={30}>
                {currentOpenWindows.map((tabList, index) => {
                  const { tabsCount, groupsCount } = getTabsAndGroupsCount(
                    tabList.tabsOrGroups,
                  );
                  return (
                    <OpenTabList
                      id={index.toString()}
                      key={index.toString()}
                      workspaceName={tabList.title}
                      windowNumber={index + 1}
                      tabsCount={tabsCount}
                      groupsCount={groupsCount}
                      tabsOrGroups={tabList.tabsOrGroups}
                    >
                      <OpenTabComponent
                        tabsOrGroups={tabList.tabsOrGroups}
                        onClickTab={handleFocusTab}
                        key={index.toString()}
                      />
                    </OpenTabList>
                  );
                })}
              </List>
            </div>
          </div>
        </ResizableLayout>
      </div>
    </DragAndDropProvider>
  );
}
