import React from "react";

interface Props {
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  spacing?: number;
  divider?: React.ReactNode;
  useFlexGap?: boolean;
  flexWrap?: "wrap" | "nowrap" | "wrap-reverse";
  width?: "full" | "auto" | "fit-content";
  [key: string]: any;
}

export default function Stack({
  direction = "column",
  alignItems = "flex-start",
  justifyContent = "flex-start",
  spacing = 0,
  divider,
  useFlexGap = false,
  flexWrap = "nowrap",
  width = "auto",
  ...props
}: Props): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: direction,
        alignItems: alignItems,
        justifyContent: justifyContent,
        flexWrap,
        width: width === "full" ? "100%" : width,
        gap: `${spacing}px`,
        margin: "0",
        wordWrap: "break-word",
      }}
      {...props}
    >
      {React.Children.map(
        props.children,
        (child: JSX.Element, index: number) => (
          <React.Fragment key={index}>
            {child}
            {divider
              ? index < props.children.length - 1 && <div>{divider}</div>
              : null}
          </React.Fragment>
        ),
      )}
    </div>
  );
}
