import React, { useState } from "react";
import { useAuth } from "../contexts/AuthProvider";
import { deleteUserIntegration } from "../api/userIntegrationsApi";
import {
  AnalyticsService,
  ConfirmationModal,
  PostHogProvider,
} from "@tabdock/common";
import { useGetUserIntegrationToken } from "../hooks/user/useGetUserIntegrationToken";

interface Props {}

const withGoogleCalendarLogin = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const WithGoogleCalendarLogin: React.FC<P & Props> = (props) => {
    const { id } = useAuth();
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const { data: token, refetch: refetchIntegrationToken } =
      useGetUserIntegrationToken(id!, "google_calendar");
    const connected = !!token;

    const postHogProvider = new PostHogProvider();
    const analyticsService = new AnalyticsService(postHogProvider);

    const handleLogin = async () => {
      const redirectUri = `${window.location.origin}/oauth2callback`;
      const clientId = process.env.REACT_APP_GOOGLE_CALENDAR_OAUTH_API_KEY;
      const scope = "https://www.googleapis.com/auth/calendar.readonly";
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(
        scope,
      )}&response_type=code&prompt=consent&access_type=offline`;
      window.location.href = authUrl;
      await refetchIntegrationToken();
    };

    const handleDisconnect = async () => {
      await deleteUserIntegration(id!, "google_calendar");
      await refetchIntegrationToken();
      setOpenConfirmationModal(false);
      analyticsService.captureEvent("google_calendar_disconnected", {
        product: "web",
      });
    };

    return (
      <>
        <ConfirmationModal
          openModal={openConfirmationModal}
          onClose={() => {
            setOpenConfirmationModal(false);
            analyticsService.captureEvent("google_calendar_disconnect_close", {
              product: "web",
            });
          }}
          title="Disconnect Google Calendar"
          buttonLabel="Disconnect"
          onConfirm={handleDisconnect}
          onCancel={() => {}}
          description="Are you sure you want to disconnect Google Calendar? You will not be able to access your Google Calendar events from TabDock."
        />
        <WrappedComponent
          {...props}
          onClick={
            connected ? () => setOpenConfirmationModal(true) : handleLogin
          }
          connected={connected}
        />
      </>
    );
  };

  return WithGoogleCalendarLogin;
};

export default withGoogleCalendarLogin;
