import React from "react";
import "./style.scss";

interface Props {
  text: string;
  onClick?: () => void;
  variant?: "primary" | "secondary";
}

export default function Link({ text, onClick, variant = "primary" }: Props) {
  return (
    <p className={`Link ${variant}`} onClick={onClick}>
      {text}
    </p>
  );
}
