import { Routes, Route, useLocation } from "react-router-dom";
import { RoutePaths } from "./RoutesPaths";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import SignInScreen from "./screens/SignInScreen/SignInScreen";
import SignUpScreen from "./screens/SignUpScreen/SignUpScreen";
import ScreenBase from "./components/ScreenBase/ScreenBase";
import WorkspacesScreen from "./screens/WorkspacesScreen/WorkspacesScreen";
import TagsCenterScreen from "./screens/TagsCenterScreen/TagsCenterScreen";
import SettingsScreen from "./screens/SettingsScreen/SettingsScreen";
import CommandsCenterScreen from "./screens/CommandsCenterScreen/CommandsCenterScreen";
import Oauth2callback from "./screens/Oauth2callback/Oauth2callback";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen/PrivacyPolicyScreen";
import TermsScreen from "./screens/TermsScreen/TermsScreen";
import HelpScreen from "./screens/HelpScreen/HelpScreen";
import UserRedirect from "./components/UserRedirect/UserRedirect";
import { SelectionProvider } from "@tabdock/common";
import InstallationProcessScreen from "./screens/InstallationProcessScreen/InstallationProcessScreen";
import { useEffect } from "react";
import { ThemeProvider } from "./contexts/ThemeProvider";
import { useAuth } from "./contexts/AuthProvider";
import RequestFeatureScreen from "./screens/RequestFeatureScreen/RequestFeatureScreen";

function App() {
  const location = useLocation();
  const { signOut, user } = useAuth();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("signout") === "true" && user) {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <SelectionProvider>
      <ThemeProvider>
        <Routes>
          <Route
            path={RoutePaths.SignIn}
            element={
              <UserRedirect>
                <SignInScreen />
              </UserRedirect>
            }
          />
          <Route
            path={RoutePaths.SignUp}
            element={
              <UserRedirect>
                <SignUpScreen />
              </UserRedirect>
            }
          />
          <Route path={RoutePaths.Privacy} element={<PrivacyPolicyScreen />} />
          <Route path={RoutePaths.Terms} element={<TermsScreen />} />
          <Route path={RoutePaths.Help} element={<HelpScreen />} />
          <Route
            path={RoutePaths.Welcome}
            element={<InstallationProcessScreen />}
          />
          <Route
            path={RoutePaths.Oauth2callback}
            element={<Oauth2callback />}
          />
          <Route
            path={RoutePaths.Home}
            element={
              <RequireAuth>
                <ScreenBase>
                  <WorkspacesScreen />
                </ScreenBase>
              </RequireAuth>
            }
          />
          <Route
            path={RoutePaths.Tags}
            element={
              <RequireAuth>
                <ScreenBase>
                  <TagsCenterScreen />
                </ScreenBase>
              </RequireAuth>
            }
          />
          <Route
            path={RoutePaths.Commands}
            element={
              <RequireAuth>
                <ScreenBase>
                  <CommandsCenterScreen />
                </ScreenBase>
              </RequireAuth>
            }
          />
          <Route
            path={RoutePaths.Settings}
            element={
              <RequireAuth>
                <ScreenBase>
                  <SettingsScreen />
                </ScreenBase>
              </RequireAuth>
            }
          />
          <Route
            path={RoutePaths.Feedback}
            element={
              <RequireAuth>
                <ScreenBase>
                  <RequestFeatureScreen />
                </ScreenBase>
              </RequireAuth>
            }
          />
          <Route
            path={RoutePaths.NotFound}
            element={<ScreenBase>Not Found</ScreenBase>}
          />
        </Routes>
      </ThemeProvider>
    </SelectionProvider>
  );
}

export default App;
